import {
  Card,
  Col,
  Modal,
  ModalProps,
  Row,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { shippingStatusName } from "helpers/order";
import { IOrder, ITrack, ITrackResult } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
const { Title, Text } = Typography;

type ModalPropsType = {
  modalProps: ModalProps;
  order: IOrder | undefined;
};

export const ShowModal: React.FC<ModalPropsType> = ({
  modalProps,
  order,
}) => {
  const [orderId, setOrderId] = useState<number>(0);
  const [details, setDetails] = useState<ITrack[]>([]);
  const apiUrl = useApiUrl();
  const { data, refetch } = useCustom<ITrackResult>({
    url: `${apiUrl}/orders/${order?.id}/track`,
    method: 'get',
    queryOptions: {
      enabled: order?.payment !== null && orderId > 0,
    },
  });

  useEffect(() => {
    if (order) {
      setOrderId(order.id)
    }

    if (order?.payment !== null && order?.trackingNumber) {
      try {
        refetch();
      } catch (e) {
        console.log(e);
      }
    }
  }, [order])

  useEffect(() => {
    if ((data?.data as ITrackResult)?.details) {
      setDetails((data?.data as ITrackResult)?.details);
    }
  }, [data])

  return (
    <Modal
      {...modalProps}
      title={`송장조회 (${shippingStatusName((data?.data as any)?.data?.status)})`}
      footer={null}
      width={720}
    >
      <Card
        type="inner"
        title={order?.courier === 'EMS' ? '우체국EMS' : '경동택배'}
        extra={<>
          <Tag>송장번호</Tag>
          <Text strong>{order?.trackingNumber}</Text>
        </>}
      >
      {
        details && details.length > 1 &&
        details.map((v: ITrack, i: number) => {
          return <div key={`div-${i}`}>
            { i === 0 &&
            <Row key={`row-header-${i}`} gutter={[16, 16]}>
              <Col span={6}>
                <Title style={{fontSize: 12}}>위치</Title>
              </Col>
              <Col span={8}>
                <Title style={{fontSize: 12}}>상태</Title>
              </Col>
              <Col span={4}>
                <Title style={{fontSize: 12}}>시각</Title>
              </Col>
              <Col span={6}>
                <Title style={{fontSize: 12}}>추가정보</Title>
              </Col>
            </Row>
            }
            <Row key={`row-body-${i}`} gutter={[16, 16]}>
              <Col span={6}>
                <Text style={{fontSize: 12}}>{ v.location }</Text>
              </Col>
              <Col span={8}>
                <Text style={{fontSize: 12}}>{ v.status }</Text>
              </Col>
              <Col span={4}>
                <ReactTimeAgo date={Date.parse(v.registeredAt)} locale="ko" />
              </Col>
              <Col span={6}>
                <Text style={{fontSize: 12}}>{ v.info }</Text>
              </Col>
            </Row>
          </div>
        })
      }
      </Card>
    </Modal>
  );
};
