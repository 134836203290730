import {
  Image
} from "@pankod/refine-antd";
import { IShortArtist } from "interfaces";
import { Draggable, Droppable } from "react-beautiful-dnd";

type SortAuctionsProps = {
  artistIdx: number,
  artist: IShortArtist,
};

const getAuctionListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#FEF7E8' : 'transparent',
  display: 'flex',
  padding: 16,
  overflow: 'auto',
});

const getAuctionItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  width: 64,
  margin: `0 16px 0 0`,
  background: isDragging ? '#FEF7E8' : 'transparent',
  ...draggableStyle,
});

export const SortAuctions: React.FC<SortAuctionsProps> = ({ artistIdx, artist }) => {
  return (
    <Droppable
      droppableId={`droppable${artist.id}`}
      direction="horizontal"
      type={`${artistIdx}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getAuctionListStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          {artist.auctions.map((auction, index) => {
            return (
              <Draggable
                key={`${artistIdx}${index}`}
                draggableId={`${artistIdx}${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getAuctionItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div key={auction.id} {...provided.dragHandleProps}>
                      <Image src={auction.image} style={{
                        border: '1px solid #888',
                        maxHeight: 64
                        }}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
