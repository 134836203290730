import { BarcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Show,
  Tag,
  Typography,
  message,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useOne, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { firstImageUrl } from 'helpers/artwork';
import { couponTargetName } from 'helpers/coupon';
import { avatarUrl } from "helpers/user";
import { IArtist, IAuction, ICoupon, IGrant, IPack } from "interfaces";
import { CreateGrantUser } from 'pages/coupons/components/users/create';
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const CouponShow: React.FC<IResourceComponentsProps> = () => {
  const now = (new Date()).getTime();

  // useShow
  const { queryResult } = useShow<ICoupon>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // useOne
  const { data: packData, isLoading: packIsLoading } =
    useOne<IPack>({
      resource: "packs",
      id: record?.targetId || "",
      queryOptions: {
        enabled: data?.data.target === 'PACK',
      },
    });
  const { data: auctionData, isLoading: auctionIsLoading } =
    useOne<IAuction>({
      resource: "auctions",
      id: record?.targetId || "",
      queryOptions: {
        enabled: data?.data.target === 'AUCTION',
      },
    });
  const { data: artistData, isLoading: artistIsLoading } =
    useOne<IArtist>({
      resource: "artists",
      id: record?.targetId || "",
      queryOptions: {
        enabled: data?.data.target === 'ARTIST',
      },
    });

  // useModalForm
  const { formProps, modalProps, show } = useModalForm({
    action: "create",
    resource: `coupons/${record?.id}/users`,
    redirect: false,
    onMutationSuccess: () => {
      message.success(`푸시메시지가 발송되었습니다.`)
      refetch();
    }
  });

  // useDelete
  const { mutate } = useDelete<IGrant>();
  const deleteGrant = (id: number) => {
    mutate(
      {
        resource: `coupons/${record?.id}/users`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  // useEffect(() => {
  //   console.log(`record has been changed; ${record}`)
  //   setGrants(record?.grants)
  // }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <CreateGrantUser modalProps={modalProps} formProps={formProps} />
      <Card
        type="inner"
        title={
          record?.target === 'USER'
          ? `제공자 (${record?.grants?.length}명)`
          : `사용자 (${record?.grants?.length}명)`
        }
        extra={
          record?.target === 'USER' &&
          <Button
            size="small"
            onClick={() => { show() }}
          >사용자 선택</Button>
        }
        style={{marginBottom: 24}}
      >
      {
        record?.grants &&
        record?.grants.length > 0
        ? (record?.grants as IGrant[])!.map(
          (grant, i: number) => <div key={`${grant.id}`}>
        {
          i === 0 &&
          <Row key={`row-header-${grant.id}`} gutter={[16, 16]} style={{marginBottom: 16}}>
            <Col span={6}><Text strong>사용자</Text></Col>
            <Col span={4}><Text strong>메뉴</Text></Col>
            <Col span={14}><Text strong>사용여부</Text></Col>
          </Row>
        }
          <Row key={`row-body-${grant.id}`} gutter={[16, 16]}>
            <Col key={`col1-${grant.id}`} span={6}>
              <Link key={`link-${grant.id}`} to={`/users/show/${grant.user?.id}`}>
                {
                  grant && grant.user &&
                  <Avatar
                    key={`avatar-${grant.id}`}
                    size={32}
                    src={avatarUrl(grant.user?.avatar)}
                    alt={grant.user?.username}
                    style={{ marginRight: 8 }}
                  />
                }
              </Link>
              <Text key={`text-${grant.id}`}>{grant.user?.username}</Text>
            </Col>
            <Col key={`col2-${grant.id}`} span={4}>
              <Button
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => deleteGrant(grant.user?.id!)}
              >삭제</Button>
            </Col>
            <Col key={`col3-${grant.id}`} span={14}>
              {
                grant.couponUsedAt
                ? <>
                    <Tag key={`tag-${i}`}>사용</Tag>
                    <ReactTimeAgo
                      key={`time-${i}`}
                      date={Date.parse(grant.couponUsedAt)}
                      locale="ko" />
                  </>
                : <Tag>미사용</Tag>
              }
            </Col>
          </Row></div>)
          : <Row gutter={[16, 16]}>
            <Col span={24}><Tag>자료없음</Tag></Col>
          </Row>
        }
      </Card>
      <Card type="inner" title="쿠폰 정보">
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>쿠폰명</Title>
            <p><BarcodeOutlined style={{ marginRight: 8 }} /><Text>{record?.name}</Text></p>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>쿠폰코드</Title>
            {
              now < Date.parse(record?.expiredAt!)
              ? <Text strong>{record?.code}</Text>
              : <Text delete>{record?.code}</Text>
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>할인금액</Title>
            <Text style={{ marginLeft: 8 }}>{ record?.discount.toLocaleString() }원</Text>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>만기일</Title>
            {
              now < Date.parse(record?.expiredAt!)
              ? <ReactTimeAgo date={Date.parse(record?.expiredAt!)} locale="ko" />
              : <Tag color="red">만료</Tag>
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>쿠폰타입</Title>
            {<Text>{record?.target ? couponTargetName(record?.target!) : ''}</Text>}
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>최대사용횟수</Title>
            {<Text>{record?.max === 0 ? '무제한' : record?.max}</Text>}
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>생성일</Title>
            {record?.createdAt ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
            {record?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          {
            record?.target !== 'USER' &&
            <Col span={24}>
              <Title level={5} style={{fontSize: 11}}>
                {
                  record?.target === 'PACK'
                    ? '관련 기획전'
                    : record?.target === 'AUCTION' ? '관련 옥션' : '관련 작가'
                }
              </Title>
              {
                record?.target === 'PACK' && packData != null
                  ? <Link to={`/packs/show/${packData?.data.id}`}>
                      <Avatar
                        size={64}
                        shape="square"
                        src={packData?.data.image}
                        alt={packData?.data.title}
                      />
                      <Text style={{marginLeft: 8}}>{packData?.data.title}</Text>
                    </Link>
                  : record?.target === 'AUCTION' && auctionData != null
                    ? <Link to={`/auctions/show/${auctionData?.data.id}`}>
                        <Avatar
                          size={64}
                          shape="square"
                          src={firstImageUrl(auctionData?.data.images)}
                          alt={packData?.data.title}
                        />
                        <Text style={{marginLeft: 8}}>{auctionData?.data?.title}</Text>
                      </Link>
                    : <Link to={`/artists/show/${artistData?.data.id}`}>
                        <Avatar
                          size={64}
                          shape="square"
                          src={artistData?.data?.user?.avatar}
                          alt={packData?.data.title}
                        />
                        <Text style={{marginLeft: 8}}>{artistData?.data?.name}</Text>
                      </Link>
              }
            </Col>
          }
        </Row>
      </Card>
    </Show>
  )
};
