export const sellerTypeName = (e: any): string => {
  switch (e) {
    case 'ARTIST1':
      return '플리크루'
    case 'ARTIST2':
      return '작가'
    case 'ARTIST3':
      return '예비작가'
    case 'RESELLER':
      return '리셀러'
  }
  return '콜렉터'
}

export const sellerTypeColor = (e: string): any => {
  switch (e) {
    case 'ARTIST1':
      return 'purple'
    case 'ARTIST2':
      return 'orange'
    case 'ARTIST3':
      return 'red'
    case 'RESELLER':
      return 'blue'
  }
  return null
}

export const countryName = (e: any): string => {
  switch (e) {
    case 'kr':
      return '🇰🇷 한국'
    case 'gr':
      return '🇬🇷 그리스'
    case 'za':
      return '🇿🇦 남아공'
    case 'no':
      return '🇳🇴 노르웨이'
    case 'nl':
      return '🇳🇱 네덜란드'
    case 'nz':
      return '🇳🇿 뉴질랜드'
    case 'de':
      return '🇩🇪 독일'
    case 'tw':
      return '🇹🇼 대만'
    case 'dk':
      return '🇩🇰 덴마크'
    case 'my':
      return '🇲🇾 말레이시아'
    case 'mx':
      return '🇲🇽 멕시코'
    case 'us':
      return '🇺🇸 미국'
    case 'be':
      return '🇧🇪 벨기에'
    case 'bg':
      return '🇧🇬 불가리아'
    case 'br':
      return '🇧🇷 브라질'
    case 'ch':
      return '🇨🇭 스위스'
    case 'se':
      return '🇸🇪 스웨덴'
    case 'es':
      return '🇪🇸 스페인'
    case 'sg':
      return '🇸🇬 싱가폴'
    case 'ie':
      return '🇮🇪 아일랜드'
    case 'ar':
      return '🇦🇷 아르헨티나'
    case 'gb':
      return '🇬🇧 영국'
    case 'ua':
      return '🇺🇦 우크라이나'
    case 'ir':
      return '🇮🇷 이란'
    case 'iq':
      return '🇮🇶 이라크'
    case 'eg':
      return '🇪🇬 이집트'
    case 'it':
      return '🇮🇹 이탈리아'
    case 'in':
      return '🇮🇳 인도'
    case 'id':
      return '🇮🇩 인도네시아'
    case 'jp':
      return '🇯🇵 일본'
    case 'jm':
      return '🇯🇲 자메이카'
    case 'ca':
      return '🇨🇦 캐나다'
    case 'th':
      return '🇹🇭 태국'
    case 'tr':
      return '🇹🇷 튀르키예'
    case 'pl':
      return '🇵🇱 폴란드'
    case 'fr':
      return '🇫🇷 프랑스'
    case 'fi':
      return '🇫🇮 핀란드'
    case 'ph':
      return '🇵🇭 필리핀'
    case 'hu':
      return '🇭🇺 헝가리'
    case 'au':
      return '🇦🇺 호주'
    case 'cn':
      return '🍜 중국'
  }
  return '기타'
}
