import {
  Card,
  Col,
  Create, Form,
  Input, Radio,
  Row,
  Select,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { INews } from "interfaces";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

export const NewsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<INews>();
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");

  // options
  const radioOptions = [
    { label: '공개', value: true },
    { label: '비공개', value: false }, 
  ];

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="공지사항 생성"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="고정"
                name="isFixed"
                initialValue={false}
              >
                <Select
                  options={[
                    { label: '📌 고정글', value: true },
                    { label: '📰 일반글', value: false }, 
                  ]}
                  value={true}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="분류"
                name="category"
                initialValue={'GENERAL'}
              >
                <Select
                  options={[
                    { label: '뉴스', value: 'GENERAL' },
                    { label: '이벤트', value: 'EVENT' }, 
                    { label: '홍보', value: 'AD' },
                  ]}
                  value={true}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="공개 여부"
                name="isPublished"
                initialValue={false}
              >
                <Radio.Group
                  options={radioOptions}
                  value={false}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="내용"
                name="body"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
