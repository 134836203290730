import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { newsCategoryColor, newsCategoryName } from "helpers/news";
import { INews } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const NewsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<INews>({
    // initialSorter: [],
    initialPageSize: 20,
    syncWithLocation: true
  });
  return (
    <List>
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<INews>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<INews>
          dataIndex="image"
          title="사진"
          render={(_, record) => (
            <Link to={`/news/show/${record?.id}`}>
              <Avatar
                size={64}
                shape="square"
                src={record?.image ?? 'https://cdn.fleaauction.world/images/news.png'}
                alt={record?.title}
              />
            </Link>
          )}
        />
        <Table.Column<INews>
          dataIndex="isFixed"
          key="isFixed"
          title="타이틀"
          render={(_, record) => {
            return record?.isFixed
              ? <>
              <TagField value='📌 고정글' />
              <TextField value={record.title} />
              </>
              : <><TagField value='📰 일반글' />
              <TextField value={record.title} /></>
          }}
          defaultSortOrder={getDefaultSortOrder("isFixed", sorter)}
          sorter
        />
        <Table.Column<INews>
          dataIndex="category"
          key="category"
          title="분류"
          render={(value) => <TagField color={newsCategoryColor(value)} value={newsCategoryName(value)} />}
          defaultSortOrder={getDefaultSortOrder("category", sorter)}
          sorter
        />
        <Table.Column<INews>
          dataIndex="isPublished"
          key="isPublished"
          title="공개"
          render={(_, record) => {
            return record?.isPublished
              ? <TagField color='indigo' value='공개' />
              : <TagField value='비공개' />
          }}
          defaultSortOrder={getDefaultSortOrder("isPublished", sorter)}
          sorter
        />
        <Table.Column<INews>
          dataIndex="createdAt"
          key="createdAt"
          title="생성일"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<INews>
          dataIndex="notifiedAt"
          key="notifiedAt"
          title="푸시발송"
          render={(value) => {
            return value
              ? <ReactTimeAgo date={Date.parse(value)} locale="ko" />
              : <TagField value="발송안함" />
          }}
        />
        <Table.Column<INews>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
