import { EyeOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import ImgCrop from 'antd-img-crop';
import { INews } from 'interfaces';
import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";

// options
const radioOptions = [
  { label: '공개', value: true },
  { label: '비공개', value: false }, 
];

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const NewsEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutateNews } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<INews>({
    redirect: false,
  });

  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as INews;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/news/${record?.id}/image`,
    headers: headers,
    onChange({ file, fileList: newFileList }) {

      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            // you can skip the type here
            url: file.response?.image,
          }];
        }

        form.setFieldsValue({ avatar: file.response?.image });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          image: images ? images[0] : null,
        }

        mutateNews({
          resource: `news`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "news", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`공지사항: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ image: null });
          setFileList([])
        } catch (e) {
          console.error('removing an image from S3 failed.')
        }
      }
    }
  }

  // handlers : uploading avatar
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('news-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    // console.log(`[useEffect hook] <INews> data has been changed. => ${record}`)
    if (record?.image) {
      const uri = record?.image;
      if (! uri.includes('news.png')) {
        const filename = uri.replace(/^.*[\\/]/, '');
        setFileList([{
          uid: '-1',
          name: filename,
          status: 'done',
          url: uri,
        }])
      }
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button icon={<EyeOutlined />} onClick={(): void => goto("news", record?.id!)}>Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form
          {...formProps}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish(values);
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../news?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title="공지사항 수정"
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="제목"
                  name="title"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="고정"
                  name="isFixed"
                >
                  <Select
                    options={[
                      { label: '📌 고정글', value: true },
                      { label: '📰 일반글', value: false }, 
                    ]}
                    value={true}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="분류"
                  name="category"
                >
                  <Select
                    options={[
                      { label: '뉴스', value: 'GENERAL' },
                      { label: '이벤트', value: 'EVENT' }, 
                      { label: '홍보', value: 'AD' },
                    ]}
                    value={true}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="사진 업로드">
                  <Form.Item
                    name="image"
                    valuePropName="[fileList]"
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                  >
                  <ImgCrop rotate>
                    <Upload { ...uploadProps }>
                      {fileList.length < 1 && '+ Upload'}
                    </Upload>
                  </ImgCrop>
                  <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                    <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="공개 여부"
                  name="isPublished"
                >
                  <Radio.Group
                    options={radioOptions}
                    value={false}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="내용"
                  name="body"
                >
                  <ReactMde
                    selectedTab={selectTab}
                    onTabChange={setSelectTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
    </>
  );
};
