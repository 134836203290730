import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  MarkdownField, Popconfirm,
  Row,
  Show,
  Tag,
  Typography,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useList, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { Comment } from 'antd';
import dayjs from "dayjs";
import { articleCategoryColor, articleCategoryName } from "helpers/article";
import { firstImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl } from "helpers/user";
import { IArticle, IArticleComment, IAuction } from "interfaces";
import { CreateArticleComment } from "pages/articles/components/create";
import { useState } from "react";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const ArticleShow: React.FC<IResourceComponentsProps> = () => {
  const [commentId, setCommentId] = useState<number | null>(null);
  const { queryResult } = useShow<IArticle>();
  const { data, isLoading: isArticleLoading } = queryResult;
  const record = data?.data;

  const { formProps, modalProps, show } = useModalForm({
    action: "create",
    resource: commentId
      ? `articles/${record?.id}/comments/${commentId}`
      : `articles/${record?.id}/comments`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });

  const { mutate: mutateBid } = useDelete<IArticleComment>();
  const deleteComment = (id: number) => {
    mutateBid(
      {
        resource: `articles/${record?.id}/comments`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const { refetch, data: commentData } =
    useList<IArticleComment>({
      resource: `articles/${record?.id}/comments/`,
      queryOptions: {
        enabled: !!record,
      },
    });
  
  return (
    <Show isLoading={isArticleLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <CreateArticleComment modalProps={modalProps} formProps={formProps} />
      <Card type="inner" title="아티클 기본정보" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>이미지</Title>
            {
              record?.images ? <Image.PreviewGroup
                preview={{
                  countRender: (current, total) => {
                    const title = record?.title ?? '';
                    const count = `(${current} / ${total})`;
                    return `${title} ${count}`;
                  }
                }}
              >{
                record?.images.map(function (name, idx) {
                  return <span key={`image-span-${idx}`} style={{ marginRight: '10px'}}><Image
                    key={`image-${idx}`}
                    width={90}
                    src={name}
                    alt={`image #${idx}`}
                    style={{ border: '1px solid #888' }}
                  />
                </span>;
              })}</Image.PreviewGroup> : <Tag>자료없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>{record?.title}</Title>
            <Text>{record?.subtitle}</Text>
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>내용</Title>
            {
              record?.body
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.body} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>분류</Title>
            {
              record &&
              <Tag color={articleCategoryColor(record?.category)}>{articleCategoryName(record?.category)}</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>활성상태</Title>
            {
              record?.isPublished ? <Tag color='green'>게시중</Tag> : <Tag color='red'>숨김</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>댓글수</Title>
            <Text>{record?.commentCount.toLocaleString()}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작성일</Title>
            {record?.createdAt ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
            {record?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>삭제일</Title>
            {record?.deletedAt ? <ReactTimeAgo date={Date.parse(record?.deletedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="댓글 정보"
        extra={
          <Button
            size="small"
            onClick={() => {
              setCommentId(null);
              show()
            }}
          >댓글쓰기</Button>
        }
        style={{marginBottom: 24}}
      >
      {
        (commentData?.data as any)?.data &&
        (commentData?.data as any)?.data.length > 0 ?
        ((commentData?.data as any)?.data as IArticleComment[])!.map(
          (comment: IArticleComment) =>
            (<Comment
              key={comment.id}
              actions={[
                <Button
                  size="small"
                  onClick={() => {
                    setCommentId(comment.id);
                    show()
                  }}
                >댓글쓰기</Button>,
                <Popconfirm
                  title="정말로 삭제하시겠습니까?"
                  onConfirm={() => deleteComment(comment.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="small"
                    danger
                    style={{ marginLeft: 8 }}
                  >댓글삭제</Button>
                </Popconfirm>
              ]}
              author={
                <Link to={`/users/show/${comment?.user?.id}`}>
                  <Text>{ comment.user.username }</Text>
                </Link>
              }
              avatar={avatarUrl(comment?.user?.avatar)}
              content={<p>{comment?.body}</p>}
              datetime={
                <ReactTimeAgo date={Date.parse(comment?.createdAt)} locale="ko" />
              }
            >
              {
                (comment.children.length > 0)
                && comment.children.map((subcomment: IArticleComment) =>
                <Comment
                  key={subcomment.id}
                  actions={[
                    <Button
                      size="small"
                      onClick={() => {
                        setCommentId(subcomment.id);
                        show()
                      }}
                    >댓글쓰기</Button>,
                    <Popconfirm
                      title="정말로 삭제하시겠습니까?"
                      onConfirm={() => deleteComment(subcomment.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        size="small"
                        danger
                        style={{ marginLeft: 8 }}
                      >댓글삭제</Button>
                    </Popconfirm>
                  ]}
                  author={
                    <Link to={`/users/show/${subcomment?.user?.id}`}>
                      <Text>{ subcomment.user.username }</Text>
                    </Link>
                  }
                  avatar={avatarUrl(subcomment?.user?.avatar)}
                  content={<p>{subcomment?.body}</p>}
                  datetime={
                    <ReactTimeAgo date={Date.parse(subcomment?.createdAt)} locale="ko" />
                  }
                ></Comment>)
              }
            </Comment>)
          ) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
      }
      </Card>
      {record?.auctions &&
      <Card
        type="inner"
        title="관련 옥션"
        style={{marginBottom: 24}}
      >
        {
          record?.auctions ? record?.auctions!.map((auction: IAuction, i: number) => {
            return <Row key={`artist-row--${i}`} gutter={[16, 16]}>
              <Col key={`artist-col-${i}`} span={4}>
              { i === 0 ?
                <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                  <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                </Row> :
                <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Link to={`/artists/show/${auction.artwork?.artist?.id}`}>
                      {
                        auction && auction.artwork && auction.artwork.artist && auction.artwork.artist.user &&
                        <Avatar
                          size={32}
                          src={avatarUrl(auction.artwork?.artist?.user?.avatar)}
                          alt={auction.artwork?.artist?.user?.username}
                          style={{ marginRight: 8 }}
                        />
                      }
                    </Link>
                    {
                      auction && auction.artwork && auction.artwork.artist &&
                      <Tag color={sellerTypeColor(auction.artwork?.artist?.sellerType)}>{sellerTypeName(auction.artwork?.artist?.sellerType)}</Tag>
                    }
                    <div><Text ellipsis>{ auction.artwork?.artist?.name ?? '무명' }</Text></div>
                    <div><Text ellipsis>{ auction.artwork?.artist?.user?.username }</Text></div>
                  </Col>
                </Row>
              </Col>
              <Col key={`auction-col-${i}`} span={20}>
              { i === 0 ?
                <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
                  <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명/추정가</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>시작</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>종료</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>입찰수</Title></Col>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>입찰/낙찰가</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>상태</Title></Col>
                </Row>
                :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                  <Col key={`auction-col1-${i}`} span={3}>
                    <Link to={`/auctions/show/${auction?.id}`}>
                      <Avatar
                        size={64}
                        shape="square"
                        src={firstImageUrl(auction?.images)}
                        alt={auction?.title}
                      />
                    </Link>
                  </Col>
                  <Col key={`auction-col2-${i}`} span={6}>
                    <div><Text ellipsis>{auction?.title}</Text></div>
                    <div><Tag style={{fontSize: 9}}>{auction?.estimate!.toLocaleString()}원</Tag></div>
                  </Col>
                  <Col key={`auction-col3-${i}`} span={4}>
                    <div><ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" /></div>
                    <div><Tag style={{fontSize: 9}}>{dayjs(auction.startTime).format('YY-MM-DD HH:mm')}</Tag></div>
                  </Col>
                  <Col key={`auction-col4-${i}`} span={4}>
                    <div><ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" /></div>
                    <div><Tag style={{fontSize: 9}}>{dayjs(auction.endTime).format('YY-MM-DD HH:mm')}</Tag></div>
                  </Col>
                  <Col key={`auction-col5-${i}`} span={2}>
                    <Text>{auction?.bidCount!.toLocaleString()}</Text>
                  </Col>
                  <Col key={`auction-col6-${i}`} span={3}>
                    <div><Text>{auction?.lastBidAmount!.toLocaleString()}</Text></div>
                    <div><Tag style={{fontSize: 9}}>{auction.status === 'ENDED' ? '낙찰가' : '입찰가'}</Tag></div>
                  </Col>
                  <Col key={`auction-col7-${i}`} span={2}>
                    <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                  </Col>
                </Row>
              </Col>
            </Row>
          }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
        }
      </Card>
      }
      {record?.relatedArticles &&
      <Card type="inner" title="관련 아티클">
        {
          record?.relatedArticles ? record?.relatedArticles!.map((article, i: number) => {
            return <Row key={`artist-row--${i}`} gutter={[16, 16]}>
              <Col key={`auction-col-${i}`} span={24}>
              { i === 0 ?
                <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>이미지</Title></Col>
                  <Col span={5}><Title level={5} style={{fontSize: 11}}>제목</Title></Col>
                  <Col span={6}><Title level={5} style={{fontSize: 11}}>부제목</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>분류</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>댓글수</Title></Col>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>생성</Title></Col>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>최근수정</Title></Col>
                </Row>
                :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                  <Col key={`auction-col1-${i}`} span={3}>
                    <Link to={`/articles/show/${article.id}`}>
                      <Avatar
                        size={64}
                        shape="square"
                        src={firstImageUrl(article.images)}
                        alt={article.title}
                      />
                    </Link>
                  </Col>
                  <Col key={`auction-col2-${i}`} span={5}>
                    <Text ellipsis>{article?.title}</Text>
                  </Col>
                  <Col key={`auction-col3-${i}`} span={6}>
                    <Text ellipsis>{article?.subtitle}</Text>
                  </Col>
                  <Col key={`article-col4-${i}`} span={2}>
                    <Tag color={articleCategoryColor(article?.category)}>{articleCategoryName(article?.category)}</Tag>
                  </Col>
                  <Col key={`article-col5-${i}`} span={2}>
                    <Text>{article?.commentCount!.toLocaleString()}</Text>
                  </Col>
                  <Col key={`article-col6-${i}`} span={3}>
                    <ReactTimeAgo date={Date.parse(article?.createdAt)} locale="ko" />
                  </Col>
                  <Col key={`article-col7-${i}`} span={3}>
                    <ReactTimeAgo date={Date.parse(article?.updatedAt)} locale="ko" />
                  </Col>
                </Row>
              </Col>
            </Row>
          }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
        }
      </Card>
      }
    </Show>
  );
};
