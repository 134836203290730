import {
  Avatar,
  Card,
  Col,
  Modal,
  ModalProps,
  Row,
  Tabs,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { firstImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { IAuction, ITrackResult } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
const { Title, Text } = Typography;

const { Link } = routerProvider;

type ModalPropsType = {
  modalProps: ModalProps;
};

export const RankingModal: React.FC<ModalPropsType> = ({
  modalProps,
}) => {
  const [query, setQuery] = useState<string>('amount');
  const apiUrl = useApiUrl();
  const { data, refetch } = useCustom<ITrackResult>({
    url: `${apiUrl}/auctions/ranking?q=${query}&`,
    method: 'get',
    queryOptions: {
      enabled: false,
    },
  });

  const record = (data?.data as any) as IAuction[];

  const onChange = (v: string) => {
    switch (v) {
      case '1':
        setQuery('bookmark');
        break;
      case '2':
        setQuery('view');
        break;
      case '3':
        setQuery('bid');
        break;
      default:
        setQuery('amount');
        break;
    }
  }

  useEffect(() => {
    refetch();
  }, [query])

  return (
    <Modal
      {...modalProps}
      title={`Leaderboard`}
      footer={null}
      width={860}
    >
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
      >
        <Tabs.TabPane tab="최고 북마크수" key="1">
          <Card>
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={3}></Col>
              <Col span={5}><Tag>작품명/추정가</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>시작</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>종료</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag color="indigo">북마크</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>조회</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>입찰</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>금액</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>상태</Tag></Col>
            </Row>
            {
            record && record.length > 0 ? record.map((auction: IAuction, i: number) =>
              <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                <Col key={`auction-col1-${i}`} span={3}>
                  <Link to={`/auctions/show/${auction?.id}`}>
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(auction?.images)}
                      alt={auction?.title}
                    />
                  </Link>
                </Col>
                <Col key={`auction-col2-${i}`} span={5}>
                  <div><Text ellipsis>{auction?.title}</Text></div>
                  <div><Text ellipsis style={{fontSize: 12}}>추정가 {auction?.estimate!.toLocaleString()}</Text></div>
                </Col>
                <Col key={`auction-col3-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" />
                </Col>
                <Col key={`auction-col4-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" />
                </Col>
                <Col key={`auction-col5-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bookmarkCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col6-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.viewCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col7-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bidCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col8-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Text>{auction?.lastBidAmount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col9-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                </Col>
              </Row>
            ) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
          }
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="최고 조회수" key="2">
          <Card>
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={3}></Col>
              <Col span={5}><Tag>작품명/추정가</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>시작</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>종료</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>북마크</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag color="indigo">조회</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>입찰</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>금액</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>상태</Tag></Col>
            </Row>
            {
            record && record.length > 0 ? record.map((auction: IAuction, i: number) =>
              <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                <Col key={`auction-col1-${i}`} span={3}>
                  <Link to={`/auctions/show/${auction?.id}`}>
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(auction?.images)}
                      alt={auction?.title}
                    />
                  </Link>
                </Col>
                <Col key={`auction-col2-${i}`} span={5}>
                  <div><Text ellipsis>{auction?.title}</Text></div>
                  <div><Text ellipsis style={{fontSize: 12}}>추정가 {auction?.estimate!.toLocaleString()}</Text></div>
                </Col>
                <Col key={`auction-col3-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" />
                </Col>
                <Col key={`auction-col4-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" />
                </Col>
                <Col key={`auction-col5-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bookmarkCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col6-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.viewCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col7-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bidCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col8-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Text>{auction?.lastBidAmount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col9-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                </Col>
              </Row>
            ) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
          }
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="최고 입찰수" key="3">
          <Card>
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={3}></Col>
              <Col span={5}><Tag>작품명/추정가</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>시작</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>종료</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>북마크</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>조회</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag color="indigo">입찰</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>금액</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>상태</Tag></Col>
            </Row>
            {
            record && record.length > 0 ? record.map((auction: IAuction, i: number) =>
              <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                <Col key={`auction-col1-${i}`} span={3}>
                  <Link to={`/auctions/show/${auction?.id}`}>
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(auction?.images)}
                      alt={auction?.title}
                    />
                  </Link>
                </Col>
                <Col key={`auction-col2-${i}`} span={5}>
                  <div><Text ellipsis>{auction?.title}</Text></div>
                  <div><Text ellipsis style={{fontSize: 12}}>추정가 {auction?.estimate!.toLocaleString()}</Text></div>
                </Col>
                <Col key={`auction-col3-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" />
                </Col>
                <Col key={`auction-col4-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" />
                </Col>
                <Col key={`auction-col5-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bookmarkCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col6-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.viewCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col7-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bidCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col8-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Text>{auction?.lastBidAmount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col9-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                </Col>
              </Row>
            ) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
          }
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="최고 입찰금액" key="4">
          <Card>
            <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={3}></Col>
              <Col span={5}><Tag>작품명/추정가</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>시작</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>종료</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>북마크</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>조회</Tag></Col>
              <Col span={2} style={{textAlign: 'center'}}><Tag>입찰</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag color="indigo">금액</Tag></Col>
              <Col span={3} style={{textAlign: 'center'}}><Tag>상태</Tag></Col>
            </Row>
            {
            record && record.length > 0 ? record.map((auction: IAuction, i: number) =>
              <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                <Col key={`auction-col1-${i}`} span={3}>
                  <Link to={`/auctions/show/${auction?.id}`}>
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(auction?.images)}
                      alt={auction?.title}
                    />
                  </Link>
                </Col>
                <Col key={`auction-col2-${i}`} span={5}>
                  <div><Text ellipsis>{auction?.title}</Text></div>
                  <div><Text ellipsis style={{fontSize: 12}}>추정가 {auction?.estimate!.toLocaleString()}</Text></div>
                </Col>
                <Col key={`auction-col3-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" />
                </Col>
                <Col key={`auction-col4-${i}`} span={2} style={{textAlign: 'center'}}>
                  <ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" />
                </Col>
                <Col key={`auction-col5-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bookmarkCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col6-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.viewCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col7-${i}`} span={2} style={{textAlign: 'center'}}>
                  <Text>{auction?.bidCount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col8-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Text>{auction?.lastBidAmount!.toLocaleString()}</Text>
                </Col>
                <Col key={`auction-col9-${i}`} span={3} style={{textAlign: 'center'}}>
                  <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status )}</Tag>
                </Col>
              </Row>
            ) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
          }
          </Card>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
