import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { articleCategoryColor, articleCategoryName } from "helpers/article";
import { firstImageUrl } from "helpers/artwork";
import { IAdminArticle, IArticle } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const ArticleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IArticle>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });
  return (
    <List>
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IAdminArticle>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="image"
          title="아티클사진"
          render={(_, record) => (
            <Link to={`/articles/show/${record?.id}`}>
              <Avatar
                size={64}
                shape="square"
                src={firstImageUrl(record?.images)}
                alt={record?.title}
              />
            </Link>
          )}
        />
        <Table.Column<IAdminArticle>
          dataIndex="title"
          key="title"
          title="제목"
          render={(_, record) => (record.isPublished) ? <TextField strong value={record.title} /> : <TextField delete value={record.title} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="subtitle"
          key="subtitle"
          title="부제목"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("subtitle", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="category"
          title="분류"
          render={(value) => <TagField
            color={articleCategoryColor(value)}
            value={articleCategoryName(value)}
          />}
          defaultSortOrder={getDefaultSortOrder("category", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="relatedArticleCount"
          key="relatedArticleCount"
          title="관련아티클"
          render={(value) => <NumberField value={value.toLocaleString()} />}
        />
        <Table.Column<IAdminArticle>
          dataIndex="auctionCount"
          key="auctionCount"
          title="관련옥션"
          render={(value) => <NumberField value={value.toLocaleString()} />}
        />
        <Table.Column<IAdminArticle>
          dataIndex="commentCount"
          key="commentCount"
          title="댓글수"
          render={(value) => <NumberField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("commentCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="isPublished"
          key="isPublished"
          title="활성"
          render={(_, record) => {
            return record?.isPublished
              ? <TagField color='green' value='노출' />
              : <TagField value='숨김' />
          }}
          defaultSortOrder={getDefaultSortOrder("isPublished", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근수정"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IAdminArticle>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
