import {
  CommentOutlined,
  EditOutlined,
  NotificationOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  DeleteButton,
  getDefaultSortOrder,
  List,
  message,
  NumberField,
  Popconfirm,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { reporTargetName, reportStatusColor, reportStatusName } from "helpers/report";
import { avatarUrl } from 'helpers/user';
import { IReport } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const ReportList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IReport>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });
  const { mutate } = useUpdate();

  const onConfirm = (id: number) => {
    mutate({
      resource: "reports",
      id: id,
      values: {
        reportStatus: 'RESOLVED',
      },
    });
    message.success('완료처리 되었습니다.');
  };

  const onCancel = (e?: React.MouseEvent<HTMLElement>) => {};

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={(tableProps?.dataSource as any)?.data}
      >
        <Table.Column<IReport>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IReport>
          dataIndex="reason"
          key="reason"
          title="신고내용"
          render={(_, record) => (
            <Link to={`/reports/show/${record?.id}`}>
              <NotificationOutlined style={{marginRight: 8}} />
              <TextField value={record?.reason} />
            </Link>
          )}
        />
        <Table.Column<IReport>
          dataIndex="target"
          key="target"
          title="신고대상"
          render={(_, record) => (
            record?.target === 'USER'
            ? (
              <>
                <UserOutlined style={{marginRight: 8}} />
                <TextField value={`${reporTargetName(record?.target)} (${record?.targetId})`} />
              </>
            )
            : (
              <>
                <CommentOutlined style={{marginRight: 8}} />
                <TextField value={`${reporTargetName(record?.target)} (${record?.targetId})`} />
              </>
            )
          )}
        />
        <Table.Column<IReport>
          dataIndex="username"
          key="username"
          title="신고자"
          render={(_, record) => (
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  shape="square"
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.username}
                />
              }
              <TextField value={record.user?.username} style={{marginLeft: 8}} />
            </Link>
          )}
          defaultSortOrder={getDefaultSortOrder("isFixed", sorter)}
          sorter
        />
        <Table.Column<IReport>
          dataIndex="reportStatus"
          key="reportStatus"
          title="상태"
          render={(value) => <TagField color={reportStatusColor(value)} value={reportStatusName(value)} />}
          defaultSortOrder={getDefaultSortOrder("reportStatus", sorter)}
          sorter
        />
        <Table.Column<IReport>
          dataIndex="createdAt"
          key="createdAt"
          title="생성일"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IReport>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <Popconfirm
                title="완료처리 할까요?"
                onConfirm={() => onConfirm(record.id)}
                onCancel={onCancel}
                okText="완료"
                cancelText="취소"
              >
              <Button
                icon={<EditOutlined />}
                size="small"
                // onClick={({ domEvent }) => domEvent.stopPropagation()}
              />
              </Popconfirm>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
