import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select, useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { IOrder } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";

// options
const radioOptions = [
  { label: '묶음배송', value: true },
  { label: '단일배송', value: false }, 
];

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IOrder>({
    redirect: false,
  });

  // useState hook
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as IOrder;

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('orders-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  // useEffect(() => {
  //   console.log(`[useEffect hook] <IOrder> data has been changed. => ${record}`)
  // }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button icon={<EyeOutlined />} onClick={(): void => goto("orders", record?.id!)}>Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form
          {...formProps}
          form={form}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish(values);
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../orders?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title="오더 정보"
          >
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  label="제목"
                  name="title"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="이미지 URL"
                  name="image"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="작품가"
                  name="price"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="배송비"
                  name="shipping"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="분류"
                  name="orderType"
                >
                  <Select
                    options={[
                      { label: '경매', value: 'AUCTION', },
                      { label: '즉구', value: 'BUYITNOW', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="묶음배송 여부"
                  name="isCombined"
                >
                  <Radio.Group
                    options={radioOptions}
                    value={record?.isCombined}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="배송상태"
                  name="shippingStatus"
                >
                  <Select
                    options={[
                      { label: '포장중', value: 'PACKAGING', },
                      { label: '송장번호발급', value: 'NUMBER_ISSUED', },
                      { label: '배송중', value: 'IN_TRANSIT', },
                      { label: '배송완료', value: 'DELIVERED', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="배송설명"
                  name="shippingComment"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="택배업체"
                  name="courier"
                >
                  <Select
                    options={[
                      { label: '경동택배', value: 'KDEXP', },
                      { label: '우체국EMS', value: 'EMS', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="송장번호"
                  name="trackingNumber"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="상태"
                  name="orderStatus"
                >
                  <Select
                    options={[
                      { label: '결제완료', value: 'PAID', },
                      { label: '결제대기', value: 'WAITING', },
                      { label: '유찰', value: 'MISCARRIED', },
                      { label: '결제취소', value: 'CANCELED', },
                      { label: '결제환불', value: 'REFUNDED', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="비고"
                  name="note"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
    </>
  );
};
