import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  Radio,
  Row, useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IOffer } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";

// options
const radioOptions = [
  { label: 'accepted', value: true },
  { label: 'not accepted', value: false }, 
];

export const OfferEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IOffer>({
    redirect: false,
  });

  // useState hook
  const [uri, setUri] = useState('');

  // more constants
  const record = queryResult?.data?.data as IOffer;

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const onDatePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ expiredAt: day });
    return day;
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('offers-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  // useEffect(() => {
  //   console.log(`[useEffect hook] <IOffer> data has been changed. => ${record}`)
  // }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button icon={<EyeOutlined />} onClick={(): void => goto("offers", record?.id!)}>Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form
          {...formProps}
          form={form}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish(values);
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../offers?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title="오퍼 정보"
          >
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  label="작품명"
                  name="title"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="기획전명"
                  name="subtitle"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="이미지 URL"
                  name="image"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="특구가"
                  name="amount"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
            <Col span={6}>
              <Form.Item
                label="종료시각"
                name="expiredAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onDatePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
              <Col span={6}>
                <Form.Item
                  label="수락여부"
                  name="isAccepted"
                >
                  <Radio.Group
                    options={radioOptions}
                    value={record?.isAccepted}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="비고"
                  name="note"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
    </>
  );
};
