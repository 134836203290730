import { BlockOutlined, BorderOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Show,
  Tag,
  Typography, useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AgeFromDateString } from 'age-calculator';
import {
  courierColor,
  courierName,
  orderStatusColor,
  orderStatusName,
  shippingStatusColor,
  shippingStatusName
} from "helpers/order";
import { paymentMethodColor, paymentMethodName, paymentStatusColor, paymentStatusName } from 'helpers/payment';
import { sellerTypeColor, sellerTypeName } from 'helpers/seller';
import { avatarUrl, phoneNumber } from "helpers/user";
import { IOrder, IPayment } from "interfaces";
import { RefundModal } from 'pages/payments/components/refund-modal';

import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const PaymentShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();

  // useShow
  const { queryResult } = useShow<IPayment>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user?.dob).age : null;

  // useModalForm
  const { formProps, modalProps, show } = useModalForm({
    action: 'edit',
    resource: `payments/${record?.id}/cancel`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <RefundModal
        modalProps={ modalProps }
        formProps={ formProps }
        orders={ record?.orders ?? [] }
        total={ record ? record.grandTotal - record.refundTotal : 0 }
      />
      <Card
        type="inner"
        title={`주문 정보`}
        style={{marginBottom: 24}}
        extra={
          record?.pgId ?
          <Button
            size="small"
            onClick={() => show()}
          >결제 취소/환불</Button> : <></>
        }
      >
        {
          record?.orders &&
          record?.orders.length > 0 &&
          record?.orders.map((order: IOrder, i: number) => {
          return <div key={`div-${i}`}>
          <Row key={`order-body-${i}`} gutter={[16, 16]}>
            <Col key={`order-col1-${i}`} span={8}>
              <Row>
                <Col key={`order-sub1-${i}`} flex='40px'>
                  <Link key={`order-link1-${i}`} to={`/orders/show/${order.id}`}>
                    <Avatar
                      key={`order-avatar-${i}`}
                      size={32}
                      shape="square"
                      src={order?.image}
                      alt={order?.title}
                    />
                  </Link>
                </Col>
                <Col key={`order-sub2-${i}`} flex='auto'>
                  <div>
                    <Link key={`order-link2-${i}`} to={`/orders/show/${order.id}`}>
                      <Text key={`order-title-${i}`} style={{marginLeft: 8}}>{ order?.title }</Text>
                    </Link>
                  </div>
                  <div>
                    <Text style={{marginLeft: 8, fontSize: 11}}>{ order?.price.toLocaleString() }원</Text>
                    <Text style={{marginLeft: 8, fontSize: 11}}>{ order?.shipping.toLocaleString() }원</Text>
                    <Text style={{marginLeft: 8, fontSize: 11}}>{ order?.shippingComment }</Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col key={`order-col2-${i}`} span={3}>
              <Tag color={orderStatusColor(order.orderStatus)}>{orderStatusName(order.orderStatus)}</Tag>
            </Col>
            <Col key={`order-col3-${i}`} span={3}>
              { order.trackingNumber
                ? <Tag color={courierColor(order.courier)}>{courierName(order.courier)}</Tag>
                : <Tag>자료없음</Tag>
              }
            </Col>
            <Col key={`order-col4-${i}`} span={4}>
            {
              order.trackingNumber
              ? <Text
                  key={`order-track-no-${i}`}
                >{order.trackingNumber}</Text>
              : <Tag>자료없음</Tag>
            }
            </Col>
            <Col key={`order-col5-${i}`} span={3}>
              {
                order &&
                <Tag color={shippingStatusColor(order.shippingStatus)}>{shippingStatusName(order.shippingStatus)}</Tag>
              }
            </Col>
            <Col key={`order-col6-${i}`} span={3}>
            {
              order.isCombined
              ? <Tag color="indigo" icon={<BlockOutlined />}>묶음배송</Tag>
              : <Tag color="red" icon={<BorderOutlined />}>단일배송</Tag>
            }
            </Col>
          </Row>
          </div>
        })
        }
      </Card>
      <Card
        type="inner"
        title="관련 결제정보"
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제일</Title>
            {record?.paidAt ? <ReactTimeAgo date={Date.parse(record?.paidAt!)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제방법</Title>
            { record?.paymentMethod ? <Tag color={paymentMethodColor(record?.paymentMethod)}>{paymentMethodName(record?.paymentMethod)}</Tag> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제상태</Title>
            { record?.paymentStatus ? <Tag color={paymentStatusColor(record?.paymentStatus)}>{paymentStatusName(record?.paymentStatus)}</Tag> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제아이디</Title>
            { record?.pgId ? <Text>{record?.pgId}</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>가상계좌정보</Title>
            { record?.paymentInfo ? <Text>{record?.paymentInfo}</Text> : <Tag>기록없음</Tag> }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최종 결제금</Title>
            <Text>{ record?.grandTotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>환불 금액</Title>
            <Text>{ record?.refundTotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상품가 소계</Title>
            <Text>{ record?.priceSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>배송비 소계</Title>
            <Text>{ record?.shippingSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>묶음배송 할인</Title>
            <Text>{ record?.shippingDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>쿠폰 할인</Title>
            <Text>{ record?.couponDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>취소/환불 사유</Title>
            { record?.cancelReason ? <Text>{record?.cancelReason}</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>쿠폰</Title>
            {
              record?.grant?.coupon ?
              <>
                <Tag>{record?.grant?.coupon?.name}</Tag>
                <Text>{record?.grant?.coupon?.code} </Text>
                <Text>{record?.grant?.coupon?.discount.toLocaleString()}원</Text>
              </> : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>배송지</Title>
            {
              record?.destination === null ? <Tag>기록없음</Tag> :
              (
                record?.destination?.country === 'kr'
                ? <>
                    <Tag>국내</Tag>
                    <Text>{record?.destination?.address} </Text>
                    <Text>{record?.destination?.addressDetail} </Text>,
                    <Text style={{marginLeft: 8}}>{record?.destination?.postalCode}</Text>
                  </>
                : <>
                    <Tag>해외</Tag>
                    <Text>{record?.destination?.address} </Text>
                    <Text>{record?.destination?.addressDetail} </Text>
                    <Text>{record?.destination?.city}, </Text>
                    <Text>{record?.destination?.state}, </Text>
                    <Text>{record?.destination?.country?.toUpperCase()} </Text>,
                    <Text style={{marginLeft: 8}}>{record?.destination?.postalCode}</Text>
                  </>
              )
            }
          </Col>
          {/* <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>배송요청사항</Title>
            {
              record?.order?.requestMessage === null
              ? <Tag>기록없음</Tag>
              : <Text>{record?.order?.requestMessage} </Text>
            }
          </Col> */}
        </Row>
      </Card>
      <Card type="inner" title="구매 회원">
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.realname}
                />
              }
              <Text style={{marginLeft: 8}}>{record?.user?.username ?? 'n/a'}</Text>
            </Link>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {
              record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화</Title>
            {
              record && record.user &&
              <Text>{phoneNumber(record?.user?.phone)}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            {record?.user?.gender ? (record?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : ''}
            {age ? <Text>{`만${age}세`}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {
              record && record.user && record.user.artist &&
              <Tag color={sellerTypeColor(record?.user?.artist?.sellerType)}>
                {sellerTypeName(record?.user?.artist?.sellerType)}
              </Tag>
            }
          </Col>
        </Row>
      </Card>
    </Show>
  )
};
