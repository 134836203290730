import { EyeOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RcFile,
  Row,
  Select, Upload,
  UploadFile,
  UploadProps,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IBanner } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";

const radioOptions = [
  { label: '노출', value: true },
  { label: '숨김', value: false }, 
];

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const BannerEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useCreate hook
  const { mutate: getSignedUrl } = useCreate();

  // useUpdate hook
  const { mutate: mutateBanner } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IBanner>({
    redirect: false,
  });

  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);  
  const [file, setFile] = useState<RcFile>();
  const [uri, setUri] = useState('');
  const [createObjectUrl, setCreateObjectUrl] = useState('');

  // more constants
  const record = queryResult?.data?.data as IBanner;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? direct file upload via s3 signed url
  //?-------------------------------------------------------------------------//

  const directlyUpload = () => {
    file &&
    file?.type &&
    record?.id &&
    getSignedUrl({
      resource: `banners/${record?.id}/image/url`,
      values: {
        mimeType: file?.type
      }
    },
    {
      onSuccess: async ({ data }) => {
        const s3SignedUrl = data.data;
        try {
          const res = await fetch(s3SignedUrl, {
            method: "POST",
            body: file,
            headers: { 'Content-type': file?.type },
          });
          const uri = res.url.replace(/(https.*?\.com\/)(.*?)(\?.*)/, "$2");
          const imageUrl = `https://cdn.fleaauction.world/${uri}`;
          const newFileList = [{ ...file, url: imageUrl }];

          form.setFieldsValue({ image: imageUrl });
          // save this change automatically
          const inputData = form.getFieldsValue() as any;
          inputData.image = imageUrl;
          mutateBanner({
            resource: `banners`,
            values: inputData,
            id: record.id
          },
          {
            onSuccess: (data, variables, context) => {
              invalidate({ resource: "banners", invalidates: ["detail"] })
            },
          })

          setFileList(newFileList);
        } catch (err) {
          console.error(err);
        }
      },
      onError: (error, variables, context) => {
        // An error occurred!
        console.error(error);
      },
    });
  }

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    fileList: fileList,
    action: `${apiUrl}/banners/${record?.id}/image`,
    headers: headers,
    onChange({ file, fileList: newFileList }) {
      if (file.status === 'done') {
        if (file.response) {
          newFileList = [{
            uid: `-1`,
            name: file.name,
            status: 'done',
            // you can skip the type here
            url: file.response?.image,
          }];
        }

        form.setFieldsValue({ avatar: file.response?.image });
        const inputData = form.getFieldsValue() as any;
        const images = newFileList.map((v) => v.url);
        const values = {
          ...inputData,
          image: images ? images[0] : null,
        }

        mutateBanner({
          resource: `banners`, values, id: record.id,
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "banners", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    beforeUpload: (file, files) => {
      setFile(file as RcFile);
      if (createObjectUrl) {
        URL.revokeObjectURL(createObjectUrl);
      }
      const url = URL.createObjectURL(file);
      setCreateObjectUrl(url);

      setFileList([{ ...file, url }]);
      // return false;
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {

        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`배너: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      const imageUrl = file.url as string;
      if (imageUrl) {
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: imageUrl.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          // update UI
          form.setFieldsValue({ image: null });
          setFileList([]);
        } catch (e) {
          console.error('removing from S3 failed.')
        }
      }
    }
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('banners-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    // console.log(`[useEffect hook] <IBanner> data has been changed. => ${record}`)
    if (record?.image) {
      const url = record?.image;
      if (! url.includes('banner.png')) {
        const filename = url.replace(/^.*[\\/]/, '');
        const extention = url.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
        setFileList([{
          uid: '-1',
          name: filename,
          status: 'done',
          type: `image/${extention}`,
          url: url,
        }])
      }
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button icon={<EyeOutlined />} onClick={(): void => goto("banners", record?.id!)}>Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        {...formProps}
        form={form}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish(values);
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../banners?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }}
        layout="vertical"
      >
        <Card type="inner" title="배너 정보">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="배너명"
                name="title"
                initialValue={record?.title}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="버튼 레이블명"
                name="buttonLabel"
                initialValue={record?.buttonLabel}
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='버튼 타입'
                name='buttonType'
                initialValue={record?.buttonType}
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: '플리색상/검정글자', value: 'info', },
                    { label: '노란바탕/검정글자', value: 'warn', },
                    { label: '빨간바탕/흰글자', value: 'error', },
                    { label: '녹색바탕/흰글자', value: 'success', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="타겟"
                name="target"
                initialValue={record?.target}
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: '기획전', value: 'packs', },
                    { label: '옥션', value: 'auctions', },
                    { label: '작가', value: 'artists', },
                    { label: '아티클', value: 'articles', },
                    { label: '마이페이지', value: 'mypage', },
                    { label: '공지사항', value: 'mypage/news', },
                    { label: '작품판매문의', value: 'mypage/inquiry', },
                    { label: '구매내역', value: 'mypage/orders', },
                    { label: '쿠폰', value: 'mypage/coupons', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="타겟 아이디"
                name="targetId"
              >
                <InputNumber />
              </Form.Item>
            </Col>
              <Col span={6}>
                <Form.Item
                  label="활성상태"
                  name="isActive"

                >
                  <Radio.Group
                    options={radioOptions}
                    value={record?.isActive}
                  />
                </Form.Item>
              </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="사진 업로드">
                <Form.Item
                  name="image"
                  valuePropName="[fileList]"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                <Upload { ...uploadProps }>
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handlePreviewModalClose}>
                  <img alt="banner" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};
