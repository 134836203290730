import { PlusSquareOutlined } from '@ant-design/icons';
import {
  Button,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Input,
  List, NumberField,
  SaveButton,
  Space,
  Table,
  TagField,
  TextField,
  useEditableTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate } from "@pankod/refine-core";
import { IHashtag } from "interfaces";
import ReactTimeAgo from "react-time-ago";

export const HashtagList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    sorter,
    setId,
    tableQueryResult
  } = useEditableTable<IHashtag>({
    initialSorter: [],
    initialPageSize: 20,
    syncWithLocation: true
  });

  const record = (tableQueryResult?.data as any)?.data as IHashtag;

  const { mutate: mutateOrder,  } = useCreate<IHashtag>();
  const createHashtag = (id: string, parentId: number | null, title: string) => {
    mutateOrder(
      {
        resource: `hashtags`,
        values: {
          key: id,
          parentId: parentId,
          title: title,
        }
      },
      {
        onSuccess: () => {
          tableQueryResult?.refetch();
          // message.success('오더를 생성하였습니다.');
        }
      }
    );
  }

  return (
    <List
      headerButtons={() => (
        <Button
          icon={<PlusSquareOutlined />}
          onClick={() => createHashtag(`0`, null, `추가 해쉬택`)}
        >1st depth 추가</Button>
      )}
    >
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          dataSource={(tableProps?.dataSource as any)?.data}
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === "TD") {
                setId && setId(record.id);
              }
            },
          })}
        >
          <Table.Column<IHashtag>
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <NumberField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column<IHashtag>
            dataIndex="title"
            key="title"
            title="해쉬택 이름"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Form.Item name="title" style={{margin: 0}}>
                    <Input />
                  </Form.Item>
                );
              }
              return <>
                <TextField value={record?.title} />
              </>;
            }}
          />
          <Table.Column<IHashtag>
            key="key"
            dataIndex="key"
            title="해쉬택 키"
            render={(value, data: any) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item name="key" style={{margin: 0}}>
                    <Input />
                  </Form.Item>
                );
              }
              return <TextField value={value} />;
            }}
          />
          <Table.Column<IHashtag>
            dataIndex="children"
            key="children"
            title="Depth"
            render={(value) => <TagField
              color={value ? 'indigo' : ''}
              value={value ? `1st` : `2nd`}
            />}
          />
          <Table.Column<IHashtag>
            dataIndex="createdAt"
            key="createdAt"
            title="생성일"
            render={(value) => {
              return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column<IHashtag>
            dataIndex="actions"
            title="메뉴"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                        {...saveButtonProps}
                        size="small"
                    />
                    <Button
                        {...cancelButtonProps}
                        size="small"
                    >
                        Cancel
                    </Button>
                  </Space>
                );
              }
              return (
                <Space>
                  {
                    record?.children &&
                    <Button
                      size="small"
                      icon={<PlusSquareOutlined />}
                      style={{ marginLeft: 8 }}
                      onClick={() => createHashtag(`${record?.id}`, record?.id, `${record?.title} 해쉬택`)}
                    >2nd depth 추가</Button>
                  }
                  <EditButton
                    {...editButtonProps(record.id)}
                    hideText
                    size="small"
                  />
                  <DeleteButton
                    recordItemId={record.id}
                    hideText
                    size="small"
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
