import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Typography
} from "@pankod/refine-antd";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { IOrder } from "interfaces";
import { useState } from "react";

const { Title, Text } = Typography;

type RefundModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  orders: Array<IOrder>;
  total: number;
};

export const RefundModal: React.FC<RefundModalProps> = ({
  modalProps,
  formProps,
  orders,
  total,
}) => {

  const [ids, setIds] = useState<Array<number>>([]);
  const onChange = (checkedValues: CheckboxValueType[]) => {
    console.log('checked = ', checkedValues);
    const vals = checkedValues as Array<number>;
    setIds(vals);
  };


  // const { queryResult } = useShow<IPayment>({ id: paymentId });
  // const record = queryResult?.data?.data as IPayment;
  // console.log(record);

  return (
    <Modal {...modalProps} title="취소/환불 정보입력">
      <Form
        { ...formProps }
        onFinish={(values) => {

          // if (!values.courier || !values.trackingNumber) {
          //   message.error('취소할 ORDER 만 체크하세요.')
          //   return false;
          // }
          // if (values.courier === 'EMS') {
          //   const code = values.trackingNumber.toUpperCase();
          //   if (/^[A-Z]{2}.*?KR$/.test(code) === false) {
          //     message.error('EMS 번호 형식이 맞지 않습니다. 확인후 다시 입력하세요.')
          //     return false;
          //   }
          // }

          formProps.onFinish && formProps.onFinish({
            ...values, orderIds: ids
          });
        }}
        layout="vertical"
      >
        <Card
          type="inner"
          title="관련 ORDER"
          extra="취소할 ORDER 를 모두 체크하세요."
          style={{marginBottom: 24}}
        >
          <Checkbox.Group style={{width: '100%'}} onChange={onChange}>
          {
            orders.length > 0 &&
            orders.map((order: IOrder, i: number) =>
            <div key={`div-${i}`}>
              <Row key={`order-row-${i}`} gutter={[16, 16]}>
                <Col key={`order-col1-${i}`} span={4}>
                  <Checkbox key={`checkbox-${i}`} value={order.id}></Checkbox>
                  <Avatar
                    size={32}
                    style={{marginLeft: 16}}
                    shape="square"
                    src={order?.image}
                    alt={order?.title}
                  />
                </Col>
                <Col key={`order-col3-${i}`} span={12}>
                  <div><Text>{ order?.title }</Text></div>
                  <div><Text style={{fontSize: 11}}>{ order?.subtitle }</Text></div>
                  
                </Col>
                <Col key={`order-col4-${i}`} span={8}>
                  <div><Text style={{fontSize: 11}}>{ order?.price.toLocaleString() }원</Text></div>
                  <div><Text style={{fontSize: 11}}>{ order?.shipping.toLocaleString() }원</Text> (<Text style={{fontSize: 11}}>{ order?.shippingComment }</Text>)</div>
                </Col>
              </Row>
            </div>
            )
          }
          </Checkbox.Group>
        </Card>
        <Card
          type="inner"
          title="환불 정보"
          extra={`최대 ${total.toLocaleString()}원 까지 취소 가능.`}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="취소/환불액"
                name="refundTotal"
                required={true}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="취소/환불 사유"
                name="cancelReason"
                required={true}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
