import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "@pankod/refine-antd";
import { useLogin, useRouterContext, useTranslate } from "@pankod/refine-core";
import React from "react";
import "./styles.css";

const { Text, Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const LoginPage: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();
  const translate = useTranslate();
  const { Link } = useRouterContext();
  const { mutate: login } = useLogin<ILoginForm>();
  const CardTitle = (
    <Title level={1} className="title" style={{ color: '#666' }}>
      {process.env.REACT_APP_ENV?.toUpperCase()} 관리자
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="https://cdn.fleaauction.world/svgs/logo.svg" alt="Company Logo" />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={
                  (values) => {
                    if (values.remember) {
                      localStorage.setItem('username', values.username);
                      localStorage.setItem('password', values.password);
                    }
                    login(values);
                  }
                }
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Email"
                  initialValue={localStorage.getItem('username') ?? ''}
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    placeholder="email"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  initialValue={localStorage.getItem('password') ?? ''}
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <div style={{ marginBottom: "12px" }}>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox style={{ fontSize: "12px" }}>
                      Remember me
                    </Checkbox>
                  </Form.Item>
                  <Link style={{ float: "right" }} to="/forgot-password">
                    {translate("pages.forgotPassword", "Forgot password")}
                  </Link>
                </div>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Sign in
                </Button>
              </Form>
              <div style={{ marginTop: 8 }}>
                <Text style={{ fontSize: 12 }}>
                  Don’t have an account?{" "}
                  <a href="#" style={{ fontWeight: "bold" }}>
                    Sign up
                  </a>
                </Text>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
