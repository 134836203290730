import { RetweetOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Checkbox,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  message,
  Popover,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { IAuction, IPack } from "interfaces";
import { useState } from 'react';
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const PackList: React.FC<IResourceComponentsProps> = () => {
  const [ids, setIds] = useState<number[]>([]);
  const { tableProps, sorter, tableQueryResult } = useTable<IPack>({
    // initialSorter: [],
    initialPageSize: 20,
    syncWithLocation: true
  });

  const { mutate } = useUpdate();

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<RetweetOutlined />}
            onClick={(): void => {
              if (ids.length != 2) {
                message.error('순서를 바꾸고 싶은 2개 아이템을 선택하세요.');
              } else {
                console.log(`selected: ${ids}`);
                setIds([]);
                mutate({
                  resource: "packs",
                  values: {
                      ids: ids,
                  },
                  id: 'swap',
                  invalidates: ['list'],

                },
                {
                  onSuccess: (data, variables, context) => {
                    tableQueryResult.refetch();
                  }
                });
              }
            }}
          >Swap</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IPack>
          dataIndex="id"
          key="id"
          title="ID"
          render={(_, record) => <TextField key={record.id} value={record.id} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="image"
          title="배너사진"
          render={(_, record) => (
            <Link key={record.id} to={`/packs/show/${record?.id}`}>
              <Avatar
                size={64}
                shape="square"
                src={record?.image}
                alt={record?.title}
              />
            </Link>
          )}
        />
        <Table.Column<IPack>
          dataIndex="title"
          key="title"
          title="기획전 제목"
          render={(_, record) => (record.isPublished) ? <TextField key={record.id} strong value={record.title} /> : <TextField key={record.id} delete value={record.title} />}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="artistCount"
          key="artistCount"
          title="아티클 수"
          render={(_, record) => <>
          <Popover key={record.id} content={
            record.articles?.map((v) => <div key={v.id}>{v.title}</div>)
          } title="아티클">
            <TextField value={`${record.articles?.length}개`} />
          </Popover>
          </>}
        />
        <Table.Column<IPack>
          dataIndex="artistCount"
          key="artistCount"
          title="작가,작품수"
          render={(_, record) => <>
          <Popover
            key={record.id}
            content={
              record
                .auctions?.sort((a: IAuction, b: IAuction) => (a.artistName ?? '').localeCompare(b.artistName ?? ''))
                .map((v) => <div key={v.id}>{v.title} by {v.artistName}</div>)
            }
            title="작품"
          >
            <TextField value={`${record.artistCount}명, ${record.artworkCount}개`} />
          </Popover>
          </>}
        />
        <Table.Column<IPack>
          dataIndex="weeks"
          key="weeks"
          title="주차"
          render={(_, record) => <TextField key={record.id} value={record.weeks} />}
        />
        <Table.Column<IPack>
          dataIndex="sortIndex"
          key="sortIndex"
          title="정렬순서"
          render={(_, record) =>
          <div key={record.id}>
            <Checkbox checked={ids.includes(record.id)} onChange={(event) => {
              if (event.target.checked) {
                setIds([...ids, record.id]);
              } else {
                setIds(ids.filter((v) => v !== record.id));
              }
            }}>{record.sortIndex}</Checkbox>
          </div>
        }
        />
        <Table.Column<IPack>
          dataIndex="startTime"
          key="startTime"
          title="시작"
          render={
            (_, record) => <div key={record.startTime}>
              <div><ReactTimeAgo date={Date.parse(record.startTime)} locale="ko" /></div>
              <div><TextField style={{fontSize: 9}} value={dayjs(record.startTime).format('YY-MM-DD HH:mm:ss')} /></div>
            </div>
          }
          defaultSortOrder={getDefaultSortOrder("startTime", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="endTime"
          key="endTime"
          title="종료"
          render={
            (_, record) => <div key={record.id}>
              <div><ReactTimeAgo date={Date.parse(record.endTime)} locale="ko" /></div>
              <div><TextField style={{fontSize: 9}} value={dayjs(record.endTime).format('YY-MM-DD HH:mm:ss')} /></div>
            </div>
          }
          defaultSortOrder={getDefaultSortOrder("endTime", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="status"
          key="status"
          title="구분"
          render={(_, record) => <>
            <TagField
              key={record.id}
              style={{marginLeft: 8}}
              color={auctionStatusColor(record.status)}
              value={auctionStatusName(record.status)}
            />
            </>
          }
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="isPublished"
          key="isPublished"
          title="활성"
          render={(_, record) => {
            return record?.isPublished
              ? <TagField key={record.id} color='green' value='노출' />
              : <TagField key={record.id} value='숨김' />
          }}
          defaultSortOrder={getDefaultSortOrder("isPublished", sorter)}
          sorter
        />
        <Table.Column<IPack>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근수정"
          render={(_, record) => <ReactTimeAgo key={record.id} date={Date.parse(record.updatedAt)} locale="ko" />}
        />
        <Table.Column<IPack>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space key={record.id}>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
