import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Show,
  Tag,
  Typography,
  useModal,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AgeFromDateString } from 'age-calculator';
import { genreName } from "helpers/artwork";
import {
  invoiceStatusColor,
  invoiceStatusName
} from "helpers/invoice";
import { paymentMethodColor, paymentMethodName } from 'helpers/payment';
import { countryName, sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IInvoice } from "interfaces";

import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const InvoiceShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();

  // useShow
  const { queryResult } = useShow<IInvoice>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user?.dob).age : null;

  // useModal
  const { modalProps: showModalProps, show: showModal } = useModal();

  // useModalForm
  const { formProps, modalProps: editModalProps, show } = useModalForm({
    action: 'edit',
    resource: `invoices/${record?.id}`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title={`리셀 정보`}
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작품</Title>
            <Row>
              <Col flex='40px'>
                <Link to={`/artworks/show/${record?.artwork?.id}`}>
                  <Avatar
                    size={32}
                    shape="square"
                    src={record?.image}
                    alt={record?.title}
                  />
                </Link>
              </Col>
              <Col flex='auto'>
                <div><Text style={{marginLeft: 8}}>{ record?.title }</Text></div>
                <div>
                  <Text style={{marginLeft: 8, fontSize: 11}}>수수료: { record?.price.toLocaleString() }원</Text>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record &&
              <Tag color={invoiceStatusColor(record?.invoiceStatus)}>{invoiceStatusName(record?.invoiceStatus)}</Tag>
            }
          </Col>
        </Row>
        {
          record?.payment?.invoices &&
          record?.payment?.invoices.length > 1 &&
          record?.payment?.invoices.map((invoice: IInvoice, i: number) => {
          return <div key={`div-${i}`}>
          { i === 0 &&
          <Row key={`invoice-header-${i}`} gutter={[16, 16]}>
            <Col key={`invoice-header-col-${i}`} span={24}>
              <Divider plain>같이 결제한 상품 리스트</Divider>
            </Col>
          </Row>
          }
          <Row key={`invoice-body-${i}`} gutter={[16, 16]}>
            <Col key={`invoice-col1-${i}`} span={8}>
              <Row>
                <Col key={`invoice-sub1-${i}`} flex='40px'>
                  <Link key={`invoice-link1-${i}`} to={`/invoices/show/${invoice.id}`}>
                    <Avatar
                      key={`invoice-avatar-${i}`}
                      size={32}
                      shape="square"
                      src={invoice?.image}
                      alt={invoice?.title}
                    />
                  </Link>
                </Col>
                <Col key={`invoice-sub2-${i}`} flex='auto'>
                  <div>
                    <Link key={`invoice-link2-${i}`} to={`/invoices/show/${invoice.id}`}>
                      <Text key={`invoice-title-${i}`} style={{marginLeft: 8}}>{ invoice?.title }</Text>
                    </Link>
                  </div>
                  <div>
                    <Text style={{marginLeft: 8, fontSize: 11}}>상품: { invoice?.price.toLocaleString() }원</Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col key={`invoice-col2-${i}`} span={3}>
              <Tag color={invoiceStatusColor(invoice.invoiceStatus)}>{invoiceStatusName(invoice.invoiceStatus)}</Tag>
            </Col>
          </Row>
          </div>
        })
        }
      </Card>
      <Card
        type="inner"
        title="관련 결제정보"
        extra={
          record?.payment ?
          <Button
            size="small"
            onClick={(): void => goto("payments", record?.payment?.id!)}
          >결제보기</Button> : <></>
        }
        style={{marginBottom: 24}}
      >
      {
        record?.payment ?
        <>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제일</Title>
            {record?.payment?.paidAt ? <ReactTimeAgo date={Date.parse(record?.payment?.paidAt!)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제방법</Title>
            { record?.payment?.paymentMethod
              ? <Tag color={paymentMethodColor(record?.payment?.paymentMethod)}>{paymentMethodName(record?.payment?.paymentMethod)}</Tag>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제상태</Title>
            { record?.payment?.paymentStatus ? <Tag>{record?.payment?.paymentStatus}</Tag> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제아이디</Title>
            { record?.payment?.paymentInfo ? <Text>{record?.payment?.pgId}</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>가상계좌정보</Title>
            { record?.payment?.paymentInfo ? <Text>{record?.payment?.paymentInfo}</Text> : <Tag>기록없음</Tag> }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최종 결제금</Title>
            <Text>{ record?.payment?.grandTotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상품가 소계</Title>
            <Text>{ record?.payment?.priceSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>배송비 소계</Title>
            <Text>{ record?.payment?.shippingSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>묶음배송 할인</Title>
            <Text>{ record?.payment?.shippingDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>쿠폰 할인</Title>
            <Text>{ record?.payment?.couponDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>특이사항</Title>
            <Text></Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>배송지</Title>
            {
              record?.payment?.destination === null ? <Tag>기록없음</Tag> :
              (
                record?.payment?.destination?.country === 'kr'
                ? <>
                    <Tag>국내</Tag>
                    <Text>{record?.payment?.destination?.address} </Text>
                    <Text>{record?.payment?.destination?.addressDetail} </Text>,
                    <Text style={{marginLeft: 8}}>{record?.payment?.destination?.postalCode}</Text>
                  </>
                : <>
                    <Tag>해외</Tag>
                    <Text>{record?.payment?.destination?.address} </Text>
                    <Text>{record?.payment?.destination?.addressDetail} </Text>
                    <Text>{record?.payment?.destination?.city}, </Text>
                    <Text>{record?.payment?.destination?.state}, </Text>
                    <Text>{record?.payment?.destination?.country?.toUpperCase()} </Text>,
                    <Text style={{marginLeft: 8}}>{record?.payment?.destination?.postalCode}</Text>
                  </>
              )
            }
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>쿠폰정보</Title>
            {
              record?.payment?.grant?.coupon ?
              <>
                <Tag>{record?.payment?.grant?.coupon?.name}</Tag>
                <Text>{record?.payment?.grant?.coupon?.code} </Text>
                <Text>{record?.payment?.grant?.coupon?.discount.toLocaleString()}원</Text>
              </> : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        </> : <Row><Col><Tag>기록없음</Tag></Col></Row>
      }
      </Card>
      <Card type="inner" title="신청 회원정보" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.realname}
                />
              }
              <Text style={{marginLeft: 8}}>{record?.user?.username ?? 'n/a'}</Text>
            </Link>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화</Title>
            {
              record && record.user &&
              <Text>{phoneNumber(record?.user?.phone)}</Text>
            }
            
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            {record?.user?.gender ? (record?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : ''}
            {age ? <Text>{`만${age}세`}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {
              record && record.user && record.user.artist &&
              <Tag color={sellerTypeColor(record?.user?.artist?.sellerType)}>
                {sellerTypeName(record?.user?.artist?.sellerType)}
              </Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="오너정보"
      >
        {!record?.artwork?.owner && (
          <Row gutter={[16, 16]}>
            <Col span={24}><Text>셀러정보가 없는 일반사용자입니다.</Text></Col>
          </Row>)}
        {record?.artwork?.owner && (
          <Row gutter={[16, 16]} style={{marginBottom: 16}}>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>셀러타입</Title>
              <Text style={{marginRight: 8}}>{sellerTypeName(record?.artwork?.owner?.sellerType)}</Text>
              <Tag color={record?.artwork?.owner?.isApproved ? 'green' : 'red'}>{record?.artwork?.owner?.isApproved ? '인증' : '미인증'}</Tag>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>작가명</Title>
              <Text>{record?.artwork?.owner?.name ?? ''}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>장르</Title>
              {
                record && record.artwork && record.artwork.owner &&
                <Text>{genreName(record?.artwork?.owner?.genre)}</Text>
              }
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>국적</Title>
              {
                record && record.artwork && record.artwork.owner &&
                <Text>{countryName(record?.artwork?.owner?.nationality)}</Text>
              }
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>생성일</Title>
              {
                record?.artwork?.owner?.createdAt ? <ReactTimeAgo date={Date.parse(record?.artwork?.owner?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
              {
                record?.artwork?.owner?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.artwork?.owner?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
          </Row>
        )}
      </Card>
    </Show>
  )
};
