import {
  Avatar,
  Button,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  ShowButton,
  Space,
  Table, TagField, TextField,
  useTable
} from "@pankod/refine-antd";
import { VerticalLeftOutlined } from '@ant-design/icons';
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { categoryName, firstImageUrl } from "helpers/artwork";
import { avatarUrl } from "helpers/user";
import { IArtwork } from "interfaces";
import { useRef } from "react";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;
const { useLocation } = routerProvider;

export const ArtworkList: React.FC<IResourceComponentsProps> = () => {

  const loc = useLocation();
  const params = loc.search.substring(1).split('&');
  const apiUrl = useApiUrl();
  const artworkId = useRef<number>();

  const onChangeHandler = (e: any) => {
    const v = e.target?.value;
    if (+v > 0) {
      artworkId.current = +v;
    }
  }

  const moveToPage = async (id: number) => {
    const res = await fetch(`${apiUrl}/artworks/pages/closest?${params[0]}&id=${artworkId.current}`)
    const { data: page } = (await res.json()) as { data: number }
    const paramString = params.map((v) => {
      if (v.startsWith('current=')) {
        return `current=${page}`;
      }
      return v;
    }).join('&')
    const targetUrl = `${window.location.origin}${loc.pathname}?${paramString}`
    window.location.replace(targetUrl);
  }

  const { tableProps, sorter } = useTable<IArtwork>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });



  return (
    <List
      headerButtons={({ defaultButtons }) => (
      <>
        <Input placeholder="Artwork #" onChange={onChangeHandler} />
        <Button
          icon={<VerticalLeftOutlined />}
          onClick={async () => {
            if (artworkId.current && artworkId.current > 0) {
              console.log(`${apiUrl}/artworks/pages/closest?${params[0]}&id=${artworkId.current}&`)
              await moveToPage(artworkId.current);
            }
          }}
        >페이지 이동</Button>
        { defaultButtons }
        </>
      )}
    >
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/artworks/show/${record?.id}`}>
              <Avatar
                size={64}
                shape="square"
                src={firstImageUrl(record?.images)}
                alt={record?.title}
              />
            </Link>
          )}
        />
        <Table.Column<IArtwork>
          dataIndex="artistName"
          key="artistName"
          title="작가"
          render={(_, record) => {
            return record?.artist
            ? 
              <Link to={`/artists/show/${record?.artist?.id}`}>
                {
                  record && record.artist && record.artist.user &&
                  <Avatar
                    size={32}
                    shape="square"
                    src={avatarUrl(record?.artist?.user?.avatar)}
                    alt={record?.owner?.user?.username}
                  />
                }
                <TextField style={{marginLeft: 8}} value={record?.artist?.name} />
              </Link>
            : <TextField value={record?.artistName} />
          }}
          defaultSortOrder={getDefaultSortOrder("artistName", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="title"
          key="title"
          title="제목"
          render={(_, record) => <>
            <TagField value={record?.size} style={{ marginRight: 4 }} />
            <TextField value={record?.title} style={{fontWeight: "bold"}} />
            <div>{ record?.place ?
              record.place === 'atelier' ?
                <TextField style={{ fontSize: 10 }} type="danger" value={`판매요청: 작가요청작`} />
                : <TextField style={{ fontSize: 10 }} type="danger" value={`판매요청: ${record.boughtIn ?? '구매년도 미상'} ${record.place} 구매`} />
                : <TextField style={{ fontSize: 10 }} value="회사입력" /> }</div>
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="startingPrice"
          key="startingPrice"
          title="시작가"
          render={(value) => <TextField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("startingPrice", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="estimate"
          key="estimate"
          title="추정가"
          render={(value) => <TextField value={value.toLocaleString()} />}
          defaultSortOrder={getDefaultSortOrder("estimate", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="category"
          key="category"
          title="분류"
          render={(value) => <TextField value={categoryName(value)} />}
          defaultSortOrder={getDefaultSortOrder("category", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근수정"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IArtwork>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
