export const orderStatusColor = (e: string): string => {
  switch (e) {
    case 'PAID':
      return 'indigo'
    case 'WAITING':
      return ''
    case 'MISCARRIED':
      return 'orange'
    case 'REFUNDED':
      return 'purple'
  }
  return 'red'
}

export const orderStatusName = (e: string): string => {
  switch (e) {
    case 'PAID':
      return '결제완료'
    case 'WAITING':
      return '결제대기'
    case 'MISCARRIED':
      return '유찰'
    case 'REFUNDED':
      return '결제환불'
  }
  return '결제취소'
}

export const shippingStatusColor = (e: string | null): any => {
  switch (e) {
    case 'PACKAGING':
      return 'red'
    case 'NUMBER_ISSUED':
      return 'orange'
    case 'IN_TRANSIT':
      return 'blue'
    case 'DELIVERED':
      return 'green'
  }
  return null
}

export const shippingStatusName = (e: string | null): string => {
  switch (e) {
    case 'PACKAGING':
      return '준비중'
    case 'NUMBER_ISSUED':
      return '송장번호발급'
    case 'IN_TRANSIT':
      return '배송중'
    case 'DELIVERED':
      return '배송완료'
  }
  return '기록없음'
}

export const courierColor = (e: string): any => {
  switch (e) {
    case 'EMS':
      return 'green'
    case 'KDEXP':
      return 'blue'
  }
  return null
}

export const courierName = (e: string): string => {
  switch (e) {
    case 'EMS':
      return '우체국EMS'
    case 'KDEXP':
      return '경동택배'
  }
  return '기록없음'
}

export const orderTypeColor = (e: string): string => {
  return e === 'AUCTION' ? 'blue' : 'indigo'
}

export const orderTypeName = (e: string): string => {
  return e === 'AUCTION' ? '옥션' : '즉구'
}
