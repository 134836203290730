export const reportStatusColor = (e: string): string => {
  switch (e) {
    case 'PENDING':
      return 'red'
  }
  return 'indigo'
}

export const reportStatusName = (e: string): string => {
  switch (e) {
    case 'PENDING':
      return '미완료'
  }
  return '완료'
}

export const reporTargetName = (e: string): string => {
  switch (e) {
    case 'USER':
      return '사용자'
  }
  return '아티클댓글'
}
