import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table, TagField, TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IBanner } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const BannerList: React.FC<IResourceComponentsProps> = () => {

  const now = (new Date()).getTime();

  const { tableProps, sorter } = useTable<IBanner>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={(tableProps?.dataSource as any)?.data}
      >
        <Table.Column<IBanner>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IBanner>
          dataIndex="name"
          key="name"
          title="배너명"
          render={(_, record) => (
          <Link to={`/banners/show/${record?.id}`}>
            <Avatar
              size={64}
              shape="square"
              src={record?.image ?? 'https://cdn.fleaauction.world/images/banner.png'}
              alt={record?.title}
            />
            <TextField value={`${record.title}`} style={{ marginLeft: 8 }} />
          </Link>
          )}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IBanner>
          dataIndex="target"
          key="target"
          title="이동 타겟"
          render={(_, record) => 
            <>
            <TextField value={record.target} />
            {record.targetId ? <TextField value={`#${record.targetId}`} style={{ marginLeft: 8 }} /> : ``}
            </>
          }
        />
        <Table.Column<IBanner>
          dataIndex="buttonLabel"
          key="buttonLabel"
          title="버튼 레이블"
          render={(_, record) => <>{
            <TextField value={`${record?.buttonLabel}`} />
          }</>}
        />
        <Table.Column<IBanner>
          dataIndex="isActive"
          key="isActive"
          title="노출"
          render={(_, record) => 
            <>
            {
              record?.isActive ? <TagField
              color='green'
              value='활성'
            /> : <TagField value='비활성' />
            }
            </>
          }
        />
        <Table.Column<IBanner>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
