import {
  AppstoreTwoTone
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Col,
  Form,
  FormProps, Modal,
  ModalProps,
  Row,
  Typography
} from "@pankod/refine-antd";
import { IShortArtist } from "interfaces";
import { SortAuctions } from "pages/packs/components/show/sort-auctions";
import { Dispatch, SetStateAction } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const { Title, Text } = Typography;

type SortArtistModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  shortArtists: Array<IShortArtist>,
  setShortArtists: Dispatch<SetStateAction<IShortArtist[]>>;
  // close: () => void,
};

const getArtistListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightGray' : '#F0F2F5',
  padding: 16,
});

const getArtistItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  maxHeight: 64 + 32,
  background: isDragging ? 'lightBlue' : '#F0F2F5',
  ...draggableStyle,
});

export const SortArtistModal: React.FC<SortArtistModalProps> = ({
  formProps,
  modalProps,
  shortArtists,
  setShortArtists,
}) => {
  // const [, setShortArtists] = useState<Array<IShortArtist>>();

  // event handlers
  const handleChange = (result: any) => {
    if (!result.destination) return;

    const artists = [...shortArtists];
    if (result.type === "ARTIST") {
      const [reorderedItem] = artists.splice(result.source.index, 1);
      artists.splice(result.destination.index, 0, reorderedItem);
      console.log(artists)

    } else {
      const items = [...shortArtists[parseInt(result.type, 10)].auctions];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      artists[parseInt(result.type, 10)].auctions = items;
      console.log(artists)
    }
    setShortArtists(artists);
  };
  
  return (
    <Modal {...modalProps} width={`80%`} title="PACK 리스트 순서변경">
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            artists: shortArtists,
          });
        }}    
        layout="vertical"
      >
        <Card
          type="inner"
          title="리스트의 순서를 Drag & Drop 으로 변경합니다."
        >
          <DragDropContext
            onDragEnd={handleChange}
          >
            <Droppable droppableId="droppable" type="ARTIST">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getArtistListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {shortArtists.map((artist, artistIdx) => (
                    <Draggable
                      key={`artist-${artist.id}`}
                      draggableId={`artist-${artist.id}`}
                      index={artistIdx}
                    >
                      {(provided, snapshot) =>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getArtistItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={8}>
                              <div
                                style={{ display: 'flex', padding: 16, alignItems: 'center' }}
                              >
                                <AppstoreTwoTone {...provided.dragHandleProps} />
                                <Avatar
                                  size={64}
                                  src={artist.avatar}
                                  alt={artist.name}
                                  style={{ marginLeft: 24, marginRight: 16 }}
                                />
                                <Text>{artist.name}</Text>                              
                              </div>
                            </Col>
                            <Col span={16}>
                              <div style={{display: 'flex'}}>
                                <SortAuctions artistIdx={artistIdx} artist={artist} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Form>
    </Modal>
  );
};
