import {
  Button,
  Card,
  Modal,
  ModalProps,
  Tree,
  Typography
} from "@pankod/refine-antd";
import { useApiUrl, useList } from "@pankod/refine-core";
import type { TreeProps } from 'antd/es/tree';
import axios from 'axios';
import { IHashtag } from "interfaces";
import { useState } from "react";
import { ACCESS_TOKEN } from "../../../../constants";
const { Text } = Typography;

type EditHashtagsModalProps = {
  modalProps: ModalProps;
  artworkId: number,
  hashtagKeys: string[],
  close: () => void
};

export const EditHashtagsModal: React.FC<EditHashtagsModalProps> = ({
  modalProps, artworkId, hashtagKeys, close
}) => {
  const apiUrl = useApiUrl();
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const [userKeys, setUserKeys] = useState<string[]>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [hashtags, setHashtags] = useState<IHashtag[]>([])

  const syncArtworkHashtags = async () => await axios({
    method: 'post',
    url: `${apiUrl}/artworks/${artworkId}/hashtags`,
    data: {keys: userKeys},
    headers: headers,
  })

  // useList
  const { refetch } = useList<IHashtag>({
    resource: `hashtags`,
    queryOptions: {
      onSuccess: (data) => {
        const hashtags = ((data?.data as any)?.data as IHashtag[]);
        setHashtags(hashtags);
        // setUserKeys(hashtagKeys);
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const onOk = async (values: any) => {
    await syncArtworkHashtags();
    close();
  }

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setUserKeys([...(checkedKeys as string[])]);
  };

  const onExpand: TreeProps['onExpand'] = (info) => {
    setExpandedKeys([...(info as string[])])
  };

  return (
      <Modal {...modalProps} title="Hashtags" destroyOnClose={true} onOk={onOk}>
        <Card
          type="inner"
          title="해쉬택 리스트"
          extra={
            <Button
              size="small"
              onClick={() => {
                if (expandedKeys.length === 0) {
                  const _parents = hashtags.filter((v) => v.children !== undefined).map((v) => v.key);
                  setExpandedKeys(_parents)
                } else {
                  setExpandedKeys([])
                }
              }}
            >{ expandedKeys.length === 0 ? '모두열기' : '모두닫기' }</Button>

          }
        >
          <Tree
            checkable
            selectable={false}
            expandedKeys={expandedKeys}
            defaultCheckedKeys={hashtagKeys}
            // onSelect={onSelect}
            onExpand={onExpand}
            onCheck={onCheck}
            treeData={hashtags}
          />
        </Card>
      </Modal>
  );
};
