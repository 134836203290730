export const bankName = (e: string): string => {
  switch (e) {
    case 'KEBHANA':
      return '하나은행'
    case 'SCFIRST':
      return 'SC제일은행'
    case 'KBSTAR':
      return '국민은행'
    case 'SHINHAN':
      return '신한은행'
    case 'KEB':
      return '외환은행'
    case 'WOORIBANK':
      return '우리은행'
    case 'CITIBANK':
      return '한국씨티은행'
    case 'KNBANK':
      return '경남은행'
    case 'KJBANK':
      return '광주은행'
    case 'DGB':
      return '대구은행'
    case 'BUSANBANK':
      return '부산은행'
    case 'JBBANK':
      return '전북은행'
    case 'JEJUBANK':
      return '제주은행'
    case 'IBK':
      return '기업은행'
    case 'NONGHYUP':
      return '농협'
    case 'SUHYUP':
      return '수협'
    case 'CU':
      return '신협'
    case 'KFCC':
      return '새마을금고'
    case 'NFCF':
      return '산림조합'
    case 'KDB':
      return '한국산업은행'
    case 'KAKAO':
      return '카카오뱅크'
    case 'TOSS':
      return '토스뱅크'
    case 'KBANK':
      return '케이뱅크'
    case 'EPOSTBANK':
      return '우체국'
    case 'FSB':
      return '저축은행'
  }
  return 'n/a'
}
