import {
  Form,
  FormProps, InputNumber, Modal, ModalProps
} from "@pankod/refine-antd";

type EditHandlingModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditHandlingModal: React.FC<EditHandlingModalProps> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} title="핸들링fee 일괄적용" width={240}>
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values
          });
        }}    
        layout="vertical"
      >
        <Form.Item label="금액 (숫자)" name="handlingFee">
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => `${value}`.replace(/[,]+/g, '')}
          />
        </Form.Item>
        <div>현 기획전에 포함된 모든 경매상품의 핸들링fee 를 일괄조정합니다.</div>
      </Form>
    </Modal>
  );
};
