export const newsCategoryColor = (e: string): string => {
  switch (e) {
    case 'AD':
      return 'red'
    case 'EVENT':
      return 'orange'
  }
  return ''
}

export const newsCategoryName = (e: string): string => {
  switch (e) {
    case 'AD':
      return '홍보'
    case 'EVENT':
      return '이벤트'
  }
  return '뉴스'
}
