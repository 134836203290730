import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { IPayment } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";

// todo. when an exception is thrown, the latest change has to be reset on the UI.
export const PaymentEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IPayment>();

  // useState hook
  const [uri, setUri] = useState('');

  // constant definition to be used here
  const record = queryResult?.data?.data as IPayment;

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('payments-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  // useEffect(() => {
  //   console.log(`[useEffect hook] <IPayment> data has been changed. => ${record}`)
  // }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button icon={<EyeOutlined />} onClick={(): void => goto("payments", record?.id!)}>Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form
          {...formProps}
          form={form}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish(values);
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../payments?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title="결제 정보"
          >
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  label="상품가 소계"
                  name="priceSubtotal"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="배송비 소계"
                  name="shippingSubtotal"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="배송비 할인"
                  name="shippingDiscount"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="쿠폰 할인"
                  name="couponDiscount"
                  rules={[{required: false}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="결제금 총합"
                  name="grandTotal"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="환불금액"
                  name="refundTotal"
                  rules={[{required: false}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="결제방법"
                  name="paymentMethod"
                >
                  <Select
                    options={[
                      { label: '신용카드', value: 'card', },
                      { label: '실시간계좌이체', value: 'trans', },
                      { label: '가상계좌', value: 'vbank', },
                      { label: '휴대폰소액결제', value: 'phone', },
                      { label: '네이버페이', value: 'naverpay', },
                      { label: '삼성페이', value: 'samsung', },
                      { label: 'K페이앱', value: 'kpay', },
                      { label: '카카오페이', value: 'kakaopay', },
                      { label: '페이코', value: 'payco', },
                      { label: 'L페이 ', value: 'lpay', },
                      { label: 'SSG페이', value: 'ssgpay', },
                      { label: '토스간편결제', value: 'tosspay', },
                      { label: '문화상품권', value: 'cultureland', },
                      { label: '스마트문상', value: 'smartculture', },
                      { label: '해피머니', value: 'happymoney', },
                      { label: '포인트', value: 'point', },
                      { label: '수동입력', value: 'manual', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="결제상태"
                  name="paymentStatus"
                >
                  <Select
                    options={[
                      { label: '결제완료', value: 'paid', },
                      { label: '이체대기', value: 'ready', },
                      { label: '결제취소', value: 'canceled', },
                      { label: '결제실패', value: 'failed', },
                      { label: '대기중', value: 'unknown', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="송금계좌번호 (자동발급)"
                  name="paymentInfo"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label="취소/환불 사유"
                  name="cancelReason"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
    </>
  );
};
