import { Col, Row, Space, Tag, Typography } from "@pankod/refine-antd";
import {
  useGetIdentity,
  useOne,
  useRouterContext
} from "@pankod/refine-core";
import { RefineErrorPageProps } from "@pankod/refine-ui-types";
import { IUserStats } from "interfaces";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import 'chartjs-plugin-labels';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants';
const { Title, Text } = Typography;

const reasons = [
  '중복 계정이 있어요.',
  '앱 오류가 많아요.',
  '자주 사용하지 않아요.',
  '알람이 너무 자주 와요.',
  '개인정보 노출이 걱정돼요.',
  '휴대폰 번호를 바꿀 예정이에요.',
  '기타.'
];

export const DashboardPage: React.FC<RefineErrorPageProps> = () => {

  const [barData, setBarData] = useState<any>(null);
  const [pieData, setPieData] = useState<any>(null);
  const [others, setOthers] = useState<string[]>([]);

  const { data: userIdentity } = useGetIdentity();
  const barOptions = {
    responsive: true,
    plugins: {
      labels: { render: 'value' },
    },
  };
  const pieOptions = {
    responsive: true,
    plugins: {
      labels: { render: 'label' },
      position: 'top' as const,
    }
  };

  
  const { data: regiStats } = useOne<IUserStats[]>({
    resource: `users/stats`,
    id: 1,
    queryOptions: {
      // enabled: true,
      onSuccess: (data) => {
        const items = ((data?.data as any)?.data as IUserStats[]);
        setBarData({
          labels: items.map((v: IUserStats) => v.date),
          datasets: [{
            label: '가입자 수',
            data: items.map((v) => v.total),
            borderWidth: 1,
          }]
        });
      },
    }
  });

  const { data: quitStats } = useOne<IUserStats[]>({
    resource: `users/stats`,
    id: `quit`,
    queryOptions: {
      // enabled: true,
      onSuccess: (data) => {
        let counts = [0, 0, 0, 0, 0, 0, 0];
        const items = ((data?.data as any)?.data as string[]);
        const etc = items.map((v) => {
          switch (v) {
            case reasons[0]:
              counts[0]++;
              break;
            case reasons[1]:
              counts[1]++;
              break;
            case reasons[2]:
              counts[2]++;
              break;
            case reasons[3]:
              counts[3]++;
              break;
            case reasons[4]:
              counts[4]++;
              break;
            case reasons[5]:
              counts[5]++;
              break;
            default:
              counts[6]++;
              return v;
          }
          return null;
        });
        setOthers(etc.filter((v) => v) as string[]);
        setPieData({
          labels: reasons,
          datasets: [{
            label: '누적 횟수',
            data: counts,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(128, 128, 128, 0.2)',
            ],
            borderWidth: 1,
          }]
        });
      },
    }
  });

  useEffect(() => {
    if (userIdentity && userIdentity.role === 'USER') {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }, [userIdentity]);

  return (
    userIdentity?.role !== 'USER'
    ? <>
        <Row>
          <Col span={24}><Title level={5}>지난 30일간 가입자 수 변화 추이</Title></Col>
          <Col span={24} style={{marginBottom: 16}}>{ barData ? <Bar options={barOptions} data={barData} /> : <></>}</Col>
          <Col span={24}><Title level={5}>탈퇴사유 누적 분포</Title></Col>
          <Col span={24}>{ pieData ? <Doughnut options={pieOptions} data={pieData} /> : <></>}</Col>
          <Col span={24}><Tag style={{marginRight: 8}}>기타 사유</Tag><Text style={{fontSize: 11}}>{others.join(', ')}</Text></Col>
        </Row>
      </>
    : <Space direction="horizontal" style={{
        width: '100%',
        justifyContent: 'center'
      }}>
        <div style={{ width: 240 }}>
          <img src="https://cdn.fleaauction.world/svgs/robot.svg" alt="Robot" />
        </div>      
        <h1>STOP, RIGHT THERE! YOU LITTLE SNEAK.</h1>
        <h1>YOU ARE NOT ALLOWED TO ACCESS THIS.</h1>
      </Space>);
};
