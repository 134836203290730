import {
  Card,
  Col,
  Form,
  FormProps, Input,
  message,
  Modal,
  ModalProps,
  Radio,
  Row
} from "@pankod/refine-antd";

type EditModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditModal: React.FC<EditModalProps> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} title="송장번호 입력">
      <Form
        { ...formProps }
        onFinish={(values) => {
          if (!values.courier || !values.trackingNumber) {
            message.error('택배업체 및 송장번호를 모두 입력하세요.')
            return false;
          }
          // EB049505313KR
          // UP00P000000KR
          if (values.courier === 'KDEXP') {
            const code = values.trackingNumber.toUpperCase();
            if (/^[0-9]{13}$/.test(code) === false) {
              message.error('경동택배 송장번호 형식이 맞지 않습니다. 확인후 다시 입력하세요.')
              return false;
            }
          }
          if (values.courier === 'EMS') {
            const code = values.trackingNumber.toUpperCase();
            if (/^[A-Z]{2}.*?KR$/.test(code) === false) {
              message.error('EMS 번호 형식이 맞지 않습니다. 확인후 다시 입력하세요.')
              return false;
            }
          }

          formProps.onFinish && formProps.onFinish({
            ...values, shippingStatus: 'NUMBER_ISSUED',
          });
        }}
        layout="vertical"
      >
        <Card
          type="inner"
          title="송장번호"
          extra="택배업체와 송장번호를 정확히 입력하세요."
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="택배업체"
                name="courier"
                required={true}
              >
                <Radio.Group
                  options={[
                    { label: '경동택배', value: 'KDEXP', },
                    { label: '우체국EMS', value: 'EMS', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="송장번호"
                name="trackingNumber"
                required={true}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
