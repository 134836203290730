import {
  AutoComplete,
  Card,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Typography,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import dayjs from "dayjs";
import { genreName } from "helpers/artwork";
import { sellerTypeName } from "helpers/seller";
import { IArtist, IAuction, ICoupon, IOptions, IPack } from "interfaces";
import { useEffect, useState } from "react";
const { Text } = Typography;

const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>{title}</Text>
  );
};

const renderItem = (name: string, resource: string, id: number) => {
  return {
    key: id,
    value: name,
    label: (<Text>{ name }</Text>),
  };
};

export const CouponCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<ICoupon>();

  //?-------------------------------------------------------------------------//
  //? useState hook
  //?-------------------------------------------------------------------------//

  const [packTitle, setPackTitle] = useState<string>('');
  const [packId, setPackId] = useState<number>(0);
  const [auctionTitle, setAuctionTitle] = useState<string>('');
  const [auctionId, setAuctionId] = useState<number>(0);
  const [artistName, setArtistName] = useState<string>('');
  const [artistId, setArtistId] = useState<number>(0);
  const [couponTarget, setCouponTarget] = useState<string>('USER');
  const [options, setOptions] = useState<IOptions[]>([]);

  //?-------------------------------------------------------------------------//
  //? useList hook
  //?-------------------------------------------------------------------------//

  const { refetch: refetchPacks } = useList<IPack>({
    resource: "packs",
    config: {
      filters: [
        { field: "search", operator: "contains", value: packTitle },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const packOptionGroup = ((data?.data as any)?.data as IPack[]).map((item) =>
          renderItem(`${item.title} - ${item.subtitle} (${item.weeks})`, "packs", item.id),
        );
        if (packOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("제목"),
              options: packOptionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchAuctions } = useList<IAuction>({
    resource: "auctions",
    config: {
      filters: [
        { field: "search", operator: "contains", value: auctionTitle },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const auctionOptionGroup = ((data?.data as any)?.data as IAuction[]).map((item) =>
          renderItem(`${item.title} - ${item.subtitle} (${item.artistName})`, "auctions", item.id),
        );
        if (auctionOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("제목"),
              options: auctionOptionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchArtists } = useList<IArtist>({
    resource: "artists",
    config: {
      filters: [
        { field: "search", operator: "contains", value: artistName },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const artistOptionGroup = ((data?.data as any)?.data as IArtist[]).map((item) =>
          renderItem(`${item.name} - ${sellerTypeName(item.sellerType)} (${genreName(item.genre)})`, "artists", item.id),
        );
        if (artistOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("작가명"),
              options: artistOptionGroup,
            },
          ]);
        }
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const onEndTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0)
    form.setFieldsValue({ endTime: day });
    return day;
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    console.log(`packTitle has been changed; ${packTitle}`)
    setOptions([]);
    refetchPacks();
  }, [packTitle]);

  useEffect(() => {
    console.log(`auctionTitle has been changed; ${auctionTitle}`)
    setOptions([]);
    refetchAuctions();
  }, [auctionTitle]);

  useEffect(() => {
    console.log(`artistName has been changed; ${artistName}`)
    setOptions([]);
    refetchArtists();
  }, [artistName]);

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        {...formProps}
        onFinish={(values: any) => {
          let targetId = null;
          if (couponTarget === 'PACK') {
            targetId = packId;
          }
          if (couponTarget === 'AUCTION') {
            targetId = auctionId;
          }
          if (couponTarget === 'ARTIST') {
            targetId = artistId;
          }
          formProps.onFinish && formProps.onFinish({
            ...values, targetId,
          })
        }}
        layout="vertical"
      >
        <Card
          type="inner"
          title="쿠폰 생성"
          extra="쿠폰코드를 지정하지 않으면 자동으로 생성됩니다."
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="쿠폰명"
                name="name"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="할인금액"
                name="discount"
                rules={[{required: true}]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="만기일"
                name="expiredAt"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onEndTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="쿠폰코드"
                name="code"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="쿠폰타입"
                name="target"
                initialValue={'USER'}
              >
                <Radio.Group
                  options={[
                    { label: "사용자쿠폰", value: "USER", },
                    { label: "기획전쿠폰", value: "PACK", },
                    { label: "옥션쿠폰", value: "AUCTION", },
                    { label: "작가쿠폰", value: "ARTIST", },
                  ]}
                  onChange={() => {
                    setCouponTarget(form.getFieldValue('target'));
                    setOptions([]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {
            couponTarget === 'PACK' &&
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="기획전 검색" name="packTitle">
                  <AutoComplete
                    allowClear
                    style={{ width: "100%" }}
                    options={options}
                    filterOption={true}
                    onSearch={(v: string) => setPackTitle(v)}
                    onSelect={(v: string, o: any) => {
                      setPackId(o.key)
                      form.setFieldsValue({ packId: o.key })
                    }}
                    //onSearch={debounce((value: string) => setArtistName(value), 500)}
                  >
                    <Input.Search allowClear placeholder="기획전 제목" />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="기획전 ID" name="packId"><Input /></Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          }
          {
            couponTarget === 'AUCTION' &&
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="옥션 검색" name="auctionTitle">
                  <AutoComplete
                    allowClear
                    style={{ width: "100%" }}
                    options={options}
                    filterOption={true}
                    onSearch={(v: string) => setAuctionTitle(v)}
                    onSelect={(v: string, o: any) => {
                      setAuctionId(o.key)
                      form.setFieldsValue({ auctionId: o.key })
                    }}
                    //onSearch={debounce((value: string) => setArtistName(value), 500)}
                  >
                    <Input.Search allowClear placeholder="옥션 제목" />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="옥션 ID" name="auctionId"><Input /></Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          }
          {
            couponTarget === 'ARTIST' &&
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="작가 검색" name="artistName">
                  <AutoComplete
                    allowClear
                    style={{ width: "100%" }}
                    options={options}
                    filterOption={true}
                    onSearch={(v: string) => setArtistName(v)}
                    onSelect={(v: string, o: any) => {
                      setArtistId(o.key)
                      form.setFieldsValue({ artistId: o.key })
                    }}
                    //onSearch={debounce((value: string) => setArtistName(value), 500)}
                  >
                    <Input.Search allowClear placeholder="작가명" />
                  </AutoComplete>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="작가 ID" name="artistId"><Input /></Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          }
        </Card>
      </Form>
    </Create>
  );
};
