import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Show,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from 'axios';
import { reporTargetName, reportStatusColor, reportStatusName } from "helpers/report";
import { avatarUrl } from "helpers/user";
import { IReport } from "interfaces";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { ACCESS_TOKEN } from "../../constants";
const { Title, Text } = Typography;
const { Link } = routerProvider;

export const ReportShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  const [commentBody, setCommentBody] = useState<string>('');
  const [articleId, setArticleId] = useState<number>(0);

  // useShow
  const { queryResult } = useShow<IReport>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    if (
      record?.target === 'ARTICLE_COMMENT' &&
      record?.targetId
    ) {
      const getComment = async () => await axios({
        method: 'get',
        url: `${apiUrl}/articles/comments/${record?.targetId}`,
        headers: headers,
      })
      getComment().then((v) => {
        setCommentBody(v.data?.body);
        setArticleId(v.data?.article?.id);
      });
    }
  }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title={`신고 조회`}
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>신고대상</Title>
            {
              record &&
              <Text>{reporTargetName(record?.target)}</Text>
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>페이지이동</Title>
            {
              record?.target === 'USER'
              ? <Button size="small" onClick={(): void => goto("users", record?.targetId)}>
                관련 사용자 보기
              </Button>
              : <Button size="small" onClick={(): void => goto("articles", articleId)}>
                관련 아티클 보기
              </Button>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>신고자</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  shape="square"
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.username}
                />
              }
              <Text style={{marginLeft: 8}}>
                {record?.user?.username}
              </Text>
            </Link>
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>신고내용</Title>
            <Text>{record?.reason}</Text>
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>상세내용</Title>            
            {
              (record?.target === 'USER' && record?.user?.profile) &&
              <Text>{ record?.user?.profile?.bio }</Text>
            }
            {
              (record?.target === 'ARTICLE_COMMENT' && commentBody) &&
              <Text>{ commentBody }</Text>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>처리상태</Title>
            {
              record &&
              <Tag color={reportStatusColor(record?.reportStatus)}>
                {reportStatusName(record?.reportStatus)}
              </Tag>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>신고시각</Title>
            {
              record?.createdAt
              ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" />
              : <></>
            }
          </Col>
        </Row>
      </Card>
    </Show>
  )
};

