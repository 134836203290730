import {
  AutoComplete,
  Avatar,
  Card,
  Checkbox,
  Col,
  Image,
  Input,
  Modal,
  ModalProps,
  Row,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { useList, useOne } from "@pankod/refine-core";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { firstImageUrl } from "helpers/artwork";
import { sellerTypeName } from "helpers/seller";
import { avatarUrl } from "helpers/user";
import { IArtist, IArtwork, IOptions } from "interfaces";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

const { Text } = Typography

type ModalPropsType = {
  modalProps: ModalProps
  callback: (v: any) => void
  close: () => void
};

const renderTitle = (title: string) =>
  (<Text strong style={{fontSize: 16}}>{title}</Text>)

const renderItem = (name: string, resource: string, id: number) => ({
  key: id,
  value: name,
  label: <Text>{name}</Text>,
})

export const SelectArtistModal: React.FC<ModalPropsType> = ({
  modalProps,
  callback,
  close
}) => {

  // useState hook
  const [selectOptions, setSelectOptions] = useState<IOptions[]>([])
  const [sellerName, setSellerName] = useState<string>('')
  const [artist, setArtist] = useState<IArtist | null>(null)
  const [artistId, setArtistId] = useState<number | undefined>(undefined)
  const [artworkIds, setArtworkIds] = useState<number[]>([])
  const [artworks, setArtworks] = useState<IArtwork[]>([])

  // useList hook
  const { refetch } = useList<IArtist>({
    resource: 'artists',
    config: {
      filters: [
        { field: 'search', operator: 'contains', value: sellerName },
        { field: 'sellerType', operator: 'in', value: 'ARTIST1,ARTIST2' },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const userOptionGroup = ((data?.data as any)?.data as any).map(
          (v: any) => renderItem(`${v.name} (${v.email})` ?? '무명', 'artists', v.id)
        )

        if (userOptionGroup.length > 0) {
          setSelectOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle('작가명'),
              options: userOptionGroup,
            },
          ])
        }
      },
    },
  })

  // useOne hook
  const { refetch: refetchArtist } = useOne<IArtist>({
    resource: 'artists',
    id: artistId ?? 0,
    queryOptions: {
      enabled: artistId !== undefined,
      onSuccess: (data) => {
        const artist = data?.data as IArtist;
        const artworks = data?.data?.artworks as IArtwork[];
        setArtist(artist);
        setArtworks(artworks);
      },
      onError: (e) => {
        console.log(e);
      }
    },
  })

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const onCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    // console.log('checked = ', checkedValues)
    setArtworkIds(checkedValues as number[])
  }

  const onModalClose= (values: any) => {
    const fnSelectedArtworks = artworks.filter((v: IArtwork) => artworkIds.includes(v.id));
    const newArtist = { ...artist, artworks: fnSelectedArtworks };

    setArtist(null)
    setArtworks([])
    callback(newArtist);
    close()
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    // console.log(`sellerName has been changed; ${sellerName}`)
    setSelectOptions([])
    setArtist(null)
    setArtistId(undefined)
    // setArtworks([])
    refetch()
  }, [sellerName])

  useEffect(() => {
    if (artistId) {
      refetchArtist(); // o.key = artistId
    }
  }, [artistId])

  return (
    <Modal {...modalProps} title="판매자선택" destroyOnClose={true} onOk={onModalClose} width={800}>
      <Card style={{marginBottom: 16}}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <AutoComplete
              style={{ width: '100%' }}
              options={selectOptions}
              filterOption={true}
              onSearch={debounce((v: string) => setSellerName(v), 100)}
              onSelect={(_v: string, o: any) => {
                setArtistId(o.key)
              }}
            >
              <Input.Search allowClear placeholder="작가명을 검색하세요." />
            </AutoComplete>
          </Col>
          <Col span={12}>
            {
              artist && artist.user &&
              <div>
                <Avatar
                  size={32}
                  src={avatarUrl(artist.user?.avatar)}
                  style={{marginRight: 8}}
                />
                <Tag>{sellerTypeName(artist.sellerType)}</Tag>
                <Text>{artist.name}</Text>
              </div>
            }
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="작품 리스트"
        extra="기획전에 포함시킬 작품을 선택하세요."
      >
        <Checkbox.Group onChange={onCheckboxChange}>
        {
          artworks && artworks.length > 0
          ? artworks!.map((artwork) => {
            return (<Row
              key={`artwork-row-${artwork.id}`}
              gutter={[16, 16]}>
              <Col span={24}>
                <Image
                  src={firstImageUrl(artwork.images)}
                  width={64}
                  height={64}
                />
                <Checkbox
                  value={artwork.id}
                  style={{marginLeft: 10}}
                >
                  <Text>{artwork.title}</Text>
                </Checkbox>  
              </Col>
            </Row>)
          })
          : <Row gutter={[16, 16]}>
            <Col span={24}><Tag>정보없음</Tag></Col>
          </Row>
        }
        </Checkbox.Group>
      </Card>
    </Modal>
  );
};
