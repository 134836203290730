import { DeleteOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  Form,
  FormProps,
  Input, message,
  Modal,
  ModalProps,
  Row,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { firstImageUrl } from "helpers/artwork";
import { IArticle, IOptionGroup, IOptions } from "interfaces";
import { useEffect, useState } from "react";
const { Text } = Typography

type SelectArticleModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const renderTitle = (title: string) =>
  (<Text strong style={{fontSize: 16}}>{title}</Text>);

const renderItem = (title: string, subtitle: string, imageUrl: string, id: number) => ({
  key: id,
  value: `${title} (${id}) ${subtitle}`,
  label: <><Avatar shape="square" size={32} src={imageUrl} />
    <Text style={{ marginLeft: 8, fontWeight: 'bold' }}>{title}</Text> 
    <Text style={{ marginLeft: 8 }}>{subtitle}</Text>
    </>
});

export const SelectArticleModal: React.FC<SelectArticleModalProps> = ({
  modalProps,
  formProps,
}) => {
  // useState hook
  const [value, setValue] = useState<string>('')
  const [options, setOptions] = useState<IOptions[]>([])
  const [optionGroups, setOptionGroups] = useState<IOptionGroup[]>([])

  // useList hook
  const { refetch } = useList<IArticle>({
    resource: 'articles/',
    config: {
      filters: [
        { field: 'search', operator: 'contains', value: value },
        // { field: 'isPublished', operator: 'eq', value: true },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IArticle[]).map(
          (item) => renderItem(
            item.title,
            item.subtitle,
            firstImageUrl(item.images),
            item.id,
          )
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle('아티클 제목'),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  })

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    setOptions([])
    refetch()
  }, [value])

  return (
    <Modal {...modalProps} title="아티클선택" width={800}>
      <Form
        {...formProps}
        onFinish={(values) => {
          console.log(values, optionGroups)
          const ids = optionGroups.map((v: any) => v.key)
          formProps.onFinish && formProps.onFinish({
            ids
          });
        }}
        layout="vertical"
      >
        <Card style={{marginBottom: 24}}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <AutoComplete
                style={{ width: '100%' }}
                options={options}
                filterOption={true}
                value={value}
                // onSearch={debounce((v: string) => setArticleTitle(v), 500)}
                onSearch={(v: string) => setValue(v)}
                onSelect={(_: string, o: any) => {
                  if (optionGroups.find((v: any) => v.key === o.key)) {
                    message.warn('이미 선택한 항목입니다.')
                  } else {
                    setOptionGroups([...optionGroups, o])
                    setValue('')
                  }
                }}
              >
                <Input.Search allowClear placeholder="아티클 제목을 검색하세요." />
              </AutoComplete>
            </Col>
          </Row>
        </Card>
        <Card
          type="inner"
          title="아티클 리스트"
          extra="경매상품과 연관된 아티클을 모두 선택하세요."
        >
          {
            optionGroups && optionGroups.length > 0
            ? optionGroups!.map((v: any, i) => {
              return (<Row
                key={`article-row-${i}`}
                gutter={[16, 16]}
                style={{ marginBottom: 8 }}
                >
                <Col flex="auto">
                  { v.label }
                </Col>
                <Col flex="40px">
                  <Button
                    icon={<DeleteOutlined />}
                    style={{ color: 'red' }}
                    onClick={() => {
                      const _options = optionGroups.filter((option: any) => option.key !== v.key)
                      setOptionGroups(_options)
                    }} />
                </Col>
              </Row>)
            })
            : <Row gutter={[16, 16]}><Col span={24}><Tag>정보없음</Tag></Col></Row>
          }
        </Card>
      </Form>
    </Modal>
  );
};
