import {
  AutoComplete,
  Card,
  Col,
  Create,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import { sellerTypeName } from "helpers/seller";
import { IAdminArtist, IArtwork, IOptions } from "interfaces";
import { useEffect, useState } from "react";
const { Text } = Typography;

const renderTitle = (title: string) =>
  (<Text strong style={{fontSize: 16}}>{title}</Text>);

const renderItem = (name: string, sellerType: string, id: number) => {
  return {
    key: id,
    value: name,
    label: <>
      <Text>{ name }</Text>
      <Text style={{ marginLeft: 8, fontWeight: 'bold' }}>{sellerType}</Text>
      </>,
  };
};

export const ArtworkCreate: React.FC<IResourceComponentsProps> = () => {
  // useState hook
  const [artistName, setArtistName] = useState<string>('');
  const [resellerName, setResellerName] = useState<string>('');
  const [artistOptions, setArtistOptions] = useState<IOptions[]>([]);
  const [resellerOptions, setResellerOptions] = useState<IOptions[]>([]);
  const [artistId, setArtistId] = useState<number>(0);
  const [ownerId, setOwnerId] = useState<number>(0);

  // useForm hook
  const { form, formProps, saveButtonProps } = useForm<IArtwork>();

  // useList hook
  const { refetch: refetchArtistNames } = useList<IAdminArtist>({
    resource: "artists",
    config: {
      filters: [
        { field: "search", operator: "contains", value: artistName },
        { field: "sellerType", operator: "eq", value: 'ARTIST' },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const artistOptionGroup = ((data?.data as any)?.data as IAdminArtist[]).map(
          (item) => renderItem(
            `[${item.id}] ${item.name}`,
            sellerTypeName(item.sellerType),
            item.id,
          ),
        );
        if (artistOptionGroup.length > 0) {
          setArtistOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("작가명"),
              options: artistOptionGroup,
            },
          ]);
        }
      },
    },
  });

  // useList hook
  const { refetch: refetchResellerNames } = useList<IAdminArtist>({
    resource: "artists",
    config: {
      filters: [
        { field: "search", operator: "contains", value: resellerName },
        { field: "sellerType", operator: "eq", value: 'RESELLER' },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const resellerOptionGroup = ((data?.data as any)?.data as IAdminArtist[]).map(
          (item) => renderItem(
            `[${item.id}] ${item.name}`,
            sellerTypeName(item.sellerType),
            item.id,
          ),
        );
        if (resellerOptionGroup.length > 0) {
          setResellerOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("리셀러명"),
              options: resellerOptionGroup,
            },
          ]);
        }
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    setArtistOptions([]);
    refetchArtistNames();
  }, [artistName]);

  useEffect(() => {
    setResellerOptions([]);
    refetchResellerNames();
  }, [resellerName]);

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        {...formProps}
        onFinish={(values: any) => {
          console.log(values);
          for (var key in values) {
            if (key === 'artistName' && values[key]) {
              values[key] = values[key].replace(/\[.*?\] /, '')
            }
            if (values[key] === '') values[key] = null;
          }
          formProps.onFinish && formProps.onFinish({
            ...values, isAdmin: true
          });
        }}    
        layout="vertical"
      >
        <Card
          type="inner"
          title="기본정보" style={{marginBottom: 24}}
          extra="작품에는 작가정보와 리셀러정보(선택사항)를 연결해야 합니다. "
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작품명"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="소재"
                name="medium"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="가로(mm)"
                name="width"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="세로(mm)"
                name="height"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item label="작가 검색" name="artistName">
                <AutoComplete
                  style={{ width: "100%" }}
                  options={artistOptions}
                  filterOption={true}
                  onSearch={(v: string) => setArtistName(v)}
                  onSelect={(_: string, o: any) => {
                    setArtistId(o.key)
                    form.setFieldsValue({ artistId: o.key })
                  }}
                  //onSearch={debounce((value: string) => setArtistName(value), 500)}
                >
                  <Input.Search
                    allowClear
                    placeholder="이름으로 검색하세요."
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="작가ID" name="artistId">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="리셀러 검색" name="ownerName">
                <AutoComplete
                  style={{ width: "100%" }}
                  options={resellerOptions}
                  filterOption={true}
                  onSearch={(v: string) => setResellerName(v)}
                  onSelect={(_: string, o: any) => {
                    setOwnerId(o.key)
                    form.setFieldsValue({ ownerId: o.key })
                  }}
                >
                  <Input.Search
                    allowClear
                    placeholder="이름으로 검색하세요."
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="리셀러ID" name="ownerId">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
