import {
  Avatar,
  Button,
  Card,
  Col, Row,
  Show,
  Tag,
  Typography,
  useModal,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AgeFromDateString } from 'age-calculator';
import dayjs from "dayjs";
import { firstImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IOffer } from "interfaces";

import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const OfferShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();

  // useShow
  const { queryResult } = useShow<IOffer>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user?.dob).age : null;

  // useModal
  const { modalProps: showModalProps, show: showModal } = useModal();

  // useModalForm
  const { formProps, modalProps: editModalProps, show } = useModalForm({
    action: 'edit',
    resource: `offers/${record?.id}`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title={`오퍼 정보`}
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작품명</Title>
            <Row>
              <Col flex='40px'>
                <Avatar
                  size={32}
                  shape="square"
                  src={record?.image}
                  alt={record?.title}
                />
              </Col>
              <Col flex='auto'>
                <Text style={{marginLeft: 8}}>{ record?.title }</Text>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>기획전명</Title>
            <Text>{ record?.subtitle }</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>특구가</Title>
            <Text>상품: { record?.amount.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>수락여부</Title>
            <Tag color={record?.isAccepted === true ? 'green' : 'red'}>
              { record?.isAccepted ? 'Accepted' : 'Not Accepted' }
            </Tag>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>만료</Title>
            { record?.expiredAt
              ? <ReactTimeAgo date={Date.parse(record?.expiredAt!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>외부링크</Title>
              <a href={`https://special-offer.fleaauction.world/offer/` + record?.id} title='외부 링크'>
                {`https://special-offer.fleaauction.world/offer/` + record?.id}
              </a>            
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="관련 회원" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.realname}
                />
              }
              <Text style={{marginLeft: 8}}>{record?.user?.username ?? 'n/a'}</Text>
            </Link>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화</Title>
            {
              record && record.user &&
              <Text>{phoneNumber(record?.user?.phone)}</Text>
            }
            
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            {record?.user?.gender ? (record?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : ''}
            {age ? <Text>{`만${age}세`}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {
              record && record.user && record.user.artist &&
              <Tag color={sellerTypeColor(record?.user?.artist?.sellerType)}>
                {sellerTypeName(record?.user?.artist?.sellerType)}
              </Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="옥션정보"
        extra={
          <Button
            size="small"
            onClick={(): void => goto("auctions", record?.auction?.id!)}
          >옥션보기</Button>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Row gutter={[16, 16]}>
              <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Link to={`/artists/show/${record?.auction?.artwork?.artist?.id}`}>
                  {
                    record && record.auction && record.auction.artwork && record.auction.artwork.artist && record.auction.artwork.artist.user &&
                    <Avatar
                      size={32}
                      src={avatarUrl(record?.auction?.artwork?.artist?.user?.avatar)}
                      alt={record?.auction?.artwork?.artist?.user?.username}
                      style={{ marginRight: 8 }}
                    />
                  }
                </Link>
                {
                  record && record.auction && record.auction.artwork && record.auction.artwork.artist &&
                  <Tag color={sellerTypeColor(record?.auction?.artwork?.artist?.sellerType)}>
                    {sellerTypeName(record?.auction?.artwork?.artist?.sellerType)}
                  </Tag>
                }
                <div><Text ellipsis>{ record?.auction?.artwork?.artist?.name ?? '무명' }</Text></div>
                <div><Text ellipsis>{ record?.auction?.artwork?.artist?.user?.username }</Text></div>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <Row gutter={[16, 16]}>
              <Col span={3}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
              <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명/추정가</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>시작</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>종료</Title></Col>
              <Col span={2}><Title level={5} style={{fontSize: 11}}>입찰수</Title></Col>
              <Col span={3}><Title level={5} style={{fontSize: 11}}>입찰/낙찰가</Title></Col>
              <Col span={2}><Title level={5} style={{fontSize: 11}}>상태</Title></Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={3}>
                <Link to={`/auctions/show/${record?.auction?.id}`}>
                  {
                    record && record.auction &&
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(record?.auction?.images)}
                      alt={record?.auction?.title}
                    />
                  }
                </Link>
              </Col>
              <Col span={6}>
                <div><Text ellipsis>{record?.auction?.title}</Text></div>
                <div><Tag style={{fontSize: 9}}>{record?.auction?.estimate!.toLocaleString()}원</Tag></div>
              </Col>
              <Col span={4}>
                <div>
                {
                  record?.auction?.startTime ?
                  <ReactTimeAgo date={Date.parse(record?.auction?.startTime!)} locale="ko" /> :
                  <Tag>자료없음</Tag>
                }
                </div>
                <div><Tag style={{fontSize: 9}}>{dayjs(record?.auction?.startTime!).format('YY-MM-DD HH:mm')}</Tag></div>
              </Col>
              <Col span={4}>
                <div>
                {
                  record?.auction?.endTime ?
                  <ReactTimeAgo date={Date.parse(record?.auction?.endTime!)} locale="ko" /> :
                  <Tag>자료없음</Tag>
                }
                </div>
                <div><Tag style={{fontSize: 9}}>{dayjs(record?.auction?.endTime!).format('YY-MM-DD HH:mm')}</Tag></div>
              </Col>
              <Col span={2}>
                <Text>{record?.auction?.bidCount!.toLocaleString()}</Text>
              </Col>
              <Col span={3}>
                <div><Text>{record?.auction?.lastBidAmount!.toLocaleString()}</Text></div>
                <div><Tag style={{fontSize: 9}}>{record?.auction?.status === 'ENDED' ? '낙찰가' : '입찰가'}</Tag></div>
              </Col>
              <Col span={2}>
                {
                  record && record.auction &&
                  <Tag color={auctionStatusColor(record?.auction?.status)}>{auctionStatusName(record?.auction?.status )}</Tag>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Show>
  )
};
