import { AntdLayout } from "@pankod/refine-antd";
import { useRouterContext, useTranslate, useUpdatePassword } from "@pankod/refine-core";
import {
  Button, Card, Col, Form, FormProps, Input, Row, Typography
} from "antd";
import React from "react";
import "./styles.css";

type ResetPasswordProps = {
  formProps: FormProps;
};

const { Text, Title } = Typography;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const UpdatePasswordPage: React.FC<ResetPasswordProps> = ({
    formProps
}) => {
    const [form] = Form.useForm<ResetPasswordProps>();
    const translate = useTranslate();
    const { Link } = useRouterContext();
    const email = localStorage.getItem('USER_EMAIL')
    const { mutate: updatePassword, isLoading } = useUpdatePassword();

    const CardTitle = (
      <Title level={1} className="title" style={{ color: '#666'}}>
        관리자 비밀번호 갱신
      </Title>
    );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="https://cdn.fleaauction.world/svgs/logo.svg" alt="Company Logo" />
            </div>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
            >
              <Form
                layout="vertical"
                form={form}
                onFinish={
                  (values) => updatePassword(values)
                }
                requiredMark={false}
                {...formProps}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  initialValue={email}
                  rules={[
                    { required: true },
                    {
                      type: "email",
                      message: translate(
                        "pages.forgotPassword.errors.validEmail",
                        "Invalid email address",
                      ),
                    },
                  ]}
                >
                  <Input
                    type="email"
                    size="large"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="code"
                  label="OTP"
                  rules={[{ required: true, len: 5 }]}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    type="text"
                    placeholder="5자리 OTP"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    type="password"
                    placeholder="안전한 비밀번호"
                    size="large"
                  />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft: "auto",
                    }}
                  >
                    {translate(
                      "pages.register.buttons.haveAccount",
                      "Have an account? ",
                    )}{" "}
                    <Link style={{ fontWeight: "bold" }} to="/login">
                      {translate("pages.login.signin", "Sign in")}
                    </Link>
                  </Text>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    block
                  >비밀번호 갱신</Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
