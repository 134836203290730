import {
  Button,
  Card,
  Col,
  Image,
  MarkdownField,
  message,
  Popconfirm,
  Row,
  Show,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustomMutation, useShow } from "@pankod/refine-core";
import { newsCategoryName } from "helpers/news";
import { INews } from "interfaces";
import ReactTimeAgo from 'react-time-ago';

const { Title, Text } = Typography;

export const NewsShow: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  // useShow
  const { queryResult } = useShow<INews>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // event handlers
  const onConfirm = (id: number | undefined) => {
    if (id) {
      mutate({
        url: `${apiUrl}/news/${id}/notify`,
        method: 'patch',
        values: {},
      }, {
        onSuccess: (data, variables, context) => {
          message.success('푸시알림을 보냈습니다.');
          refetch();
        }
      });
    }
  };
  const onCancel = (e?: React.MouseEvent<HTMLElement>) => {};

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card
        type="inner"
        title={`공지사항`}
        extra={
          <>
          {
            record?.notifiedAt &&
            <Text style={{marginRight: 8, fontSize: 12}}>
              <ReactTimeAgo date={Date.parse(record?.notifiedAt)} locale="ko" />
              <Text style={{marginLeft: 4}}>발송</Text>
            </Text>
          }
          <Popconfirm
            title="푸시알림을 보낼까요?"
            onConfirm={() => onConfirm(record?.id)}
            onCancel={onCancel}
            okText="🥕 당근"
            cancelText="취소"
          >
          <Button
            size="small"
          >푸시알림 보내기</Button>
          </Popconfirm>
          </>
        }
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>이미지</Title>
            {
              <Image
                src={ record?.image ?? 'https://cdn.fleaauction.world/images/news.png'}
                width={64}
                height={64}
              />
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>제목</Title>
            {record?.isFixed
            ? <><Tag>📌 고정글</Tag> <Text>{record?.title}</Text></>
            : <><Tag>📰 일반글</Tag> <Text>{record?.title}</Text></>
            }
            
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>분류</Title>
            {
              record &&
              <Text>{newsCategoryName(record?.title)}</Text>
            }
          </Col>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>공지내용</Title>
            {
              record?.body
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.body} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
    </Show>
  )
};
