import {
  AutoComplete,
  Card,
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  Typography,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import { IArtist, IOptions, IUser } from "interfaces";
import { useEffect, useState } from "react";
const { Text } = Typography;

const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>
      {title}
    </Text>
  );
};

const renderItem = (name: string, resource: string, id: number) => {
  return {
    key: id,
    value: name,
    label: (<Text>{ name }</Text>),
  };
};

export const ArtistCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IArtist>();
  const [username, setUsername] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);
  const [options, setOptions] = useState<IOptions[]>([]);
  const { refetch: refetchUsernames } = useList<IUser>({
    resource: "users",
    config: {
      filters: [
        { field: "username", operator: "contains", value: username },
      ],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const userOptionGroup = ((data?.data as any)?.data as IUser[]).map((item) =>
          renderItem(
            item.username ?? '',
            "users",
            item.id,
          ),
        );
        if (userOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            //...prevOptions,
            {
              label: renderTitle("닉네임"),
              options: userOptionGroup,
            },
          ]);
        }
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    console.log(`username has been changed; ${username}`)
    setOptions([]);
    refetchUsernames();
  }, [username]);

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="셀러정보"
          extra="작가는 반드시 사용자정보를 연결해야 합니다."
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가명"
                name="name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="장르"
                name="genre"
                initialValue='ETC'
              >
                <Select
                  options={[
                    { label: '🎨 원화작가', value: 'PAINTER', },
                    { label: '✍🏼 일러스트레이터', value: 'DRAWER', },
                    { label: '🪆 조각가', value: 'SCULPTOR', },
                    { label: '📸 사진작가', value: 'PHOTOGRAPHER', },
                    { label: '📺 비쥬얼아티스트', value: 'VISUAL_ARTIST', },
                    { label: '🖼️ 갤러리', value: 'GALLERY', },
                    { label: '기타', value: 'ETC', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="국적"
                name="nationality"
                initialValue='kr'
              >
                <Select
                  options={[
                    { label: '🇰🇷 한국', value: 'kr', },
                    { label: '🇬🇷 그리스', value: 'gr', },
                    { label: '🇿🇦 남아공', value: 'za', },
                    { label: '🇳🇴 노르웨이', value: 'no', },
                    { label: '🇳🇱 네덜란드', value: 'nl', },
                    { label: '🇳🇿 뉴질랜드', value: 'nz', },
                    { label: '🇩🇪 독일', value: 'de', },
                    { label: '🇹🇼 대만', value: 'tw', },
                    { label: '🇩🇰 덴마크', value: 'dk', },
                    { label: '🇲🇾 말레이시아', value: 'my', },
                    { label: '🇲🇽 멕시코', value: 'mx', },
                    { label: '🇺🇸 미국', value: 'us', },
                    { label: '🇧🇪 벨기에', value: 'be', },
                    { label: '🇧🇬 불가리아', value: 'bg', },
                    { label: '🇧🇷 브라질', value: 'br', },
                    { label: '🇨🇭 스위스', value: 'ch', },
                    { label: '🇸🇪 스웨덴', value: 'se', },
                    { label: '🇪🇸 스페인', value: 'es', },
                    { label: '🇸🇬 싱가폴', value: 'sg', },
                    { label: '🇮🇪 아일랜드', value: 'ie', },
                    { label: '🇦🇷 아르헨티나', value: 'ar', },
                    { label: '🇬🇧 영국', value: 'gb', },
                    { label: '🇺🇦 우크라이나', value: 'ua', },
                    { label: '🇮🇷 이란', value: 'ir', },
                    { label: '🇮🇶 이라크', value: 'iq', },
                    { label: '🇪🇬 이집트', value: 'eg', },
                    { label: '🇮🇹 이탈리아', value: 'it', },
                    { label: '🇮🇳 인도', value: 'in', },
                    { label: '🇮🇩 인도네시아', value: 'id', },
                    { label: '🇯🇵 일본', value: 'jp', },
                    { label: '🇯🇲 자메이카', value: 'jm', },
                    { label: '🇨🇳 중국', value: 'cn', },
                    { label: '🇨🇦 캐나다', value: 'ca', },
                    { label: '🇹🇭 태국', value: 'th', },
                    { label: '🇹🇷 튀르키예(터키)', value: 'tr', },
                    { label: '🇵🇱 폴란드', value: 'pl', },
                    { label: '🇫🇷 프랑스', value: 'fr', },
                    { label: '🇫🇮 핀란드', value: 'fi', },
                    { label: '🇵🇭 필리핀', value: 'ph', },
                    { label: '🇭🇺 헝가리', value: 'hu', },
                    { label: '🇦🇺 호주', value: 'au', },
                    { label: '기타', value: 'other', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="셀러타입"
                name="sellerType"
                initialValue='ARTIST3'
              >
                <Select
                  options={[
                    { label: '🟪 플리크루', value: 'ARTIST1', },
                    { label: '🟧 작가', value: 'ARTIST2', },
                    { label: '🟥 예비작가', value: 'ARTIST3', },
                    { label: '🟩 리셀러', value: 'RESELLER', },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="사용자 검색"
                name="username"
              >
                <AutoComplete
                  allowClear
                  style={{ width: "100%" }}
                  options={options}
                  filterOption={true}
                  onSearch={(v: string) => setUsername(v)}
                  onSelect={(v: string, o: any) => {
                    setUserId(o.key)
                    form.setFieldsValue({ userId: o.key })
                  }}
                  //onSearch={debounce((value: string) => setArtistName(value), 500)}
                >
                  <Input.Search
                    allowClear
                    placeholder="닉네임 검색"
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="사용자ID" name="userId">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
