import {
  Avatar, DatePicker, DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  message,
  NumberField,
  Select,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Typography,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from 'axios';
import { invoiceStatusColor, invoiceStatusName, invoiceTypeColor, invoiceTypeName } from "helpers/invoice";
import { IInvoice } from "interfaces";
import { useState } from 'react';
import ReactTimeAgo from "react-time-ago";
import { ACCESS_TOKEN } from "../../constants";

const { Option } = Select;
const { Link } = routerProvider;
const { RangePicker } = DatePicker;

export const InvoiceList: React.FC<IResourceComponentsProps> = () => {
  const { Text } = Typography;
  const [from, setFrom] = useState<string>('');
  const [target, setTarget] = useState<string>('delivery');
  const [to, setTo] = useState<string>('');
  const [csvData, setCsvData] = useState<any>([]);

  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();


  const { tableProps, sorter } = useTable<IInvoice>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  const loadDeliveryData = async (from: string, to: string) => {
    if (!from || !to) {
      message.warn(`생성할 날짜 범위를 지정해주세요.`)
    }
    try {
      axios({
        method: 'get',
        url: `${apiUrl}/invoices/${target}?from=${from}&to=${to}`,
        headers: headers,
      }).then((data) => {
        if (data.data.length > 0) {
          message.info(`CSV 데이터 ${data.data.length} 건이 검색되었습니다. 다운로드 버튼을 누르세요.`)
        }
        setCsvData(data.data);
      })
    } catch (e) {
      message.error('데이터 조회 오류')
    }
  }

  const handleRangeChange = async (value: any) => {
    const [fromVal, toVal] = value;
    const fromDate = fromVal.toISOString().split('T')[0];
    const toDate = toVal.toISOString().split('T')[0];
    setFrom(fromDate);
    setTo(toDate);
    await loadDeliveryData(fromDate, toDate);
  }

  const handleOptionChange = (value: string) => {
    setTarget(value)
  }

  return (
    <List>
      <Table
        {...tableProps}
        dataSource={(tableProps?.dataSource as any)?.data}
        rowKey="id"
      >
        <Table.Column<IInvoice>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IInvoice>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/invoices/show/${record?.id}`}>
              {
                record &&
                <Avatar
                  size={64}
                  shape="square"
                  src={record?.image}
                  alt={record?.title}
                />
              }
            </Link>
          )}
        />
        <Table.Column<IInvoice>
          dataIndex="title"
          key="title"
          title="작품명"
          render={(_, record) => <>
            <div><TextField value={`${record.title} (작가: ${record.artwork?.artistName})`} /></div>
            <div>
              <Text style={{fontSize: 11}}>{ record?.subtitle }</Text>
            </div>
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IInvoice>
          dataIndex="price"
          key="price"
          title="수수료"
          render={(_, record) => <TextField value={`${record?.price.toLocaleString()}원`} />}
          defaultSortOrder={getDefaultSortOrder("price", sorter)}
          sorter
        />
        <Table.Column<IInvoice>
          dataIndex="invoiceType"
          key="invoiceType"
          title="분류"
          render={(value) => <TagField color={invoiceTypeColor(value)} value={invoiceTypeName(value)} />}
        />
        <Table.Column<IInvoice>
          dataIndex="createdAt"
          key="createdAt"
          title="등록시각"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IInvoice>
          dataIndex="paidAt"
          key="paidAt"
          title="결제시각"
          render={(_, record) => {
            return record?.payment?.paidAt
            ? (<ReactTimeAgo date={Date.parse(record?.payment?.paidAt)} locale="ko" />)
            : <TagField value="기록없음" />;
          }}
        />
        <Table.Column<IInvoice>
          dataIndex="invoiceStatus"
          key="invoiceStatus"
          title="결제상태"
          render={(value) => <TagField color={invoiceStatusColor(value)} value={invoiceStatusName(value)} />}
          defaultSortOrder={getDefaultSortOrder("invoiceStatus", sorter)}
          sorter
        />
        <Table.Column<IInvoice>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
