import { EyeOutlined, InboxOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Upload,
  UploadFile,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import { IArtist } from "interfaces";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";

const radioOptions = [
  { label: '인증', value: true },
  { label: '미인증', value: false }, 
];

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const ArtistEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutateArtist } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IArtist>({
    redirect: false,
  });

  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectCredentialTab, setSelectCredentialTab] = useState<"write" | "preview">("write");
  const [selectIntroTab, setSelectIntroTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');
  // more constants
  const record = queryResult?.data?.data as IArtist;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const draggerProps: UploadProps = {
    name: 'file',
    listType: 'picture',
    fileList: fileList,
    multiple: true,
    action: `${apiUrl}/artists/${record?.id}/image`,
    headers: headers,
    async onChange(info) {
      const newFileList =
        await Promise.all([...info.fileList].map(async(file) => {
          if (file.response) {
            file.url = await file.response.data;
          }
          return file;
        }));
      const images = newFileList.filter((v) => v.url).map((v) => v.url);
      const inputData = form.getFieldsValue() as any;
      inputData.images = images;
      if (images.filter((v) => v).length === newFileList.length) {
        mutateArtist({
          resource: `artists`,
          values: inputData,
          id: record.id
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "artists", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    async onPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`셀러명: ${record?.name}`);
    },
    async onRemove(file) {
      const src = file.url as string;
      if (src) {
        const newFileList = fileList.filter((e) => e.url !== src);
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: src.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
        } catch (e) {
          console.error(e, 'removing image(s) from S3 failed.')
        }
        setFileList(newFileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const transformStringsToUploadFiles = (items: string[]): UploadFile[] => {
    return items.map((item: string, i: number) => {
      const filename = item.replace(/^.*[\\/]/, '');
      const extention = item.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
      return {
        uid: `-${i+1}`,
        name: filename,
        status: 'done',
        type: `image/${extention}`,
        url: item,
      } as UploadFile
    })
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//
  
  useEffect(() => {
    const savedUri = localStorage.getItem('artists-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IArtist> data has been changed. => ${record}`)
    // if images property exists
    if (record?.images && record?.images.length > 0) {
      const imageList: UploadFile[] = transformStringsToUploadFiles(record?.images);
      setFileList(imageList);
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("artists", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form
        {...formProps}
        form={form}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values, images: fileList.map((e) => e.url),
          });
          // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
          setTimeout(() => {
            if (uri.length > 0) {
              push(`../artists?${uri}`);
            } else {
              goBack();
            }
          }, 500)
        }}
        layout="vertical"
      >
        <Card type="inner" title="셀러 정보" style={{marginBottom: 24}}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가명/리셀러명"
                name="name"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="장르"
                name="genre"
              >
                <Select
                  options={[
                    { label: '🎨 원화작가', value: 'PAINTER', },
                    { label: '✍🏼 일러스트레이터', value: 'DRAWER', },
                    { label: '🪆 조각가', value: 'SCULPTOR', },
                    { label: '📸 사진작가', value: 'PHOTOGRAPHER', },
                    { label: '📺 비쥬얼아티스트', value: 'VISUAL_ARTIST', },
                    { label: '🖼️ 갤러리', value: 'GALLERY', },
                    { label: '기타', value: 'ETC', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="국적"
                name="nationality"
              >
                <Select
                  options={[
                    { label: '🇰🇷 한국', value: 'kr', },
                    { label: '🇬🇷 그리스', value: 'gr', },
                    { label: '🇿🇦 남아공', value: 'za', },
                    { label: '🇳🇴 노르웨이', value: 'no', },
                    { label: '🇳🇱 네덜란드', value: 'nl', },
                    { label: '🇳🇿 뉴질랜드', value: 'nz', },
                    { label: '🇩🇪 독일', value: 'de', },
                    { label: '🇹🇼 대만', value: 'tw', },
                    { label: '🇩🇰 덴마크', value: 'dk', },
                    { label: '🇲🇾 말레이시아', value: 'my', },
                    { label: '🇲🇽 멕시코', value: 'mx', },
                    { label: '🇺🇸 미국', value: 'us', },
                    { label: '🇧🇪 벨기에', value: 'be', },
                    { label: '🇧🇬 불가리아', value: 'bg', },
                    { label: '🇧🇷 브라질', value: 'br', },
                    { label: '🇨🇭 스위스', value: 'ch', },
                    { label: '🇸🇪 스웨덴', value: 'se', },
                    { label: '🇪🇸 스페인', value: 'es', },
                    { label: '🇸🇬 싱가폴', value: 'sg', },
                    { label: '🇮🇪 아일랜드', value: 'ie', },
                    { label: '🇦🇷 아르헨티나', value: 'ar', },
                    { label: '🇬🇧 영국', value: 'gb', },
                    { label: '🇺🇦 우크라이나', value: 'ua', },
                    { label: '🇮🇷 이란', value: 'ir', },
                    { label: '🇮🇶 이라크', value: 'iq', },
                    { label: '🇪🇬 이집트', value: 'eg', },
                    { label: '🇮🇹 이탈리아', value: 'it', },
                    { label: '🇮🇳 인도', value: 'in', },
                    { label: '🇮🇩 인도네시아', value: 'id', },
                    { label: '🇯🇵 일본', value: 'jp', },
                    { label: '🇯🇲 자메이카', value: 'jm', },
                    { label: '🇨🇳 중국', value: 'cn', },
                    { label: '🇨🇦 캐나다', value: 'ca', },
                    { label: '🇹🇭 태국', value: 'th', },
                    { label: '🇹🇷 튀르키예(터키)', value: 'tr', },
                    { label: '🇵🇱 폴란드', value: 'pl', },
                    { label: '🇫🇷 프랑스', value: 'fr', },
                    { label: '🇫🇮 핀란드', value: 'fi', },
                    { label: '🇵🇭 필리핀', value: 'ph', },
                    { label: '🇭🇺 헝가리', value: 'hu', },
                    { label: '🇦🇺 호주', value: 'au', },
                    { label: '기타', value: 'other', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="셀러타입"
                name="sellerType"
              >
                <Select
                  options={[
                    { label: '🟪 플리크루', value: 'ARTIST1', },
                    { label: '🟧 작가', value: 'ARTIST2', },
                    { label: '🟥 예비작가', value: 'ARTIST3', },
                    { label: '🟩 리셀러', value: 'RESELLER', },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="인증여부"
                name="isApproved"
              >
                <Radio.Group
                  options={radioOptions}
                  value={record?.isApproved}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="이미지 업로드">
                <Form.Item
                  name="images"
                  valuePropName="fileList"
                  getValueProps={ (value) => value }
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  <Upload.Dragger { ...draggerProps }>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ fontSize: 14 }}
                    >
                      이미지 파일을 이곳으로 Drag & Drop 하세요.
                    </p>
                  </Upload.Dragger>
                  <Modal
                    visible={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handlePreviewModalClose}
                  >
                    <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="작가소개"
                name="intro"
              >
                <ReactMde
                  selectedTab={selectIntroTab}
                  onTabChange={setSelectIntroTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="작가약력"
                name="credentials"
              >
                <ReactMde
                  selectedTab={selectCredentialTab}
                  onTabChange={setSelectCredentialTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="비고"
                name="note"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="은행명"
                name="bank"
              >
                <Select
                  options={[
                    { label: '하나', value: 'KEBHANA', },
                    { label: 'SC제일', value: 'SCFIRST', },
                    { label: '국민', value: 'KBSTAR', },
                    { label: '신한', value: 'SHINHAN', },
                    { label: '외환', value: 'KEB', },
                    { label: '우리', value: 'WOORIBANK', },
                    { label: '한국씨티', value: 'CITIBANK', },
                    { label: '경남', value: 'KNBANK', },
                    { label: '광주', value: 'KJBANK', },
                    { label: '대구', value: 'DGB', },
                    { label: '부산', value: 'BUSANBANK', },
                    { label: '전북', value: 'JBBANK', },
                    { label: '제주', value: 'JEJUBANK', },
                    { label: '기업', value: 'IBK', },
                    { label: '농협', value: 'NONGHYUP', },
                    { label: '수협', value: 'SUHYUP', },
                    { label: '신협', value: 'CU', },
                    { label: '새마을', value: 'KFCC', },
                    { label: '한국산업은행', value: 'KDB', },
                    { label: '카카오뱅크', value: 'KAKAO', },
                    { label: '토스뱅크', value: 'TOSS', },
                    { label: '우체국', value: 'EPOSTBANK', },
                    { label: '케이뱅크', value: 'KBANK', },
                    { label: '산림조합', value: 'NFCF', },
                    { label: '저축', value: 'FSB', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="예금주"
                name="accountName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="계좌번호"
                name="accountNumber"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="작가SNS"
                name="sns"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="작가제공 연락처"
                name="contact"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};
