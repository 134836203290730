import { Button, Card, Col, Image, MarkdownField, Row, Show, Tag, Typography, useModalForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import { AgeFromDateString } from "age-calculator";
import { genreName } from "helpers/artwork";
import { countryName, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IAdminUser } from "interfaces";
import { EditPasswordModal } from "pages/users/components/edit";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();
  const { queryResult } = useShow<IAdminUser>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.dob ? new AgeFromDateString(record?.dob).age : null;

  const { formProps, modalProps, show: showPasswordModal } = useModalForm({
    action: "edit",
    resource: `users/${record?.id}/password/admin`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    },
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <EditPasswordModal modalProps={modalProps} formProps={formProps} />
      <Card
        type="inner"
        title="사용자 기본정보"
        style={{marginBottom: 24}}
        extra={
          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => {
              showPasswordModal()
            }}
          >사용자 비밀번호 변경</Button>

        }
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            {
              record &&
              <Image
                width={64}
                src={avatarUrl(record?.avatar)}
                alt={record?.realname}
              />
            }
          </Col>
          <Col span={20}>
            <p>{record?.deletedAt ? <Tag color='red'>탈퇴회원</Tag> : <></>}</p>
            <p>{record?.isBanned ? <Tag color='red'>정지회원</Tag> : <></>}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Text delete={record?.deletedAt !== null}>{record?.username ?? 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.isActive ? { color: "green"} : {}}>{record?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화번호</Title>
            {
              record &&
              <Text>{phoneNumber(record?.phone)}</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.realname ? <Text>{record?.realname}</Text> : <Text>n/a</Text>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>성별</Title>
            <Text>{record?.gender ? (record?.gender === 'M' ? <Text>남자</Text> : <Text>여자</Text>)  : 'n/a'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            <Text>{age ? `만${age}세` : 'n/a'}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>사용 정지여부</Title>
            {record?.isBanned ? <Tag color='red'>정지</Tag> : <Tag color='green'>정상</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일확인</Title>
            {record?.isActive ? <Tag color='green'>확인</Tag> : <Tag color='red'>미확인</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>프로필공개</Title>
            {record?.isAnonymous ? <Tag color='red'>미공개</Tag> : <Tag color='green'>공개</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>낙찰내역공개</Title>
            {record?.isPrivate ? <Tag color='red'>미공개</Tag> : <Tag color='green'>공개</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>푸시토큰</Title>
            {record?.pushToken ? <Text ellipsis>{record?.pushToken}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>토큰해쉬 | 탈퇴이유</Title>
            {record?.refreshTokenHash ? <Text ellipsis>{record?.refreshTokenHash}</Text> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {record?.role !== 'USER' ? <Tag color='indigo'>{record?.role}</Tag> : <Tag>{record?.role}</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>언어</Title>
            <Text>{record?.locale === 'ko' ? '한국어' : '영어'}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>가입일</Title>
            {record?.createdAt ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최근사용일</Title>
            {record?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>탈퇴일</Title>
            {record?.deletedAt ? <ReactTimeAgo date={Date.parse(record?.deletedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="사용자 프로필정보" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>푸시알림</Title>
            <Text>{record?.profile?.notifyPush ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>카카오알림</Title>
            <Text>{record?.profile?.notifyKakao ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일알림</Title>
            <Text>{record?.profile?.notifyEmail ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이벤트알림</Title>
            <Text>{record?.profile?.notifyEvent ? <Tag color='green'>수신</Tag> : <Tag>거부</Tag>}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>조회수</Title>
            <Text>{record?.profile?.viewCount.toLocaleString()}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제횟수</Title>
            <Text>{record?.profile?.payCount.toLocaleString()}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>소개글</Title>
            {
              record?.profile?.bio
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.profile?.bio} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="셀러정보"
      >
        {!record?.artist && (
          <Row gutter={[16, 16]}>
            <Col span={24}><Text>셀러정보가 없는 일반사용자입니다.</Text></Col>
          </Row>)}
        {record?.artist && (
          <Row gutter={[16, 16]} style={{marginBottom: 16}}>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>셀러타입</Title>
              <Text style={{marginRight: 8}}>{sellerTypeName(record?.artist?.sellerType)}</Text>
              <Tag color={record?.artist?.isApproved ? 'green' : 'red'}>{record?.artist?.isApproved ? '인증' : '미인증'}</Tag>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>작가명</Title>
              <Text>{record?.artist?.name ?? ''}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>장르</Title>
              <Text>{genreName(record?.artist?.genre)}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>국적</Title>
              <Text>{countryName(record?.artist?.nationality)}</Text>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>생성일</Title>
              {
                record?.artist?.createdAt ? <ReactTimeAgo date={Date.parse(record?.artist?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
              {
                record?.artist?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.artist?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>
              }
            </Col>
          </Row>
        )}
      </Card>
    </Show>
  );
};
