export const articleCategoryColor = (e: string): string => {
  switch (e) {
    case 'INTERVIEW':
      return 'green'
    case 'CURATION':
      return 'blue'
    case 'FLEA_CREW':
      return 'purple'
    case 'FLEA_AUCTION':
      return 'yellow'
    case 'SERVICE':
      return 'orange'
  }
  return 'red'
}

export const articleCategoryName = (e: string): string => {
  switch (e) {
    case 'INTERVIEW':
      return '인터뷰'
    case 'CURATION':
      return '큐레이션'
    case 'FLEA_CREW':
      return '플리크루'
    case 'FLEA_AUCTION':
      return '플리옥션'
    case 'SERVICE':
      return '서비스'
  }
  return '이벤트'
}
