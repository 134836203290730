import {
  Form, FormProps, Input, Modal, ModalProps
} from "@pankod/refine-antd";

type EditPasswordModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const EditPasswordModal: React.FC<EditPasswordModalProps> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} title="비밀번호변경" width={240}>
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values
          });
        }}    
        layout="vertical"
      >
        <Form.Item label="비밀번호" name="password">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
