import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IPayment } from "interfaces";


import { paymentMethodColor, paymentMethodName, paymentStatusColor, paymentStatusName } from "helpers/payment";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const PaymentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IPayment>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });
  return (
    <List>
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IPayment>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/payments/show/${record?.id}`}>
            {
              record?.orders ? <Avatar
                size={64}
                shape="square"
                src={record?.orders[0]?.image}
                alt={record?.orders[0]?.title}
              />
              : <Avatar
                size={64}
                shape="square"
                src='https://cdn.fleaauction.world/images/artwork.png'
                alt="artwork"
              />
            }
            </Link>
          )}
        />
        <Table.Column<IPayment>
          dataIndex="title"
          key="title"
          title="타이틀"
          render={(_, record) => (
            record?.orders && record?.orders!.length > 1
            ? <TextField delete={record?.deletedAt !== null} style={
                record?.deletedAt !== null
                ? { fontWeight: "bold", color: "red" }
                : { fontWeight: "bold" }} value={`${record?.orders[0]?.title} 외 ${record?.orders.length - 1}개`} />
            : (
              record?.orders!.length === 1
              ? <TextField delete={record?.deletedAt !== null} style={
                record?.deletedAt !== null
                ? { fontWeight: "bold", color: "red" }
                : { fontWeight: "bold" }} value={`${record?.orders[0]?.title}`} />
              : <TextField value={`n/a`} />
            )
          )}
        />
        <Table.Column<IPayment>
          key="orderCount"
          title="상품갯수"
          render={(_, record) => record?.orders?.length ?? 0}
        />
        <Table.Column<IPayment>
          dataIndex="grandTotal"
          key="price"
          title="결제금 합계"
          render={(_, record) => <TextField value={`${record?.grandTotal.toLocaleString()}원`} />}
          defaultSortOrder={getDefaultSortOrder("grandTotal", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="createdAt"
          key="createdAt"
          title="종료시각"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="paidAt"
          key="paidAt"
          title="결제시각"
          render={(_, record) => {
            return record?.paidAt
            ? (<ReactTimeAgo date={Date.parse(record?.paidAt)} locale="ko" />)
            : <TagField value="기록없음" />;
          }}
          defaultSortOrder={getDefaultSortOrder("paidAt", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="paymentMethod"
          key="paymentMethod"
          title="결제방법"
          render={(value) => {
            return value ? <TagField color={paymentMethodColor(value)} value={paymentMethodName(value)} /> : <TagField value="기록없음" />;
          }}
          defaultSortOrder={getDefaultSortOrder("paymentMethod", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="paymentStatus"
          key="paymentStatus"
          title="상태"
          render={(value) => <TagField color={paymentStatusColor(value)} value={paymentStatusName(value)} />}
          defaultSortOrder={getDefaultSortOrder("paymentStatus", sorter)}
          sorter
        />
        <Table.Column<IPayment>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
