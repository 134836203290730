import {
  Card,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IBanner } from "interfaces";

export const BannerCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, saveButtonProps } = useForm<IBanner>();

  const onStartedAtOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0)
    form.setFieldsValue({ startedAt: day });
    return day;
  }
  const onEndedAtOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0)
    form.setFieldsValue({ endedAt: day });
    return day;
  }

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="배너 생성"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="배너명"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="버튼 레이블명"
                name="buttonLabel"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='버튼 타입'
                name='buttonType'
                initialValue='info'
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: '플리색상/검정글자', value: 'info', },
                    { label: '노란바탕/검정글자', value: 'warn', },
                    { label: '빨간바탕/흰글자', value: 'error', },
                    { label: '녹색바탕/흰글자', value: 'success', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="타겟"
                name="target"
                initialValue={'packs'}
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: '기획전', value: 'packs', },
                    { label: '옥션', value: 'auctions', },
                    { label: '작가', value: 'artists', },
                    { label: '아티클', value: 'articles', },
                    { label: '마이페이지', value: 'mypage', },
                    { label: '공지사항', value: 'mypage/news', },
                    { label: '작품판매문의', value: 'mypage/inquiry', },
                    { label: '구매내역', value: 'mypage/orders', },
                    { label: '쿠폰', value: 'mypage/coupons', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="타겟아이디"
                name="targetId"
                rules={[{ required: false }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="노출"
                name="isActive"
                initialValue={false}
              >
                <Radio.Group
                  options={[
                    { label: '활성', value: true, },
                    { label: '비활성', value: false, },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
