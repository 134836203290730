import {
  Avatar,
  Button,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  SaveButton,
  Space,
  Table,
  TagField,
  TextField,
  useEditableTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { firstImageUrl } from 'helpers/artwork';
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl } from 'helpers/user';
import { IQuestion } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const QuestionList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps,
    formProps,
    isEditing,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
    sorter,
    setId
  } = useEditableTable<IQuestion>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });
  return (
    <List>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
          dataSource={(tableProps?.dataSource as any)?.data}
          onRow={(record) => ({
            onClick: (event: any) => {
              if (event.target.nodeName === "TD") {
                setId && setId(record.id);
              }
            },
          })}
        >
          <Table.Column<IQuestion>
            dataIndex="id"
            key="id"
            title="ID"
            render={(value) => <NumberField value={value} />}
            defaultSortOrder={getDefaultSortOrder("id", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex="image"
            title="작품사진"
            render={(_, record) => (
              record && record.artwork &&
              <Link to={`/artworks/show/${record?.artwork?.id}`}>
                <Avatar
                  size={64}
                  shape="square"
                  src={firstImageUrl(record?.artwork?.images)}
                  alt={record?.artwork?.title}
                />
              </Link>
            )}
          />
          <Table.Column<IQuestion>
            dataIndex={["artwork", "title"]}
            key="title"
            title="제목"
            render={(value) => <TextField value={value} style={{fontWeight: "bold"}} />}
            defaultSortOrder={getDefaultSortOrder("title", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex={["artwork", "artistName"]}
            key="artistName"
            title="작가명"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("artistName", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex="name"
            key="name"
            title="분류"
            render={(_, record) => <TagField
                color={sellerTypeColor(record?.sellerType)}
                value={`${sellerTypeName(record?.sellerType)} 문의`}
              />}
            defaultSortOrder={getDefaultSortOrder("name", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex="username"
            key="username"
            title="의뢰인"
            render={(_, record) => ( record && record.user &&
              <Link to={`/users/show/${record?.user?.id}`}>
                <Avatar
                  size={32}
                  shape="square"
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.username}
                />
                <TextField value={record.user?.username} style={{marginLeft: 8}} />
              </Link>
            )}
            defaultSortOrder={getDefaultSortOrder("isFixed", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex="answer"
            key="answer"
            title="답변"
            render={(value, data: any) => {
              if (isEditing(data.id)) {
                return (
                  <Form.Item name="answer" style={{margin: 0}}>
                    <Input />
                  </Form.Item>
                );
              }
              return <TextField value={value} />;
            }}
          />
          <Table.Column<IQuestion>
            dataIndex="createdAt"
            key="createdAt"
            title="생성일"
            render={(value) => {
              return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
            }}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
            sorter
          />
          <Table.Column<IQuestion>
            dataIndex="actions"
            title="메뉴"
            render={(_, record) => {
              if (isEditing(record.id)) {
                return (
                  <Space>
                    <SaveButton
                        {...saveButtonProps}
                        size="small"
                    />
                    <Button
                        {...cancelButtonProps}
                        size="small"
                    >
                        Cancel
                    </Button>
                  </Space>
                );
              }
              return (
                <Space>
                  <EditButton
                    {...editButtonProps(record.id)}
                    hideText
                    size="small"
                  />
                  <DeleteButton
                    recordItemId={record.id}
                    hideText
                    size="small"
                  />
                </Space>
              );
            }}
          />
        </Table>
      </Form>
    </List>
  );
};
