import { EyeOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Image,
  Input,
  InputNumber, Modal,
  Radio,
  RcFile,
  Row,
  Select,
  Spin,
  Tag, Upload,
  UploadFile,
  getValueFromEvent,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useInvalidate, useNavigation, useUpdate } from "@pankod/refine-core";
import type { UploadProps } from 'antd';
import { orientationImageUrl } from 'helpers/artwork';
import { IArtwork } from "interfaces";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ACCESS_TOKEN } from "../../constants";

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION ?? `ap-northeast-2`,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? 'n/a',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? 'n/a',
  },
});

export const ArtworkEdit: React.FC<IResourceComponentsProps> = () => {
  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  // useInvalidate hook
  const invalidate = useInvalidate();

  // useUpdate hook
  const { mutate: mutateArtwork } = useUpdate();

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IArtwork>({
    redirect: false,
  });
  
  // useState hooks
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");
  const [uri, setUri] = useState('');
  const [bg, setBg] = useState('backpack');
  const [manualColor, setManualColor] = useState<string>('');

  // more constants
  const record = queryResult?.data?.data as IArtwork;
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  //?-------------------------------------------------------------------------//
  //? file upload
  //?-------------------------------------------------------------------------//

  const draggerProps: UploadProps = { // file 하나씩 업로드
    name: 'file',
    listType: 'picture',
    fileList: fileList,
    multiple: true,
    action: `${apiUrl}/artworks/${record?.id}/image`,
    headers: headers,
    async onChange(info) {
      const newFileList =
        await Promise.all([...info.fileList].map(async(file) => {
          if (file.response) {
            file.url = await file.response.data;
          }
          return file;
        }));
      const images = newFileList.filter((v) => v.url).map((v) => v.url);
      const inputData = form.getFieldsValue() as any;
      inputData.images = images;
      if (images.filter((v) => v).length === newFileList.length) {
        mutateArtwork({
          resource: `artworks`,
          values: inputData,
          id: record.id
        },
        {
          onSuccess: (data, variables, context) => {
            setIsUploading(false);
            invalidate({ resource: "artworks", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
    beforeUpload() {
      setIsUploading(true);
    },
    async onPreview(file: UploadFile) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(`작품명: ${record?.title}`);
    },
    async onRemove(file: UploadFile) {
      setIsUploading(true);
      const src = file.url as string;
      if (src) {
        const newFileList = fileList.filter((e) => e.url !== src);
        try {
          const input = {
            Bucket: `auction-uploads`,
            Key: src.replace('https://cdn.fleaauction.world/', ''),
          }
          const command = new DeleteObjectCommand(input);
          await s3Client.send(command);
          setIsUploading(false);
        } catch (e) {
          console.error(e, 'removing image(s) from S3 failed.')
        }
        setFileList(newFileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  //?-------------------------------------------------------------------------//
  //? compose w/ a uploaded image and a background one
  //?-------------------------------------------------------------------------//

  const composeProps: UploadProps = {
    name: 'file',
    action: `${apiUrl}/artworks/${record?.id}/compose/${bg}`,
    headers: headers,
    async onChange(info) {
      const newFileList = [...fileList];
      const originalName = info.file.name;
      info.file.name = '합성중...';

      if (info.file.status === 'done') {
        if (info.file.response) {
          info.file.name = info.file.response.data.replace(/^.*[\\/]/, '');
          newFileList.push({
            uid: `-${newFileList.length + 1}`,
            name: originalName,
            status: 'done',
            url: info.file.response.data,
          })
        }
        const images = newFileList.map((v) => v.url);
        const inputData = form.getFieldsValue() as any;
        inputData.images = images;
        mutateArtwork({
          resource: `artworks`,
          values: inputData,
          id: record.id
        },
        {
          onSuccess: (data, variables, context) => {
            invalidate({ resource: "artworks", invalidates: ["detail"] })
          },
        })
      }
      setFileList(newFileList);
    },
  };

  const transformStringsToUploadFiles = (items: string[]): UploadFile[] => {
    return items.map((item: string, i: number) => {
      const filename = item.replace(/^.*[\\/]/, '');
      const extention = item.replace(/^.*\.([A-Za-z]{3,4})/, "$1").toLowerCase();
      return {
        uid: `-${i+1}`,
        name: filename,
        status: 'done',
        type: `image/${extention}`,
        url: item,
      } as UploadFile
    })
  }

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const handlePreviewModalClose = () => setPreviewOpen(false);

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('artworks-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IArtwork> data has been changed. => ${record}`)
    // if images property exists
    if (record?.images && record?.images.length > 0) {
      const imageList: UploadFile[] = transformStringsToUploadFiles(record?.images);
      setFileList(imageList);
    }
    if (record?.color) {
      setManualColor(record?.color === 'BLACK' ? '' : record?.color);
    }
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList (as opposed to the raw data.)
  return (
    <Edit
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={(): void => goto("artworks", record?.id!)}
          >Show</Button>
          {defaultButtons}
        </>
      )}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} form={form} onFinish={(values: any) => {
        const edition = values.edition ? values.edition.replaceAll(/[^/0-9]/g, '') : null;
        const artistId = values.artist.id;
        const ownerId = values.owner.id;
        delete values.artist;
        delete values.owner;

        formProps.onFinish && formProps.onFinish({
          ...values,
          edition,
          artistId,
          ownerId,
          manualColor: manualColor,
          images: fileList.map((e) => e.url),
        });
        // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
        setTimeout(() => {
          if (uri.length > 0) {
            push(`../artworks?${uri}`);
          } else {
            goBack();
          }
        }, 500)
      }} layout="vertical">
        <Card
          type="inner"
          title="작품정보"
          style={{marginBottom: 24}}
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가명"
                name="artistName"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="작품명"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="가로(mm)"
                name="width"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="세로(mm)"
                name="height"
                rules={[{required: true}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="액자포함 가로(mm)"
                name="fullWidth"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="액자포함 세로(mm)"
                name="fullHeight"
                rules={[{required: false}]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="소재"
                name="medium"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="제작년도"
                name="producedIn"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="프레임"
                name="framing"
              >
                <Select
                  options={[
                    { label: '무', value: 'UNFRAMED', },
                    { label: '유', value: 'FRAMED', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="묶음배송"
                name="isCombinable"
              >
                <Select
                  options={[
                    { label: '가능', value: true, },
                    { label: '불가', value: false, },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="경매시작가"
                name="startingPrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="최소구매가"
                name="reservePrice"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="추정가"
                name="estimate"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="즉시구매가"
                name="buyItNowPrice"
                rules={[{required: false}]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="분류"
                name="category"
              >
                <Select
                  options={[
                    { label: '🎨 원화', value: 'ORIGINAL', },
                    { label: '🎴 에디션(LTD)', value: 'LIMITED_EDITION', },
                    { label: '🃏 에디션(OPN)', value: 'OPEN_EDITION', },
                    { label: '🪆 조각', value: 'SCULPTURE', },
                    { label: '✍🏼 일러스트', value: 'DRAWING', },
                    { label: '📸 사진', value: 'PHOTOGRAPHY', },
                    { label: '기타', value: 'OTHER', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="에디션"
                name="edition"
                rules={[{required: false}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="상태"
                name="condition"
              >
                <Select
                  options={[
                    { label: 'NEW', value: 'NEW', },
                    { label: 'A', value: 'A', },
                    { label: 'B', value: 'B', },
                    { label: 'C', value: 'C', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="색상"
                name="color"
              >
                <Select
                  options={[
                    { label: 'BEIGE', value: 'BEIGE', },
                    { label: 'BLACK', value: 'BLACK', },
                    { label: 'BLUE', value: 'BLUE', },
                    { label: 'BROWN', value: 'BROWN', },
                    { label: 'GRAY', value: 'GRAY', },
                    { label: 'GREEN', value: 'GREEN', },
                    { label: 'NAVY', value: 'NAVY', },
                    { label: 'ORANGE', value: 'ORANGE', },
                    { label: 'PINK', value: 'PINK', },
                    { label: 'PURPLE', value: 'PURPLE', },
                    { label: 'RED', value: 'RED', },
                    { label: 'WHITE', value: 'WHITE', },
                    { label: 'YELLOW', value: 'YELLOW', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label="수동색상"
                name="manualColor"
              >
                <Radio.Group onChange={(e) => { setManualColor(e.target.value) }}>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FF0000' }} value="RED">{ manualColor === 'RED' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFA500' }} value="ORANGE">{ manualColor === 'ORANGE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFFF00' }} value="YELLOW">{ manualColor === 'YELLOW' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#F5F5DC' }} value="BEIGE">{ manualColor === 'BEIGE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#008000' }} value="GREEN">{ manualColor === 'GREEN' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#0000FF' }} value="BLUE">{ manualColor === 'BLUE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#000080' }} value="NAVY">{ manualColor === 'NAVY' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFC0CB' }} value="PINK">{ manualColor === 'PINK' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#800080' }} value="PURPLE">{ manualColor === 'PURPLE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#A52A2A' }} value="BROWN">{ manualColor === 'BROWN' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#FFFFFF' }} value="WHITE">{ manualColor === 'WHITE' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#808080' }} value="GRAY">{ manualColor === 'GRAY' ? '✅': '▫' }</Radio.Button>
                  <Radio.Button style={{ width: 48, textAlign: 'center', backgroundColor: '#000000' }} value="BLACK">{ manualColor === 'BLACK' ? '✅': '▫' }</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="방향/사이즈"
              >
                <Image
                  width={24}
                  src={orientationImageUrl(record?.orientation)}
                  preview={false}
                />
                <Tag color='Blue' style={{ marginLeft: 16 }}>{record?.size}</Tag>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="보증서"
                name="certificate"
              >
                <Select
                  options={[
                    { label: '보증서있음', value: 'INCLUDED', },
                    { label: '보증서없음', value: 'NA', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매장소"
                name="place"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매년도"
                name="boughtIn"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="구매가"
                name="paidAmount"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="작가 아이디"
                name={["artist", "id"]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="오너 아이디"
                name={["owner", "id"]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Tag style={{ marginBottom: 4 }}>참고</Tag>
              <div style={{ fontSize: 12 }}>작가의 작품은 `작가 아이디`와 `오너(작가) 아이디`가 동일합니다.</div>
              <div style={{ fontSize: 12 }}>리셀 작품은 `작가 아이디` 와 `오너(리셀러) 아이디`가 다릅니다.</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="이미지 업로드">
                <Form.Item
                  name="images"
                  valuePropName="fileList"
                  getValueProps={ (value) => value }
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  <Upload.Dragger { ...draggerProps }>
                    <p className="ant-upload-drag-icon">
                      { isUploading ? <Spin /> : <InboxOutlined style={{fontSize: 24}} /> }
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ fontSize: 14 }}
                    >
                      이미지 파일을 이곳으로 Drag & Drop 하세요.
                    </p>
                  </Upload.Dragger>
                  <Modal
                    visible={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handlePreviewModalClose}
                  >
                    <img alt="artwork" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="뷰잉룸 합성사진 생성">
                <Radio.Group value={bg} onChange={e => setBg(e.target.value)} style={{marginRight: 10}}>
                  <Radio.Button value="backpack">야외</Radio.Button>
                  <Radio.Button value="coffee">거실(커피)</Radio.Button>
                  <Radio.Button value="pot">거실(화분)</Radio.Button>
                  <Radio.Button value="kid">아이(방)</Radio.Button>
                  <Radio.Button value="corridor">아이(복도)</Radio.Button>
                  <Radio.Button value="dog">플리큐브</Radio.Button>
                  <Radio.Button value="ladypot">여자(화분)</Radio.Button>
                  <Radio.Button value="ladysofa">여자(소파)</Radio.Button>
                  <Radio.Button value="guyblack">남자(블랙)</Radio.Button>
                  <Radio.Button value="guywhite">남자(화이트)</Radio.Button>
                </Radio.Group>
                <Upload {...composeProps}>
                  <Button icon={<UploadOutlined />}>파일 선택</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="작품설명"
                name="body"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="비고"
                name="note"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Edit>
  );
};