export const auctionStatusColor = (e: string): string => {
  switch (e) {
    case 'PREPARING':
      return 'indigo'
    case 'ONGOING':
      return 'green'
    case 'ENDED':
      return ''
  }
  return 'pink'
}

export const auctionStatusName = (e: string): string => {
  switch (e) {
    case 'PREPARING':
      return '준비중'
    case 'ONGOING':
      return '진행중'
    case 'ENDED':
      return '종료'
  }
  return '오프라인 전시상품'
}
