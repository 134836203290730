export const couponTargetName = (e: string): string => {
  switch (e) {
    case 'ARTIST':
      return '작가쿠폰'
    case 'PACK':
      return '기획전쿠폰'
    case 'AUCTION':
      return '옥션쿠폰'
  }
  return '사용자쿠폰'
}
