import { message } from '@pankod/refine-antd'
import { AuthProvider } from '@pankod/refine-core'
import { AxiosInstance } from 'axios'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants'

export const authProvider = (axiosInstance: AxiosInstance): AuthProvider => {
  const apiUrl = process.env.REACT_APP_API_URL

  return {
    login: async ({ username, password, remember }) => {
      try {
        const { data } = await axiosInstance.post(`${apiUrl}/auth/login`, {
          email: username,
          password,
        })
        localStorage.setItem(ACCESS_TOKEN, data.accessToken)
        localStorage.setItem(REFRESH_TOKEN, data.refreshToken)
      } catch (error) {
        return Promise.reject(error)
      }

      return Promise.resolve()
    },
    forgotPassword: async (props) => {
      await axiosInstance.post(`${apiUrl}/auth/forgot`, {
        email: props.email,
      })
      localStorage.setItem('USER_EMAIL', props.email)

      return Promise.resolve(`/update-password`)
    },
    updatePassword: async (props) => {
      try {
        await axiosInstance.patch(`${apiUrl}/auth/reset/admin`, props)
        localStorage.removeItem('USER_EMAIL')
        message.info(`비밀번호가 갱신되었습니다.`)
        return Promise.resolve(`/login`)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    logout: (props) => {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      return Promise.resolve(props?.redirectPath)
    },
    checkError: () => Promise.resolve(),
    // checkError: async (error) => {
    //   return Promise.reject({ message: error.message })
    // },
    checkAuth: () => {
      console.log('... checkAuth')
      if (localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.resolve()
      }
      return Promise.reject()
    },
    getPermissions: async () => {
      console.log('... checkPermission')
      return Promise.resolve()
    },
    getUserIdentity: async () => {
      console.log('... getUserIdentity')
      try {
        const user = await axiosInstance.get(`${apiUrl}/users/mine`)
        return Promise.resolve(user.data)
      } catch (error: any) {
        return Promise.reject(error)
      }
    },
  }
}
