export const invoiceStatusColor = (e: string): string => {
  switch (e) {
    case 'PAID':
      return 'indigo'
    case 'WAITING':
      return ''
  }
  return 'red'
}

export const invoiceStatusName = (e: string): string => {
  switch (e) {
    case 'PAID':
      return '결제완료'
    case 'WAITING':
      return '결제대기'
  }
  return '결제취소'
}

export const invoiceTypeColor = (e: string): string => {
  return 'pink'
}

export const invoiceTypeName = (e: string): string => {
  return '리셀수수료'
}
