import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl } from "helpers/user";
import { IAdminArtist } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const ArtistList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IAdminArtist>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IAdminArtist>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="avatar"
          title="프로필사진"
          render={(_, record) => (
            <Link to={`/artists/show/${record?.id}`}>
              {
                record &&
                <Avatar
                  size={64}
                  shape="square"
                  src={avatarUrl(record?.avatar)}
                  alt={record?.username}
                />
              }
            </Link>
          )}
        />
        <Table.Column<IAdminArtist>
          dataIndex="name"
          key="name"
          title="작가명/닉네임"
          render={(_, record) => <div>
            <div><TextField value={record?.name ?? 'n/a'} style={{fontWeight: "bold"}} /></div>
            <TextField value={record?.username} />
          </div>}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="sellerType"
          key="sellerType"
          title="분류"
          render={(_, record) => {
            return (
              <TagField
                color={sellerTypeColor(record?.sellerType)}
                value={sellerTypeName(record?.sellerType)}
              />
            )
          }}
          defaultSortOrder={getDefaultSortOrder("sellerType", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="isApproved"
          key="isApproved"
          title="인증"
          render={(_, record) => (
            record?.isApproved
            ? <TagField color='green' value="인증" />
            : <TagField value='미인증' />
          )}
          defaultSortOrder={getDefaultSortOrder("sns", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="genre"
          key="genre"
          title="장르"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("genre", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="packCount"
          key="packCount"
          title="기획전"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("packCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="auctionCount"
          key="auctionCount"
          title="출품작"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("auctionCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="payCount"
          key="payCount"
          title="낙찰작"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("payCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="followerCount"
          key="followerCount"
          title="팔로워"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("followerCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="bookmarkCount"
          key="bookmarkCount"
          title="북마크"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("bookmarkCount", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근사용"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IAdminArtist>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
