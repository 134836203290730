import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  InputNumber, Row,
  Select, useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { IInvoice } from 'interfaces';
import { useEffect, useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";

export const InvoiceEdit: React.FC<IResourceComponentsProps> = () => {

  // useNavigation hook
  const { show: goto, push, goBack } = useNavigation();

  const [uri, setUri] = useState('');

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult } = useForm<IInvoice>({
    redirect: false,
  });

  // constant definition to be used here
  const record = queryResult?.data?.data as IInvoice;


  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    const savedUri = localStorage.getItem('invoices-list-uri');
    if (savedUri) {
      setUri(savedUri);
    }
  }, []);

  useEffect(() => {
    console.log(`[useEffect hook] <IInvoice> data has been changed. => ${record}`)
  }, [record]);

  // note that getValueProps within Form.Item component makes it possible
  // to access transformated fileList not the raw one.
  return (
    <>
      <Edit
        isLoading={queryResult?.isLoading}
        saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
          <>
            <Button icon={<EyeOutlined />} onClick={(): void => goto("invoices", record?.id!)}>Show</Button>
            {defaultButtons}
          </>
        )}
        contentProps={{
          style: {
            backgroundColor: "#f0f2f5",
          },
        }}
      >
        <Form
          {...formProps}
          onFinish={(values) => {
            formProps.onFinish && formProps.onFinish(values);
            // 수정이 끝나고, 리스트 이동시 해당 아이템이 있는 페이지로 이동
            setTimeout(() => {
              if (uri.length > 0) {
                push(`../invoices?${uri}`);
              } else {
                goBack();
              }
            }, 500)
          }}
          layout="vertical"
        >
          <Card
            type="inner"
            title="인보이스 정보"
          >
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  label="작품제목"
                  name="title"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="관련정보"
                  name="subtitle"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="이미지 URL"
                  name="image"
                  rules={[{required: true}]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="가격"
                  name="price"
                  rules={[{required: true}]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => `${value}`.replace(/[,]+/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="분류"
                  name="invoiceType"
                >
                  <Select
                    options={[
                      { label: '리셀수수료', value: 'RESALE_FEE', },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="상태"
                  name="invoiceStatus"
                >
                  <Select
                    options={[
                      { label: '결제완료', value: 'PAID', },
                      { label: '결제대기', value: 'WAITING', },
                      { label: '결제취소', value: 'CANCELED', },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Edit>
    </>
  );
};
