import {
  Card,
  Col,
  Create,
  Form,
  Input,
  Radio,
  Row,
  Select,
  useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IArticle } from "interfaces";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

export const ArticleCreate: React.FC<IResourceComponentsProps> = () => {
  // options
  const radioOptions = [
    { label: '노출', value: true },
    { label: '숨김', value: false }, 
  ];

  // useState hook
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");

  // useForm hook
  const { form, formProps, saveButtonProps } = useForm<IArticle>();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="기본정보"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="제목"
                name="title"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="부제목"
                name="subtitle"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="분류"
                name="category"
                initialValue={'INTERVIEW'}
              >
                <Select
                  options={[
                    { label: '인터뷰', value: 'INTERVIEW', },
                    { label: '큐레이션', value: 'CURATION', },
                    { label: '플리크루', value: 'FLEA_CREW', },
                    { label: '플리옥션', value: 'FLEA_AUCTION', },
                    { label: '서비스', value: 'SERVICE', },
                    { label: '이벤트', value: 'EVENT', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="활성상태"
                name="isPublished"
                initialValue={false}
              >
                <Radio.Group
                  options={radioOptions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="내용"
                name="body"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
