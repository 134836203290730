import { AntdLayout } from "@pankod/refine-antd";
import {
  useForgotPassword, useRouterContext, useTranslate
} from "@pankod/refine-core";
import {
  Button, Card, Col, Form, FormProps, Input, Row, Typography
} from "antd";
import React from "react";
import "./styles.css";

type ResetPasswordProps = {
  formProps: FormProps;
};

const { Text, Title } = Typography;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPasswordProps> = ({
    formProps
}) => {
    const [form] = Form.useForm<ResetPasswordProps>();
    const translate = useTranslate();
    const { Link } = useRouterContext();
    const { mutate: forgotPassword, isLoading } = useForgotPassword();
    const CardTitle = (
      <Title level={1} className="title" style={{ color: '#666'}}>
        관리자 비밀번호 찾기
      </Title>
    );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh" }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="https://cdn.fleaauction.world/svgs/logo.svg" alt="Company Logo" />
            </div>
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
            >
              <Form
                layout="vertical"
                form={form}
                onFinish={
                  (values) => forgotPassword(values)
                }
                requiredMark={false}
                {...formProps}
              >
                <Form.Item
                  name="email"
                  label={translate(
                    "pages.forgotPassword.fields.email",
                    "Email",
                  )}
                  rules={[
                    { required: true },
                    {
                      type: "email",
                      message: translate(
                        "pages.forgotPassword.errors.validEmail",
                        "Invalid email address",
                      ),
                    },
                  ]}
                >
                  <Input
                    type="email"
                    size="large"
                    placeholder={translate(
                      "pages.forgotPassword.fields.email",
                      "Email",
                    )}
                  />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      marginLeft: "auto",
                    }}
                  >
                    {translate(
                      "pages.register.buttons.haveAccount",
                      "Have an account? ",
                    )}{" "}
                    <Link style={{ fontWeight: "bold" }} to="/login">
                      {translate("pages.login.signin", "Sign in")}
                    </Link>
                  </Text>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    block
                  >
                    {translate(
                      "pages.forgotPassword.buttons.submit",
                      "Send reset instructions",
                    )}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
