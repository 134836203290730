import { CanParams, CanReturnType } from '@pankod/refine-core'

export const accessControl = {
  can: async ({
    resource,
    action,
    params,
  }: CanParams): Promise<CanReturnType> => {
    // console.log(getIdentity)

    return Promise.resolve({ can: true })
  },
}
