import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Tag,
  Typography,
  useForm,
  useModal
} from '@pankod/refine-antd';
import {
  IResourceComponentsProps
} from '@pankod/refine-core';
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from 'dayjs';
import { categoryName, firstImageUrl } from 'helpers/artwork';
import { sellerTypeColor, sellerTypeName } from 'helpers/seller';
import { avatarUrl } from 'helpers/user';
import { IArtist, IArtwork, IPack } from 'interfaces';
import { SelectArtistModal } from 'pages/packs/components/artist/select-artist-modal';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const PackCreate: React.FC<IResourceComponentsProps> = () => {
  // options
  const radioOptions = [
    { label: '노출', value: true },
    { label: '숨김', value: false }, 
  ];

  // useModal hook
  const { modalProps, show, close } = useModal()

  // useForm hook
  const { form, formProps, saveButtonProps, queryResult, } = useForm<IPack>()

  // useState hook
  const [artists, setArtists] = useState<IArtist[]>([])
  const [selectTab, setSelectTab] = useState<"write" | "preview">("write");

  const callback = (newArtist: IArtist) => {
    if (newArtist.artworks && newArtist.artworks.length > 0) {
      const newArtists = [ ...artists, newArtist ];
      setArtists(newArtists)
    }
  }

  //?-------------------------------------------------------------------------//
  //? handlers
  //?-------------------------------------------------------------------------//

  const onStartTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ startTime: day });
    return day;
  }

  const onEndTimePickerOk = (val: dayjs.Dayjs) => {
    const day = val.set('second', 0).set('millisecond', 0); // get rid of jitter
    form.setFieldsValue({ endTime: day });
    return day;
  }

  const onModalOpen = () => {
    show();
  }

  const onArtistDelete = (i: number) => {
    const newArtists = artists.filter((v) => v.id !== i)
    setArtists(newArtists);
  }

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: '#f0f2f5',
        },
      }}
    >
      <SelectArtistModal modalProps={ modalProps } close={ close } callback={ callback } />
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values,
            simple: false,
            compositeIds: artists.map(
              (v) => {
                return {
                  artistId: v.id,
                  artworkIds: v.artworks?.map((v) => v.id)
                }
              }
            ),
          });
        }}
      >
        <Card
          type="inner"
          title="참여 작가·작품"
          extra={
            <Button
              size="small"
              icon={<UserOutlined />}
              onClick={onModalOpen}
            >
              작가·작품 선택
            </Button>
          }
          style={{marginBottom: 24}}
        >
          {
            artists && artists.length > 0
            ? artists!.map((artist, i) => {
              return(<Row
              key={`artwork-row-${artist.id}`}
              gutter={[16, 16]}
              style={{marginBottom: 16}}
              >
                <Col key={`artist-col1-${i}`} span={6}>
                { i === 0 ?
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                    <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                  </Row> :
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
                }
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Link to={`/artists/show/${artist?.id}`}>
                        {
                          artist.user &&
                          <Avatar
                            size={32}
                            src={avatarUrl(artist.user?.avatar)}
                            alt={artist?.user?.username}
                            style={{ marginRight: 8 }}
                          />
                        }
                      </Link>
                      <Tag color={sellerTypeColor(artist.sellerType)}>{sellerTypeName(artist.sellerType)}</Tag>
                      <Button
                        danger
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() => onArtistDelete(artist.id)} />
                      <div><Text ellipsis>{ artist.name ?? '무명' }</Text></div>
                      <div><Text ellipsis>{ artist.user?.username }</Text></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={18}>
                { i === 0 ?
                  <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                    <Col span={4}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
                    <Col span={8}><Title level={5} style={{fontSize: 11}}>작품명</Title></Col>
                    <Col span={2}><Title level={5} style={{fontSize: 11}}>구분</Title></Col>
                    <Col span={4}><Title level={5} style={{fontSize: 11}}>추정가</Title></Col>
                    <Col span={6}><Title level={5} style={{fontSize: 11}}>사이즈</Title></Col>
                  </Row>
                  :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
                }
                {
                  artist.artworks && artist.artworks.length > 0
                  ? artist.artworks!.map((artwork: IArtwork, i: number) => {
                    return <Row key={`artwork-row-${i}`} gutter={[16, 16]}>
                      <Col key={`artwork-col1-${i}`} span={4}>
                        <Link to={`/artworks/show/${artwork?.id}`}>
                          <Avatar
                            size={64}
                            shape="square"
                            src={firstImageUrl(artwork?.images)}
                            alt={artwork?.title}
                          />
                        </Link>
                      </Col>
                      <Col key={`artwork-col2-${i}`} span={8}>
                        <div><Text ellipsis>{artwork?.title}</Text></div>
                      </Col>
                      <Col key={`artwork-col4-${i}`} span={2}>
                        <Tag>{categoryName(artwork.category)}</Tag>
                      </Col>
                      <Col key={`artwork-col3-${i}`} span={4}>
                        <Text>{artwork?.estimate!.toLocaleString()}원</Text>
                      </Col>
                      <Col key={`artwork-col5-${i}`} span={6}>
                        <Tag>{artwork?.size}</Tag>
                        <Text>{(artwork?.width/10).toFixed(1)}cm x {(artwork?.height/10).toFixed(1)}cm</Text>

                      </Col>
                    </Row>
                  })
                  : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
                }
                </Col>
              </Row>)
            })
            : <Row gutter={[16, 16]} style={{marginBottom: 16}}>
              <Col span={24}><Text>선택한 작가·작품이 없습니다. 작가·작품 선택 버튼을 누르세요.</Text></Col>
            </Row>
          }
        </Card>
        <Card type="inner" title="기획전 정보">
            <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="제목"
                name="title"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="부제목"
                name="subtitle"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="기획전 설명"
                name="summary"
              >
                <ReactMde
                  selectedTab={selectTab}
                  onTabChange={setSelectTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="시작날짜"
                name="startTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onStartTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="종료날짜"
                name="endTime"
                getValueProps={(v) => ({
                  value: v ? dayjs(v) : ''
                })}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime
                  format={'YYYY-MM-DD HH:mm'}
                  onOk={onEndTimePickerOk}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label='구분'
                name='status'
                initialValue='PREPARING'
              >
                <Select
                  options={[
                    { label: '준비중', value: 'PREPARING', },
                    { label: '진행중', value: 'ONGOING', },
                    { label: '종료', value: 'ENDED', },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="활성상태"
                name="isPublished"
                initialValue={false}
              >
                <Radio.Group
                  options={radioOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  )
}
