import {
  Card, Col, Form, FormInstance, FormProps, Input, InputNumber, Modal, ModalProps, Row, Select
} from "@pankod/refine-antd";
import { HttpError, useMany } from "@pankod/refine-core";
import { IBidInfo, IUser } from "interfaces";

const { TextArea } = Input;

type CreateOfferModalProps = {
  modalProps: ModalProps;
  form: FormInstance,
  formProps: FormProps;
  title: string;
  subtitle: string;
  image: string | null;
  bidders: Map<string, IBidInfo> | {};
};

export const CreateOfferModal: React.FC<CreateOfferModalProps> = ({
  modalProps,
  form,
  formProps,
  title,
  subtitle,
  image,
  bidders,
}) => {
  const bidderIds = Object.keys(bidders)?.map(v => +v);
  const bidInfos = Object.values(bidders) as IBidInfo[];

  const { data, isLoading, isError } = useMany<IUser, HttpError>({
    resource: "users/many",
    ids: bidderIds,
    queryOptions: {
      enabled: bidderIds !== null && bidderIds.length > 0
    }
  });
  const record = data?.data ?? [];

  return (
    <Modal {...modalProps} title="입찰자 2차오퍼" width={720}>
      <Form
        form={ form }
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values, image
          });
        }}
        
        layout="vertical"
      >
        <Card extra={`입찰자 리스트에서 사용자를 선택하면, 나머지 항목들은 자동으로 채워집니다.`}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="입찰자 리스트" name="userId">
                <Select onChange={(v) => {
                  const idx = bidderIds.findIndex((id) => id === v);
                  form.setFieldsValue({ title: title });
                  form.setFieldsValue({ subtitle: subtitle });
                  form.setFieldsValue({ username: record[idx].username });
                  form.setFieldsValue({ userId: record[idx].id });
                  form.setFieldsValue({ amount: bidInfos[idx].amount });
                  form.setFieldsValue({ phone: bidInfos[idx].phone });
                }}
                  options={record?.map((v, i) => {
                    return {
                      label: `${v.username} (최고입찰액: ${bidInfos[i].amount.toLocaleString()}원)`, value: v.id
                    }
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="작품명" name="title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="기획전명" name="subtitle">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item label="사용자명" name="username">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="사용자 아이디" name="userId">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="전화번호" name="phone">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="특구가" name="amount">
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => `${value}`.replace(/[,]+/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
