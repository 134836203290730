import { BlockOutlined, BorderOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  DateField,
  Image,
  MarkdownField,
  Popconfirm,
  Row,
  Show,
  Tag,
  Typography,
  message,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useDelete, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { articleCategoryColor, articleCategoryName } from "helpers/article";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { courierColor, courierName, orderStatusColor, orderStatusName, shippingStatusColor, shippingStatusName } from "helpers/order";
import { avatarUrl } from "helpers/user";
import { IArticle, IAuction, IBid, IOrder } from "interfaces";
import { CreateBidModal } from "pages/auctions/components/bids/create-bid-modal";
import { CreateOfferModal } from "pages/auctions/components/bids/create-offer-modal";
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const AuctionShow: React.FC<IResourceComponentsProps> = () => {
  // useMediaQuery hook
  const isBigScreen = useMediaQuery({ minWidth: 1280 })
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 })

  const { show: goto } = useNavigation();
  const { queryResult } = useShow<IAuction>();
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;

  const { formProps: bidFormProps, modalProps: bidModalProps, show: showBidForm } = useModalForm({
    action: "create",
    resource: `auctions/${record?.id}/bids`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
      message.success('입찰에 성공했습니다.')
    },
  });

  const { form: offerForm, formProps: offerFormProps, modalProps: offerModalProps, show: showOfferForm } = useModalForm({
    action: "create",
    resource: `auctions/${record?.id}/offers`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
      message.success('특구가 오퍼를 보냈습니다.')
    },
  });

  const { mutate: mutateBid } = useDelete<IBid>();
  const deleteBid = (id: number) => {
    mutateBid(
      {
        resource: `bids`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const { mutate: mutateOrder } = useCreate<IOrder>();
  const createOrder = () => {
    mutateOrder(
      {
        resource: `orders`,
        values: {
          auctionId: record?.id,
        }
      },
      {
        onSuccess: () => {
          refetch();
          message.success('오더를 생성하였습니다.');
        }
      }
    );
  }
  

  //?-------------------------------------------------------------------------//
  //? useState hook
  //?-------------------------------------------------------------------------//

  const [uniqueBidders, setUniqueBidders] = useState({});

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  useEffect(() => {
    if (record?.bids && record?.bids.length > 0) {
      const bidders = record?.bids?.reduce((acc: any, val: IBid) => {
        if (acc[val.user.id] === undefined) {
          acc[val.user.id] = {
            amount: val.amount,
            phone: val.user.phone,
          }
        } else {
          if (val.amount > acc[val.user.id]) {
            acc[val.user.id] = {
              amount: val.amount,
              phone: val.user.phone,
            }
          }
        }
        return acc;
      }, {})
      delete bidders[record?.lastBidderId];
      setUniqueBidders(bidders); // bidders except the highest one
    }
  }, [record]);


  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <CreateBidModal modalProps={bidModalProps} formProps={bidFormProps} />
      <CreateOfferModal
        modalProps={ offerModalProps }
        form={ offerForm }
        formProps={ offerFormProps }
        title={ record?.title ?? 'n/a' }
        subtitle={ record?.subtitle  ?? 'n/a' }
        image={ record?.images ? record?.images[0] : null }
        bidders={ uniqueBidders }
      />

      <Card
        type="inner"
        title="옥션 정보"
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={24}>
            <Title level={5} style={{fontSize: 11}}>이미지</Title>
            {
              record?.images ? <Image.PreviewGroup
                preview={{
                  countRender: (current, total) => {
                    const title = record?.title ?? '';
                    const count = `(${current} / ${total})`;
                    return `${title} ${count}`;
                  }
                }}
              >{
                record?.images.map(function (name, idx) {
                  return <span key={`image-span-${idx}`} style={{ marginRight: '10px'}}><Image
                    key={`image-${idx}`}
                    width={90}
                    src={name}
                    alt={`image #${idx}`}
                    style={{ border: '1px solid #888' }}
                  />
                </span>;
              })}</Image.PreviewGroup> : <Tag>자료없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>연결작품</Title>
            { record?.artwork
            ? <Button
                size="small"
                onClick={(): void => goto("artworks", record?.artwork?.id!)}
              >작품보기</Button>
            : <Tag>연결작품 없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>작가</Title>
            { record?.artwork?.artist
            ?  <Link to={`/artists/show/${record?.artwork?.artist?.id}`}>
                {
                  record && record.artwork && record.artwork.artist && record.artwork.artist.user && 
                  <Avatar
                    size={32}
                    // shape="square"
                    src={avatarUrl(record?.artwork?.artist?.user?.avatar)}
                    alt={record?.artwork?.artist?.user?.username}
                    style={{ marginRight: 10 }}
                  />
                }
                <Text>{ record?.artwork?.artist?.name }</Text>
              </Link>
            : <Tag>작가정보 없음</Tag> }
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작품명</Title>
            <Text>{record?.title}</Text>
          </Col>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>기획전</Title>
            <Text>{record?.subtitle}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>시작시각</Title>
            { record?.startTime &&
              <ReactTimeAgo date={Date.parse(record?.startTime!)} locale="ko" />
            }
            
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>종료시각</Title>
            { record?.endTime
              ? <ReactTimeAgo date={Date.parse(record?.endTime!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>연장종료시각</Title>
            { record?.closingTime
              ? <ReactTimeAgo date={Date.parse(record?.closingTime!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>발송예정일</Title>
            { record?.scheduledAt
              ? <ReactTimeAgo date={Date.parse(record?.scheduledAt!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>생성</Title>
            { record?.createdAt
              ? <ReactTimeAgo date={Date.parse(record?.createdAt!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }              
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>수정</Title>
            { record?.updatedAt
              ? <ReactTimeAgo date={Date.parse(record?.updatedAt!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>추정가</Title>
            <Text>{record?.estimate!.toLocaleString()}원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>즉시구매가</Title>
            {
              record?.buyItNowPrice! > 0
              ? <Text>{record?.buyItNowPrice!.toLocaleString()}원</Text>
              : <Tag>설정안함</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최소구매가</Title>
            <Text>{record?.reservePrice!.toLocaleString()}원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>시작가</Title>
            {
              record?.startingPrice
              ? <Text>{record?.startingPrice!.toLocaleString()}원</Text>
              : <Tag>제로베이스</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>호가</Title>
            {
              record?.bidIncrement && record?.bidIncrement > 0
              ? <Text>{record?.bidIncrement!.toLocaleString()}원</Text>
              : <Tag color="red">즉구ONLY</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>입찰수</Title>
            <Text>{record?.bidCount!.toLocaleString()}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>낙찰가</Title>
            <Text>{record?.lastBidAmount!.toLocaleString()}원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제</Title>
            {
              record?.order
              ? <Tag color={orderStatusColor(record?.order!.orderStatus)}>{orderStatusName(record?.order!.orderStatus)}</Tag>
              : <Tag>정보없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>조회수</Title>
            <Text>{record?.viewCount!.toLocaleString()}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>북마크수</Title>
            <Text>{record?.bookmarkCount!.toLocaleString()}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record &&
              <Tag color={auctionStatusColor(record?.status)}>{auctionStatusName(record?.status )}</Tag>
            }
            {
              record?.status === 'ENDED' &&
              record?.order === null && 
              <Popconfirm
                  title="정말로 생성하시겠습니까?"
                  onConfirm={() => createOrder()}
                  okText="Yes"
                  cancelText="No"
                >
                <Button size="small">Order생성</Button>
              </Popconfirm>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>삭제</Title>
            { record?.deletedAt
              ? <ReactTimeAgo date={Date.parse(record?.deletedAt!)} locale="ko" />
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        {
          record?.isShippable &&
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>묶음배송</Title>
              {record?.isCombinable ? <Tag>가능</Tag> : <Tag>불가</Tag>}
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>핸들링 fee</Title>
              <Text>{record?.handlingFee!.toLocaleString()}원</Text>
            </Col>
            <Col span={16}></Col>
          </Row>
        }
        {
          !record?.isShippable &&
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={5} style={{fontSize: 12, color: 'red'}}>이 상품은 작가 직배송 또는 digital 상품이라 배송비가 없습니다.</Title>
            </Col>
          </Row>
        }
      </Card>
      <Card
        type="inner"
        title={`주문 정보`}
        style={{marginBottom: 24}}
      >
        { record?.order ?
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작품</Title>
            <Row>
              <Col flex='40px'>
                <Link to={`/orders/show/${record?.order?.id}`}>
                  <Avatar
                    size={32}
                    shape="square"
                    src={record?.order?.image}
                    alt={record?.order?.title}
                  />
                </Link>
              </Col>
              <Col flex='auto'>
                <div><Text style={{marginLeft: 8}}>{ record?.order?.title }</Text></div>
                <div>
                  <Text style={{marginLeft: 8, fontSize: 11}}>상품: { record?.order?.price.toLocaleString() }원</Text>
                  <Text style={{marginLeft: 8, fontSize: 11}}>배송: { record?.order?.shipping.toLocaleString() }원</Text>
                  <Text style={{marginLeft: 8, fontSize: 11}}>{ record?.order?.shippingComment }</Text>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            <Tag color={orderStatusColor(record?.order?.orderStatus)}>{orderStatusName(record?.order?.orderStatus)}</Tag>
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>택배업체</Title>
            <Tag color={courierColor(record?.order?.courier)}>{courierName(record?.order?.courier)}</Tag>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>송장번호</Title>
            { record?.order?.trackingNumber
              ? <Text>{record?.order?.trackingNumber}</Text>
              : <Tag>자료없음</Tag>
            }
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>배송상태</Title>
            {
              record && record.order &&
              <Tag color={shippingStatusColor(record?.order?.shippingStatus)}>{shippingStatusName(record?.order?.shippingStatus)}</Tag>
            }
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>묶음배송</Title>
            {
              record?.order?.isCombined
              ? <Tag color="indigo" icon={<BlockOutlined />}>묶음배송</Tag>
              : <Tag color="red" icon={<BorderOutlined />}>단일배송</Tag>
            }
          </Col>
        </Row> : <Row><Col><Tag>기록없음</Tag></Col></Row>
        }
      </Card>
      <Card
        type="inner"
        title="입찰 히스토리"
        extra={
          <>
          {
            record &&
            record.status &&
            record.status === 'ENDED' &&
            record.order?.orderStatus === 'WAITING' &&
            Object.keys(uniqueBidders).length > 0 &&
            <Button
              size="small"
              onClick={() => {
                showOfferForm()
              }}
            >입찰자 2차오퍼</Button>
          }
          <Button
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => {
              showBidForm()
            }}
          >입찰하기</Button>
          </>
        }
        style={{marginBottom: 24}}
      >
        {
          record?.bids && record?.bids.length > 0 ? record?.bids!.sort((a: IBid, b: IBid) => {
              return a.amount > b.amount ? -1 : 1
            }).map((bid: IBid, i) => {
            const isWinner = bid.amount === record.lastBidAmount;
            return <Row key={`bid-row--${i}`} gutter={[16, 16]}>
              <Col key={`bid-col1-${i}`} span={6}>
                {
                  isWinner
                    ? <Text type="success" strong>{ bid.amount.toLocaleString() }원 ✅</Text>
                    : <Text>{ bid.amount.toLocaleString() }원</Text>
                }
                
              </Col>
              <Col key={`bid-col2-${i}`} span={6}>
                <Link to={`/users/show/${bid?.user?.id}`}>
                  <Avatar
                    size={32}
                    src={avatarUrl(bid?.user?.avatar)}
                    alt={bid?.user?.username}
                    style={{ marginRight: 8 }}
                  />
                  <Text strong={isWinner}>{ bid?.user?.username }</Text>
                </Link>
                <Popconfirm
                  title="정말로 삭제하시겠습니까?"
                  onConfirm={() => deleteBid(bid.id)}
                  okText="Yes"
                  cancelText="No"
                >
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                >삭제</Button>
                </Popconfirm>
              </Col>
              <Col key={`bid-col4-${i}`} span={12}>
                <Text strong={isWinner}>
                  <ReactTimeAgo date={Date.parse(bid?.createdAt)} locale="ko" style={{ marginRight: 10 }} />
                  (<DateField value={bid?.createdAt} format="YYYY-MM-DD HH:mm:ss" />)
                </Text>
              </Col>
            </Row>
          }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
        }
      </Card>
      {
        record?.online === false
        ? <Card
          type="inner"
          title="야외전시장소"
          style={{marginBottom: 24}}
          extra={<Tag color='pink'>오프라인 전시상품</Tag>}
        >
          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>전시위치</Title>
              <Tag>{record?.online ? `온라인 전시상품` : `오프라인 전시상품`}</Tag>
            </Col>
            <Col span={4}>
              <Title level={5} style={{fontSize: 11}}>상세</Title>
              {
                record?.location ? <Text>{record?.location}</Text> : <Tag>기록없음</Tag>
              }
            </Col>
          </Row>
        </Card>
        : <></>
      }
      <Card type="inner" title="비고" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>옥션</Title>
            {
              record?.note ? <Text>{record?.note}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>작품</Title>
            {
              record?.artwork?.note ? <Text>{record?.artwork?.note}</Text> : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card type="inner" title="안내문" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {
              record?.finePrint
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.finePrint} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
      </Card>
      {record?.articles &&
      <Card type="inner" title="관련 아티클">
        <Row gutter={[16, 16]}>
          {
            record?.articles && record?.articles.length > 0
            ? record?.articles!.sort((a: IArticle, b: IArticle) => a.id > b.id ? -1 : 1).map((article, i) => {
              return (
                <Col key={`article-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                  <Link to={`/articles/show/${article.id}`}>
                    <Card
                      key={`article-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={article.images![0]}
                          alt={article.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <Text ellipsis style={{ fontSize: 11}}>{ article.subtitle }</Text>
                      <Tag
                        color={articleCategoryColor(article.category)}
                        style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                      >
                        {articleCategoryName(article.category)}
                      </Tag>
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { article.title }
                      </Tag>
                    </Card>
                  </Link>
                </Col>
              );
            }) : <Col span={24}><Text>관련 아티클이 없습니다.</Text></Col>
          }
        </Row>
      </Card>
      }
    </Show>
  );
};
