import {
  Button,
  Card,
  Col,
  Image, Row,
  Show,
  Tag,
  Typography
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IBanner, IGrant } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const BannerShow: React.FC<IResourceComponentsProps> = () => {
  const now = (new Date()).getTime();

  // useShow
  const { queryResult } = useShow<IBanner>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // useDelete
  const { mutate } = useDelete<IGrant>();
  const deleteGrant = (id: number) => {
    mutate(
      {
        resource: `banners/${record?.id}/users`,
        id: id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  // useEffect(() => {
  //   console.log(`record has been changed; ${record}`)
  //   setGrants(record?.grants)
  // }, [record]);

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Card type="inner" title="배너 정보">
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>제목</Title>
            <Text>{ record?.title }</Text>
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이미지</Title>
            {
              <Image
                src={ record?.image ?? 'https://cdn.fleaauction.world/images/banner.png'}
                width={64}
                height={64}
              />
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>이동 타겟</Title>
            {
              <>
                <Text strong>{record?.target}</Text>
                {record?.targetId ? <Text style={{marginLeft: 8}}>{`#${record?.targetId}`}</Text> : ``}
              </>
            }
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record?.isActive ? <Tag color="green">활성</Tag> : <Tag>비활성</Tag>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>버튼 preview</Title>
            {
              record?.buttonType === 'info' &&
              <Button style={{
                'backgroundColor' : '#6ad1c3',
                'color': 'black',
              }}>{record?.buttonLabel}</Button>
            }
            {
              record?.buttonType === 'warn' &&
              <Button style={{
                'backgroundColor' : 'yellow',
                'color': 'black',
              }}>{record?.buttonLabel}</Button>
            }
            {
              record?.buttonType === 'error' &&
              <Button style={{
                'backgroundColor' : 'red',
                'color': 'white',
              }}>{record?.buttonLabel}</Button>
            }
            {
              record?.buttonType === 'success' &&
              <Button style={{
                'backgroundColor' : 'green',
                'color': 'white',
              }}>{record?.buttonLabel}</Button>
            }
          </Col>

          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>생성일</Title>
            {record?.createdAt ? <ReactTimeAgo date={Date.parse(record?.createdAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={6}>
            <Title level={5} style={{fontSize: 11}}>최근수정일</Title>
            {record?.updatedAt ? <ReactTimeAgo date={Date.parse(record?.updatedAt)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
        </Row>
      </Card>
      </Show>
  )
};
