import {
  AntdLayout,
  AutoComplete,
  Avatar,
  Icons,
  Input,
  Row,
  Select,
  Space,
  Typography
} from "@pankod/refine-antd";
import { useCheckError, useGetIdentity, useList } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IAdminArtist, IArtwork, IAuction, IOptions, IUser } from "interfaces";
import { useEffect, useState } from "react";

const { Option } = Select;
const { Link } = routerProvider;
const { Text } = Typography;
const { SearchOutlined } = Icons;

const renderTitle = (title: string) => {
  return (
    <Text strong style={{ fontSize: "16px" }}>{title}</Text>
  );
};

const renderItem = (title: string, resource: string, id: number) => {
  return {
    value: title,
    label: (
      <Link key={`${resource}-${id}`} to={`/${resource}/show/${id}`} reloadDocument={true}>
        <Text style={{width: '100%'}}>{title}</Text>
      </Link>
    ),
  };
};

export const Header: React.FC = () => {

  const [target, setTarget] = useState<string>('username');
  const [value, setValue] = useState<string>('');
  const [options, setOptions] = useState<IOptions[]>([]);
  
  const { data: userIdentity } = useGetIdentity();
  const { mutate: checkError } = useCheckError();
  
  //?-------------------------------------------------------------------------//
  //? useList fetchers
  //?-------------------------------------------------------------------------//

  const { refetch: refetchUsernames } = useList<IUser>({
    resource: "users",
    config: {
      filters: [{ field: "username", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IUser[]).map((item) =>
          renderItem(`${item.id} ${item.username} (${item.email})`, "users", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("사용자 닉네임"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchEmails } = useList<IUser>({
    resource: "users",
    config: {
      filters: [{ field: "email", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IUser[]).map((item) =>
          renderItem(`${item.id} ${item.email} (${item.username})`, "users", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("사용자 이메일"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchNames } = useList<IUser>({
    resource: "users",
    config: {
      filters: [{ field: "realname", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IUser[]).filter((item) => item.realname).map((item) =>
          renderItem(`${item.id} ${item.realname} (${item.email}, ${item.username})`, "users", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("사용자 실명"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchArtists } = useList<IAdminArtist>({
    resource: "artists",
    config: {
      filters: [
        { field: "search", operator: "contains", value },
      ],
    },
    queryOptions: {
      enabled: false,        
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IAdminArtist[]).map((item) =>
          renderItem(`${item.id} ${item.name} (${item.email})`, "artists", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("작가명"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchArtworks } = useList<IArtwork>({
    resource: "artworks",
    config: {
      filters: [
        { field: "search", operator: "contains", value },
      ],
    },
    queryOptions: {
      enabled: false,        
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IArtwork[]).map((item) =>
          renderItem(`${item.id} ${item.title} (${item.artistName})`, "artworks", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("작품 제목"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchAuctions } = useList<IAuction>({
    resource: "auctions",
    config: {
      filters: [
        { field: "search", operator: "contains", value },
      ],
    },
    queryOptions: {
      enabled: false,        
      onSuccess: (data) => {
        const optionGroup = ((data?.data as any)?.data as IAuction[]).map((item) =>
          renderItem(`${item.title} - ${item.artistName}`, "auctions", item.id),
        );
        if (optionGroup.length > 0) {
          setOptions((prevOptions) => [
            // ...prevOptions,
            {
              label: renderTitle("옥션(Auction)"),
              options: optionGroup,
            },
          ]);
        }
      },
    },
  });

  //?-------------------------------------------------------------------------//
  //? event handlers
  //?-------------------------------------------------------------------------//

  const handleChange = (value: string) => {
    setTarget(value)
    setValue('')
  }

  //?-------------------------------------------------------------------------//
  //? useEffect hook
  //?-------------------------------------------------------------------------//

  // useEffect(() => {
  //   if (data) {
  //     data.then((r: any) => {
  //       console.log('good', r);
  //       message.info(`오늘도 열 일하시는 ${r.username} 님 반가와요.`)
  //     }, (e: any) => {
  //       console.error('fuck', e);
  //       localStorage.removeItem(ACCESS_TOKEN)
  //       localStorage.removeItem(REFRESH_TOKEN)

  //       checkError(e);
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    console.log(`value has been changed; ${target} ${value}`)
    setOptions([]);
    switch (target) {
      case 'username':
        refetchUsernames();
        break;
      case 'realname':
        refetchNames();
        break;
      case 'email':
        refetchEmails();
        break;
      case 'artists':
        refetchArtists();
        break;
      case 'artworks':
        refetchArtworks();
        break;
      case 'auctions':
        refetchAuctions();
        break;
    }
  }, [value]);

  useEffect(() => {
    console.log(`target has been changed; ${target}`)
    setOptions([]);
    setValue(() => '');
  }, [target]);

  return (
    <AntdLayout.Header
      style={{
        padding: "0px 24px",
        backgroundColor: "#FFF",
      }}
    >
      <Row justify="space-between">
        <Space align="center" direction="horizontal">
          <Select value={target} style={{ width: 140, marginRight: 10 }} onChange={handleChange}>
            <Option value="username">Users 닉네임</Option>
            <Option value="realname">Users 실명</Option>
            <Option value="email">Users 이메일</Option>
            <Option value="artists">Artists 작가명</Option>
            <Option value="artworks">Artworks</Option>
            <Option value="auctions">Auctions</Option>
          </Select>
          <AutoComplete
            style={{ width: "400" }}
            options={options}
            filterOption={false}
            onSearch={(v: string) => setValue(v)}
            //onSearch={debounce((value: string) => setValue(value), 500)}
          >
            <Input
              allowClear
              placeholder="검색어를 입력하세요."
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Space>

        <Space align="center" direction="horizontal">
          <Avatar size="large" src={userIdentity?.avatar ?? 'https://cdn.fleaauction.world/images/user.png'} />
          <Text>{userIdentity?.username}</Text>
          <Text>({userIdentity?.email})</Text>
        </Space>
      </Row>
    </AntdLayout.Header>
  );
};
