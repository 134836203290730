import {
  Avatar,
  DeleteButton,
  EditButton,
  getDefaultSortOrder,
  List, ShowButton,
  Space,
  Table,
  TagField,
  TextField, useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { IOffer } from "interfaces";
import { useState } from 'react';
import ReactTimeAgo from "react-time-ago";
import { ACCESS_TOKEN } from "../../constants";

const { Link } = routerProvider;

export const OfferList: React.FC<IResourceComponentsProps> = () => {
  const [from, setFrom] = useState<string>('');
  const [target, setTarget] = useState<string>('delivery');
  const [to, setTo] = useState<string>('');
  
  const token = localStorage.getItem(ACCESS_TOKEN);
  const headers = {Authorization: `Bearer ${token}`}
  const apiUrl = useApiUrl();

  const { tableProps, sorter } = useTable<IOffer>({
    initialSorter: [
      { field: "id", order: "desc" },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List>
      <Table
        {...tableProps}
        dataSource={(tableProps?.dataSource as any)?.data}
        rowKey="id"
      >
        <Table.Column<IOffer>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<IOffer>
          dataIndex="image"
          title="작품사진"
          render={(_, record) => (
            <Link to={`/offers/show/${record?.id}`}>
              {
                record &&
                <Avatar
                  size={64}
                  shape="square"
                  src={record?.image}
                  alt={record?.title}
                />
              }
            </Link>
          )}
        />
        <Table.Column<IOffer>
          dataIndex="title"
          key="title"
          title="작품명/기획전명"
          render={(_, record) => <>
            <div><TextField value={record?.title} /></div>
            <div><TagField value={record?.subtitle} /></div>
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IOffer>
          dataIndex="title"
          key="title"
          title="작가명"
          render={(_, record) => <>
            <TextField value={record.auction?.artistName} />
          </>}
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
        />
        <Table.Column<IOffer>
          dataIndex="amount"
          key="amount"
          title="제안가"
          render={(_, record) => <TextField value={`${record?.amount.toLocaleString()}원`} />}
          defaultSortOrder={getDefaultSortOrder("amount", sorter)}
          sorter
        />
        <Table.Column<IOffer>
          dataIndex="expiredAt"
          key="expiredAt"
          title="만료시각"
          render={(value) => {
            return (<ReactTimeAgo date={Date.parse(value)} locale="ko" />);
          }}
          defaultSortOrder={getDefaultSortOrder("expiredAt", sorter)}
          sorter
        />
        <Table.Column<IOffer>
          dataIndex="isRead"
          key="isRead"
          title="확인"
          render={(value) => <TagField color={value ? 'green' : 'red'} value={value ? 'O' : 'X'} />}
        />
        <Table.Column<IOffer>
          dataIndex="isAccepted"
          key="isAccepted"
          title="수락"
          render={(value) => <TagField color={value ? 'green' : 'red'} value={value ? 'O' : 'X'} />}
        />
        <Table.Column<IOffer>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
