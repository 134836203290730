export const categoryName = (e: string): string => {
  switch (e) {
    case 'ORIGINAL':
      return '원화'
    case 'LIMITED_EDITION':
      return '에디션(LTD)'
    case 'OPEN_EDITION':
      return '에디션(OPN)'
    case 'SCULPTURE':
      return '조각'
    case 'DRAWING':
      return '일러스트'
    case 'PHOTOGRAPHY':
      return '사진'
  }
  return '기타'
}

export const genreName = (e: string): string => {
  switch (e) {
    case 'PAINTER':
      return '🎨 원화작가'
    case 'DRAWER':
      return '✍🏼 일러스트레이터'
    case 'SCULPTOR':
      return '🪆 조각가'
    case 'PHOTOGRAPHER':
      return '📸 사진작가'
    case 'VISUAL_ARTIST':
      return '📺 비쥬얼아티스트'
    case 'GALLERY':
      return '🖼️ 갤러리'
  }
  return '기타'
}

export const firstImageUrl = (urls: string[] | null): string => {
  return urls && urls[0]
    ? urls[0]
    : 'https://cdn.fleaauction.world/images/artwork.png'
}

export const orientationImageUrl = (e: string): string => {
  switch (e) {
    case 'LANDSCAPE':
      return 'https://cdn.fleaauction.world/images/orient-landscape.png'
    case 'PORTRAIT':
      return 'https://cdn.fleaauction.world/images/orient-portrait.png'
  }

  return 'https://cdn.fleaauction.world/images/orient-square.png'
}
