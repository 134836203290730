import {
  Form, FormProps, Input, Modal, ModalProps
} from "@pankod/refine-antd";

type CreateArticleCommentProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateArticleComment: React.FC<CreateArticleCommentProps> = ({
  modalProps,
  formProps,
}) => {
  return (
    <Modal {...modalProps} title="댓글 쓰기">
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish && formProps.onFinish({
            ...values,
          });
        }}    
        layout="vertical"
      >
        <Form.Item label="내용" name="body">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
