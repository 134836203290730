import {
  Avatar, DeleteButton,
  EditButton,
  getDefaultSortOrder, List,
  NumberField,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { IAdminUser } from "interfaces";
import ReactTimeAgo from "react-time-ago";

const { Link } = routerProvider;

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IAdminUser>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialPageSize: 20,
    syncWithLocation: true
  });

  return (
    <List>
      <Table {...tableProps} dataSource={(tableProps?.dataSource as any)?.data} rowKey="id">
        <Table.Column<IAdminUser>
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="avatar"
          title="프로필사진"
          render={(_, record) => (
            <Link to={`/users/show/${record.id}`}>
              <Avatar
                size={64}
                shape="square"
                src={record.avatar}
                alt={record.username}
              />
            </Link>
          )}
        />
        <Table.Column<IAdminUser>
          dataIndex="username"
          key="username"
          title="닉네임"
          render={(_, record) => <>
            <div>
              { record.gender === 'M' && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-male.png" alt="male" /> }
              { record.gender === 'F' && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-female.png" alt="female" /> }
              { record.gender === null && <Avatar size={24} shape="square" src="https://cdn.fleaauction.world/images/gender-unknown.png" alt="unknown" /> }
              <TextField
                value={record?.username}
                delete={record?.deletedAt !== null}
                style={
                  record?.deletedAt !== null
                  ? { marginLeft: 4, fontWeight: "bold", color: "red" }
                  : { marginLeft: 4, fontWeight: "bold" }}
                />
              </div>
              <TextField value={record?.email} style={ record?.isActive ? { color: "green"} : {}} />
          </>}
          defaultSortOrder={getDefaultSortOrder("username", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="isBanned"
          key="isBanned"
          title="상태"
          render={(_, record) => <TagField
            color={ record.isBanned ? 'red' : 'green' }
            value={ record.isBanned ? '정지' : '정상' }
          />}
          defaultSortOrder={getDefaultSortOrder("isBanned", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="sellerType"
          key="sellerType"
          title="분류"
          render={(_, record) => (
            record &&
              <TagField color={sellerTypeColor(record?.sellerType)} value={sellerTypeName(record?.sellerType)} />
          )}
          defaultSortOrder={getDefaultSortOrder("sellerType", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="artworkCount"
          key="artworkCount"
          title="판매작"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("artworkCount", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="payCount"
          key="payCount"
          title="낙찰작"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("payCount", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="auctionCount"
          key="auctionCount"
          title="입찰작"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("auctionCount", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="bidCount"
          key="bidCount"
          title="입찰수"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("bidCount", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="updatedAt"
          key="updatedAt"
          title="최근사용"
          render={(value) => <ReactTimeAgo date={Date.parse(value)} locale="ko" />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IAdminUser>
          dataIndex="actions"
          title="메뉴"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
