import { BlockOutlined, BorderOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Show,
  Tag,
  Typography,
  useModal,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { AgeFromDateString } from 'age-calculator';
import dayjs from "dayjs";
import { firstImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import {
  courierColor,
  courierName,
  orderStatusColor,
  orderStatusName,
  shippingStatusColor,
  shippingStatusName
} from "helpers/order";
import { paymentMethodColor, paymentMethodName, paymentStatusColor, paymentStatusName } from 'helpers/payment';
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl, phoneNumber } from "helpers/user";
import { IOrder } from "interfaces";
import { EditModal } from "pages/orders/components/track/edit-modal";
import { ShowModal } from 'pages/orders/components/track/show-modal';

import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { show: goto } = useNavigation();

  // useShow
  const { queryResult } = useShow<IOrder>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const age = record?.user?.dob ? new AgeFromDateString(record?.user?.dob).age : null;

  // useModal
  const { modalProps: showModalProps, show: showModal } = useModal();

  // useModalForm
  const { formProps, modalProps: editModalProps, show } = useModalForm({
    action: 'edit',
    resource: `orders/${record?.id}`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });

  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      {
        record?.trackingNumber &&
        <ShowModal
          modalProps={ showModalProps }
          order={ record }
        />
      }
      <EditModal modalProps={ editModalProps } formProps={ formProps } />
      <Card
        type="inner"
        title={`주문 정보`}
        extra={
          record?.trackingNumber
          ? <>
            <Button
              size="small"
              style={{marginRight: 8}}
              onClick={() => showModal()}
            >
            { 
              '송장번호 조회'
            }
            </Button>
            <Button
              size="small"
              onClick={() => show()}
            >
            { (record?.courier && record?.trackingNumber)
              ? '송장번호 수정'
              : '송장번호 입력'
            }
            </Button>
          </>
          : <Button
            size="small"
            onClick={() => { show() }}
          >
          { (record?.courier && record?.trackingNumber)
            ? '송장번호 수정'
            : '송장번호 입력'
          }
          </Button>
        }
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={5} style={{fontSize: 11}}>작품</Title>
            <Row>
              <Col flex='40px'>
                <Avatar
                  size={32}
                  shape="square"
                  src={record?.image}
                  alt={record?.title}
                />
              </Col>
              <Col flex='auto'>
                <div><Text style={{marginLeft: 8}}>{ record?.title }</Text></div>
                <div>
                  <Text style={{marginLeft: 8, fontSize: 11}}>상품: { record?.price.toLocaleString() }원</Text>
                  <Text style={{marginLeft: 8, fontSize: 11}}>배송: { record?.shipping.toLocaleString() }원</Text>
                  <Text style={{marginLeft: 8, fontSize: 11}}>{ record?.shippingComment }</Text>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record &&
              <Tag color={orderStatusColor(record?.orderStatus)}>{orderStatusName(record?.orderStatus)}</Tag>
            }
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>택배업체</Title>
            {
              record &&
              <Tag color={courierColor(record?.courier)}>{courierName(record?.courier)}</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>송장번호</Title>
            { record?.trackingNumber
              ? <Text>{record?.trackingNumber}</Text>
              : <Tag>자료없음</Tag>
            }
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>배송상태</Title>
            {
              record &&
              <Tag color={shippingStatusColor(record?.shippingStatus)}>{shippingStatusName(record?.shippingStatus)}</Tag>
            }
          </Col>
          <Col span={3}>
            <Title level={5} style={{fontSize: 11}}>묶음배송</Title>
            {
              record?.isCombined
              ? <Tag color="indigo" icon={<BlockOutlined />}>묶음배송</Tag>
              : <Tag color="red" icon={<BorderOutlined />}>단일배송</Tag>
            }
          </Col>
          <Col span={24}>
            <Tag>비고</Tag>
            <Text>{record?.note}</Text>
          </Col>
        </Row>
        {
          record?.payment?.orders &&
          record?.payment?.orders.length > 1 &&
          record?.payment?.orders.map((order: IOrder, i: number) => {
          return <div key={`div-${i}`}>
          { i === 0 &&
          <Row key={`order-header-${i}`} gutter={[16, 16]}>
            <Col key={`order-header-col-${i}`} span={24}>
              <Divider plain>같이 결제한 상품 리스트</Divider>
            </Col>
          </Row>
          }
          <Row key={`order-body-${i}`} gutter={[16, 16]}>
            <Col key={`order-col1-${i}`} span={8}>
              <Row>
                <Col key={`order-sub1-${i}`} flex='40px'>
                  <Link key={`order-link1-${i}`} to={`/orders/show/${order.id}`}>
                    <Avatar
                      key={`order-avatar-${i}`}
                      size={32}
                      shape="square"
                      src={order?.image}
                      alt={order?.title}
                    />
                  </Link>
                </Col>
                <Col key={`order-sub2-${i}`} flex='auto'>
                  <div>
                    <Link key={`order-link2-${i}`} to={`/orders/show/${order.id}`}>
                      <Text key={`order-title-${i}`} style={{marginLeft: 8}}>{ order?.title }</Text>
                    </Link>
                  </div>
                  <div>
                    <Text style={{marginLeft: 8, fontSize: 11}}>상품: { order?.price.toLocaleString() }원</Text>
                    <Text style={{marginLeft: 8, fontSize: 11}}>배송: { order?.shipping.toLocaleString() }원</Text>
                    <Text style={{marginLeft: 8, fontSize: 11}}>{ order?.shippingComment }</Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col key={`order-col2-${i}`} span={3}>
              <Tag color={orderStatusColor(order.orderStatus)}>{orderStatusName(order.orderStatus)}</Tag>
            </Col>
            <Col key={`order-col3-${i}`} span={3}>
              { order.trackingNumber
                ? <Tag color={courierColor(order.courier)}>{courierName(order.courier)}</Tag>
                : <Tag>자료없음</Tag>
              }
            </Col>
            <Col key={`order-col4-${i}`} span={4}>
            {
              order.trackingNumber
              ? <Text
                  key={`order-track-no-${i}`}
                >{order.trackingNumber}</Text>
              : <Tag>자료없음</Tag>
            }
            </Col>
            <Col key={`order-col5-${i}`} span={3}>
              <Tag color={shippingStatusColor(order.shippingStatus)}>{shippingStatusName(order.shippingStatus)}</Tag>
            </Col>
            <Col key={`order-col6-${i}`} span={3}>
            {
              order.isCombined
              ? <Tag color="indigo" icon={<BlockOutlined />}>묶음배송</Tag>
              : <Tag color="red" icon={<BorderOutlined />}>단일배송</Tag>
            }
            </Col>
          </Row>
          </div>
        })
        }
      </Card>
      <Card
        type="inner"
        title="관련 결제정보"
        extra={
          record?.payment ?
          <Button
            size="small"
            onClick={(): void => goto("payments", record?.payment?.id!)}
          >결제보기</Button> : <></>
        }
        style={{marginBottom: 24}}
      >
      {
        record?.payment ?
        <>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>결제일</Title>
            {record?.payment?.paidAt ? <ReactTimeAgo date={Date.parse(record?.payment?.paidAt!)} locale="ko" /> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제방법</Title>
            { record?.payment?.paymentMethod
              ? <Tag color={paymentMethodColor(record?.payment?.paymentMethod)}>{paymentMethodName(record?.payment?.paymentMethod)}</Tag>
              : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제상태</Title>
            { record?.payment?.paymentStatus ? <Tag color={paymentStatusColor(record?.payment?.paymentStatus)}>{paymentStatusName(record?.payment?.paymentStatus)}</Tag> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>PG사 결제아이디</Title>
            { record?.payment?.pgId ? <Text>{record?.payment?.pgId}</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>가상계좌정보</Title>
            { record?.payment?.paymentInfo ? <Text>{record?.payment?.paymentInfo}</Text> : <Tag>기록없음</Tag> }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>최종 결제금</Title>
            <Text>{ record?.payment?.grandTotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>환불 금액</Title>
            <Text>{ record?.payment?.refundTotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상품가 소계</Title>
            <Text>{ record?.payment?.priceSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>배송비 소계</Title>
            <Text>{ record?.payment?.shippingSubtotal.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>묶음배송 할인</Title>
            <Text>{ record?.payment?.shippingDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>쿠폰 할인</Title>
            <Text>{ record?.payment?.couponDiscount.toLocaleString() }원</Text>
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>취소/환불 사유</Title>
            { record?.payment?.cancelReason ? <Text>{record?.payment?.cancelReason}</Text> : <Tag>기록없음</Tag> }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>쿠폰정보</Title>
            {
              record?.payment?.grant?.coupon ?
              <>
                <Tag>{record?.payment?.grant?.coupon?.name}</Tag>
                <Text>{record?.payment?.grant?.coupon?.code} </Text>
                <Text>{record?.payment?.grant?.coupon?.discount.toLocaleString()}원</Text>
              </> : <Tag>기록없음</Tag>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>수취인</Title>
            {
              <Text>{record?.payment?.destination?.name ?? record?.user?.realname }</Text>
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>배송지</Title>
            {
              record?.payment?.destination === null && record?.payment?.shippingAddress === null ? <Tag>기록없음</Tag> :
              (
                record?.payment?.country === 'kr'
                ? <>
                    <Tag>국내</Tag>
                    <Text>{record?.payment?.destination ? `${record?.payment?.destination?.address} ${record?.payment?.destination?.addressDetail}` : record?.payment?.shippingAddress} </Text>
                    <Text style={{marginLeft: 8}}>(ZIP: {record?.payment?.destination?.postalCode ?? record?.payment?.postalCode })</Text>
                  </>
                : <>
                    <Tag>해외</Tag>
                    <Text>{record?.payment?.destination?.address} </Text>
                    <Text>{record?.payment?.destination?.addressDetail} </Text>
                    <Text>{record?.payment?.destination?.city}, </Text>
                    <Text>{record?.payment?.destination?.state}, </Text>
                    <Text>{record?.payment?.destination?.country?.toUpperCase()} </Text>,
                    <Text style={{marginLeft: 8}}>(ZIP: {record?.payment?.destination?.postalCode})</Text>
                  </>
              )
            }
          </Col>
          <Col span={12}>
            <Title level={5} style={{fontSize: 11}}>배송요청사항</Title>
            {
              record?.requestMessage === null
              ? <Tag>기록없음</Tag>
              : <Text>{record?.requestMessage} </Text>
            }
          </Col>
        </Row>
        </> : <Row><Col><Tag>기록없음</Tag></Col></Row>
      }
      </Card>
      <Card type="inner" title="낙찰 회원" style={{marginBottom: 24}}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>닉네임</Title>
            <Link to={`/users/show/${record?.user?.id}`}>
              {
                record && record.user &&
                <Avatar
                  size={32}
                  src={avatarUrl(record?.user?.avatar)}
                  alt={record?.user?.realname}
                />
              }
              <Text style={{marginLeft: 8}}>{record?.user?.username ?? 'n/a'}</Text>
            </Link>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>이메일</Title>
            <Text style={ record?.user?.isActive ? { color: "green"} : {}}>{record?.user?.email}</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>실명</Title>
            {record?.user?.realname ? <Text>{record?.user?.realname}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전화</Title>
            {
              record && record.user &&
              <Text>{phoneNumber(record?.user?.phone)}</Text>
            }
            
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>나이</Title>
            {record?.user?.gender ? (record?.user?.gender === 'F' ? <Text>여자</Text> : <Text>남자</Text>)  : ''}
            {age ? <Text>{`만${age}세`}</Text> : <Tag>기록없음</Tag>}
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>권한</Title>
            {
              record && record.user && record.user.artist &&
              <Tag color={sellerTypeColor(record?.user?.artist?.sellerType)}>
                {sellerTypeName(record?.user?.artist?.sellerType)}
              </Tag>
            }
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="옥션정보"
        extra={
          <Button
            size="small"
            onClick={(): void => goto("auctions", record?.auction?.id!)}
          >옥션보기</Button>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Row gutter={[16, 16]}>
              <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Link to={`/artists/show/${record?.auction?.artwork?.artist?.id}`}>
                  {
                    record && record.auction && record.auction.artwork && record.auction.artwork.artist && record.auction.artwork.artist.user &&
                    <Avatar
                      size={32}
                      src={avatarUrl(record?.auction?.artwork?.artist?.user?.avatar)}
                      alt={record?.auction?.artwork?.artist?.user?.username}
                      style={{ marginRight: 8 }}
                    />
                  }
                </Link>
                {
                  record && record.auction && record.auction.artwork && record.auction.artwork.artist &&
                  <Tag color={sellerTypeColor(record?.auction?.artwork?.artist?.sellerType)}>
                    {sellerTypeName(record?.auction?.artwork?.artist?.sellerType)}
                  </Tag>
                }
                <div><Text ellipsis>{ record?.auction?.artwork?.artist?.name ?? '무명' }</Text></div>
                <div><Text ellipsis>{ record?.auction?.artwork?.artist?.user?.username }</Text></div>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <Row gutter={[16, 16]}>
              <Col span={3}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
              <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명/추정가</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>시작</Title></Col>
              <Col span={4}><Title level={5} style={{fontSize: 11}}>종료</Title></Col>
              <Col span={2}><Title level={5} style={{fontSize: 11}}>입찰수</Title></Col>
              <Col span={3}><Title level={5} style={{fontSize: 11}}>입찰/낙찰가</Title></Col>
              <Col span={2}><Title level={5} style={{fontSize: 11}}>상태</Title></Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={3}>
                <Link to={`/auctions/show/${record?.auction?.id}`}>
                  {
                    record && record.auction &&
                    <Avatar
                      size={64}
                      shape="square"
                      src={firstImageUrl(record?.auction?.images)}
                      alt={record?.auction?.title}
                    />
                  }
                </Link>
              </Col>
              <Col span={6}>
                <div><Text ellipsis>{record?.auction?.title}</Text></div>
                <div><Tag style={{fontSize: 9}}>{record?.auction?.estimate!.toLocaleString()}원</Tag></div>
              </Col>
              <Col span={4}>
                <div>
                {
                  record?.auction?.startTime ?
                  <ReactTimeAgo date={Date.parse(record?.auction?.startTime!)} locale="ko" /> :
                  <Tag>자료없음</Tag>
                }
                </div>
                <div><Tag style={{fontSize: 9}}>{dayjs(record?.auction?.startTime!).format('YY-MM-DD HH:mm')}</Tag></div>
              </Col>
              <Col span={4}>
                <div>
                {
                  record?.auction?.endTime ?
                  <ReactTimeAgo date={Date.parse(record?.auction?.endTime!)} locale="ko" /> :
                  <Tag>자료없음</Tag>
                }
                </div>
                <div><Tag style={{fontSize: 9}}>{dayjs(record?.auction?.endTime!).format('YY-MM-DD HH:mm')}</Tag></div>
              </Col>
              <Col span={2}>
                <Text>{record?.auction?.bidCount!.toLocaleString()}</Text>
              </Col>
              <Col span={3}>
                <div><Text>{record?.auction?.lastBidAmount!.toLocaleString()}</Text></div>
                <div><Tag style={{fontSize: 9}}>{record?.auction?.status === 'ENDED' ? '낙찰가' : '입찰가'}</Tag></div>
              </Col>
              <Col span={2}>
                {
                  record && record.auction &&
                  <Tag color={auctionStatusColor(record?.auction?.status)}>{auctionStatusName(record?.auction?.status )}</Tag>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Show>
  )
};
