import {
  Card, Col,
  Create,
  Form,
  Input,
  Row,
  Select, useForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";

import { IUser } from "interfaces";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IUser>();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Card
          type="inner"
          title="사용자 기본정보"
          extra="닉네임을 지정하지 않으면 자동으로 생성됩니다."
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Form.Item
                label="이메일"
                name="email"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="비밀번호"
                name="password"
                rules={[{required: true}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="권한"
                name="role"
                initialValue={'USER'}
                rules={[{required: true}]}
              >
                <Select
                  options={[
                    { label: "USER", value: "USER", },
                    { label: "ADMIN", value: "ADMIN", },
                    { label: "SUPER", value: "SUPER", },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="닉네임"
                name="username"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Create>
  );
};
