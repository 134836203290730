export const paymentStatusColor = (e: string): string => {
  switch (e) {
    case 'paid':
      return 'indigo'
    case 'ready':
      return 'green'
    case 'canceled':
      return 'orange'
    case 'failed':
      return 'red'
  }
  return 'grey'
}

export const paymentStatusName = (e: string): string => {
  switch (e) {
    case 'paid':
      return '결제완료'
    case 'ready':
      return '이체대기'
    case 'canceled':
      return '결제취소'
    case 'partial_refund':
      return '부분환불'
    case 'failed':
      return '결제실패'
  }
  return '대기중'
}

export const paymentMethodColor = (e: string): string => {
  switch (e) {
    case 'card':
      return 'green'
    case 'trans':
      return 'orange'
    case 'vbank':
      return 'blue'
    case 'phone':
      return 'pink'
    case 'manual':
      return 'indigo'
  }
  return 'grey'
}

export const paymentMethodName = (e: string): string => {
  switch (e) {
    case 'card':
      return '신용카드'
    case 'trans':
      return '실시간계좌이체'
    case 'vbank':
      return '가상계좌'
    case 'phone':
      return '휴대폰소액결제'
    case 'manual':
      return '수동입력'
  }
  return e
}
