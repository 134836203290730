import { DeleteOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  MarkdownField, Popconfirm,
  Row,
  Show,
  Tag,
  Typography,
  useModalForm
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useDelete, useShow } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import { articleCategoryColor, articleCategoryName } from "helpers/article";
import { firstImageUrl } from "helpers/artwork";
import { auctionStatusColor, auctionStatusName } from "helpers/auction";
import { sellerTypeColor, sellerTypeName } from "helpers/seller";
import { avatarUrl } from "helpers/user";
import { IArticle, IArtist, IAuction, IPack, IShortArtist, IShortAuction } from "interfaces";
import { EditHandlingModal } from 'pages/packs/components/auction/edit-handling-modal';
import { SortArtistModal } from "pages/packs/components/show/sort-artist-modal";
import { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import ReactTimeAgo from "react-time-ago";

const { Title, Text } = Typography;
const { Link } = routerProvider;

export const PackShow: React.FC<IResourceComponentsProps> = () => {
  // useMediaQuery hook
  const isBigScreen = useMediaQuery({ minWidth: 1280 })
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 })
  const [shortArtists, setShortArtists] = useState<Array<IShortArtist>>([]);

  // useShow
  const { queryResult } = useShow<IPack>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  // useModalForm
  const { formProps, modalProps, show } = useModalForm({
    action: 'edit',
    resource: `packs/${record?.id}/sort`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    }
  });


  const { formProps: handlingFormProps, modalProps: handlingModalProps, show: showHandlingForm } = useModalForm({
    action: "edit",
    resource: `packs/${record?.id}/auctions`,
    redirect: false,
    onMutationSuccess: () => {
      refetch();
    },
  });


  const { mutate } = useDelete();

  // event handler
  const onAuctionDelete = (id: number) => {
    mutate(
      {
        resource: `packs/${record?.id}/auctions`,
        id,
      },
      {
        onError: (error, variables, context) => {
          console.error(error);
        },
        onSuccess: (data, variables, context) => {
          refetch();
        },
      },    
    )
  }

  useEffect(() => {
    if (record && record?.artists && record?.artists.length > 0) {
      const _artists = record.artists.filter((v) => v).map((v: IArtist) => {
        return {
          id: v.id,
          name: v.name,
          avatar: v.user?.avatar,
          auctions: v.auctions?.filter((v) => v).map((w: IAuction) => {
            return {
              id: w.id,
              title: w.title,
              image: w.images && w.images.length > 0
                ? w.images[0]
                : 'https://cdn.fleaauction.world/images/artwork.png',
            } as IShortAuction
          })
        } as IShortArtist
      })
      setShortArtists(_artists)
    }
  }, [record]);


  return (
    <Show isLoading={isLoading}
      contentProps={{
        style: {
          backgroundColor: "#f0f2f5",
        },
      }}
    >
      <SortArtistModal
        formProps={ formProps }
        modalProps={ modalProps }
        shortArtists={shortArtists}
        setShortArtists={setShortArtists}
      />
      <EditHandlingModal
        formProps={ handlingFormProps }
        modalProps={ handlingModalProps }
      />
      <Card
        type="inner"
        title="기획전"
        extra={ record?.notify ? <Tag color="green">푸시알림 ON</Tag> : <Tag color="red">푸시알림 OFF</Tag> }
        style={{marginBottom: 24}}
      >
        <Row gutter={[16, 16]} style={{marginBottom: 16}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>배너 이미지</Title>
            {
              record?.image ? <Image
              width={64}
              src={firstImageUrl([record?.image])}
              alt={record?.title}
            /> : <Tag>배너없음</Tag>
            }
          </Col>
          <Col span={20}>
            <Title level={5}>{record?.title}</Title>
            <Text>{record?.subtitle}</Text>
          </Col>
          <Col span={24}>
            {
              record?.summary
              ? <div style={{ backgroundColor: "#f0f2f5", padding: "16px"}}><MarkdownField value={record?.summary} /></div>
              : <Tag>기록없음</Tag>
            }
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginBottom: '16px'}}>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>상태</Title>
            {
              record &&
              <Tag color={auctionStatusColor(record?.status)}>{auctionStatusName(record?.status )}</Tag>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>시작시각</Title>
            { record?.startTime ?
              <div>
                <div><ReactTimeAgo date={Date.parse(record?.startTime!)} locale="ko" /></div>
                <div><Text style={{fontSize: 9}}>{dayjs(record?.startTime).format('YY-MM-DD HH:mm:ss')}</Text></div>
              </div> : <Text>기록없음</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>종료시각</Title>
            { record?.endTime ?
              <div>
                <div><ReactTimeAgo date={Date.parse(record?.endTime!)} locale="ko" /></div>
                <div><Text style={{fontSize: 9}}>{dayjs(record?.endTime).format('YY-MM-DD HH:mm:ss')}</Text></div>
              </div> : <Text>기록없음</Text>
            }
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>참여 작가수</Title>
            <Text>{record?.artistCount}명</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>참여 작품수</Title>
            <Text>{record?.artworkCount}개</Text>
          </Col>
          <Col span={4}>
            <Title level={5} style={{fontSize: 11}}>전시</Title>
            <Tag>{record?.online ? `온라인 전시상품` : `오프라인 전시상품`}</Tag>
          </Col>
        </Row>
      </Card>
      <Card
        type="inner"
        title="관련 옥션"
        extra={
          <>
          <Button size="small" onClick={() => showHandlingForm()} >핸들링 fee 일괄조정</Button>
          <Button size="small" style={{ marginLeft: 8 }} onClick={() => show()} >정렬</Button>
          </>
        }
        style={{marginBottom: 24}}
      >
        {
          record?.artists ?
          record?.artists!.map((artist, i: number) => {
            return <Row
            key={`artist-row--${artist.id}`}
            gutter={[16, 16]}
            >
              <Col key={`artist-col1-${i}`} span={4}>
              { i === 0 ?
                <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                  <Col span={24}><Title level={5} style={{fontSize: 11}}>작가</Title></Col>
                </Row> :
                <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Link to={`/artists/show/${artist?.id}`}>
                      {
                        artist && artist.user &&
                        <Avatar
                          size={32}
                          src={avatarUrl(artist?.user?.avatar)}
                          alt={artist?.user?.username}
                          style={{ marginRight: 8 }}
                        />
                      }
                    </Link>
                    <Tag color={sellerTypeColor(artist.sellerType)}>{sellerTypeName(artist.sellerType)}</Tag>
                    <div><Text ellipsis>{ artist?.name ?? '무명' }</Text></div>
                    <div><Text ellipsis>{ artist?.user?.username }</Text></div>
                  </Col>
                </Row>
              </Col>
              <Col key={`artist-col2-${i}`} span={20}>
              { i === 0 ?
                <Row gutter={[16, 16]} style={{marginBottom: 16}}>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>작품</Title></Col>
                  <Col span={6}><Title level={5} style={{fontSize: 11}}>작품명/추정가</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>시작</Title></Col>
                  <Col span={4}><Title level={5} style={{fontSize: 11}}>종료</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>입찰수</Title></Col>
                  <Col span={3}><Title level={5} style={{fontSize: 11}}>입찰/낙찰가</Title></Col>
                  <Col span={2}><Title level={5} style={{fontSize: 11}}>상태</Title></Col>
                </Row>
                :  <Row gutter={[16, 16]} style={{marginBottom: 16}}><Col span={24}></Col></Row>
              }
              {
                artist.auctions ? artist.auctions!.filter((v) => v).map((auction: IAuction, i: number) => {
                  return <Row key={`auction-row-${i}`} gutter={[16, 16]}>
                    <Col key={`auction-col1-${i}`} span={3}>
                      <Link to={`/auctions/show/${auction?.id}`}>
                        <Avatar
                          size={64}
                          shape="square"
                          src={firstImageUrl(auction?.images)}
                          alt={auction?.title}
                        />
                      </Link>
                    </Col>
                    <Col key={`auction-col2-${i}`} span={6}>
                      <div><Text ellipsis>{auction?.title}</Text></div>
                      <div>
                        <Tag style={{fontSize: 9}}>{auction?.estimate!.toLocaleString()}원</Tag>
                        <Popconfirm
                          title="정말로 삭제하시겠습니까?"
                          onConfirm={() => onAuctionDelete(auction?.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            size='small'
                            danger
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </div>
                    </Col>
                    <Col key={`auction-col3-${i}`} span={4}>
                      <div><ReactTimeAgo date={Date.parse(auction?.startTime)} locale="ko" /></div>
                      <div><Text style={{fontSize: 9}}>{dayjs(auction.startTime).format('YY-MM-DD HH:mm:ss')}</Text></div>
                    </Col>
                    <Col key={`auction-col4-${i}`} span={4}>
                      <div><ReactTimeAgo date={Date.parse(auction?.endTime)} locale="ko" /></div>
                      <div><Text style={{fontSize: 9}}>{dayjs(auction.endTime).format('YY-MM-DD HH:mm:ss')}</Text></div>
                    </Col>
                    <Col key={`auction-col5-${i}`} span={2}>
                      <Text>{auction?.bidCount!.toLocaleString()}</Text>
                    </Col>
                    <Col key={`auction-col6-${i}`} span={3}>
                      <Tag style={{fontSize: 9}}>{auction.status==='ENDED' ? '낙찰가' : '입찰가'}</Tag>
                      <Text>{auction?.lastBidAmount!.toLocaleString()}</Text>
                    </Col>
                    <Col key={`auction-col7-${i}`} span={2}>
                      <Tag color={auctionStatusColor(auction?.status)}>{auctionStatusName(auction?.status)}</Tag>
                    </Col>
                  </Row>
                }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
              }
              </Col>
            </Row>
          }) : <Row gutter={[16, 16]}><Col span={24}><Tag>자료없음</Tag></Col></Row>
        }
      </Card>
    {record?.articles &&
      <Card type="inner" title="관련 아티클" extra="기획전 아티클은 관련 옥션 아티클로도 등록됩니다. (완전 🐕꿀)">
        <Row gutter={[16, 16]}>
          {
            record?.articles && record?.articles.length > 0
            ? record?.articles!.sort((a: IArticle, b: IArticle) => a.id > b.id ? -1 : 1).map((article, i) => {
              return (
                <Col key={`article-col-${i}`} span={isBigScreen? 3 : (isSmallScreen? 6 : 4)}>
                  <Link to={`/articles/show/${article.id}`}>
                    <Card
                      key={`article-key-${i}`} 
                      bordered={true}
                      size="small"
                      style={{ padding: 2, border: '1px solid #ccc' }}
                      cover={
                        <img src={article.images![0]}
                          alt={article.title}
                          style={{ height: 160 }} />
                      }
                    >
                      <Text ellipsis style={{ fontSize: 11}}>{ article.subtitle }</Text>
                      <Tag
                        color={articleCategoryColor(article.category)}
                        style={{ fontSize: 10, position: 'absolute', top: 124, left: 10 }}
                      >
                        {articleCategoryName(article.category)}
                      </Tag>
                      <Tag
                        style={{ fontSize: 10, position: 'absolute', top: 10, left: 10 }}
                      >
                        { article.title }
                      </Tag>
                    </Card>
                  </Link>
                </Col>
              );
            }) : <Col span={24}><Text>관련 아티클이 없습니다.</Text></Col>
          }
        </Row>
      </Card>
    }
    </Show>
  );
};
